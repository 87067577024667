<template>
  <el-dialog
    :model-value="isProductDeatilsDialogVisible"
    title="Product Details"
    :width="dialogWidth"
    :style="{ paddingBottom: '20px' }"
    @close="closeDialog"
    @open="viewProductDetails"
  >
    <div v-loading="loading" class="content-image">
      <section class="image-and-content">
        <div class="image-sesction">
          <p class="details-value" :style="{ fontSize: '1.2rem' }">
            <span v-html="linkifiedDescription"></span>
          </p>
          <img v-if="editedImage" class="detailed-img" :src="editedImage" />
        </div>
        <div class="details-content">
          <p class="bold-paragraph">
            Title: <span v-html="linkifiedTitle"></span>
          </p>
          <p class="bold-paragraph">
            Location: <span>{{ editedLocation }}</span>
          </p>
          <p class="bold-paragraph">
            Price: <span>{{ editedPrice }}</span>
          </p>
          <p class="bold-paragraph">
            Phone: <span>{{ editedPhone }}</span>
          </p>
          <p class="bold-paragraph">
            Published:
            <span>{{ publishedStatus ? "Published" : "Not published" }}</span>
          </p>
          <p class="icon-word" :style="{ fontSize: '1.2rem' }">
            <strong
              ><span
                class="material-symbols-outlined"
                :style="{ fontSize: '1.2rem' }"
              >
                visibility
              </span></strong
            >
            <span>{{ viewers }}</span>
          </p>
          <div>
            <p class="bold-paragraph">Watchers</p>
            <div
              v-for="watcher in watchers"
              :key="watcher.id"
              class="viewer-profile"
            >
              <div class="profile-picture">
                <img
                  v-if="watcher.picture"
                  class="profile-img"
                  :src="watcher.picture"
                  style="object-fit: cover"
                />
                <div v-else class="initials">
                  {{ getUserInitials(watcher.username) }}
                </div>
              </div>
              <span class="username-and-country">
                <p class="capitalise">{{ watcher.username }}</p>
                <p>({{ watcher.country }})</p>
              </span>
            </div>
          </div>
        </div>
      </section>
    </div>
  </el-dialog>
</template>

<script lang="ts" setup>
import {
  defineProps,
  ref,
  defineEmits,
  computed,
  onMounted,
  onUnmounted,
} from "vue";
import { useAuthStore } from "../../../store/authStore";
import axios from "axios";
import Swal from "sweetalert2";

const windowWidth = ref(window.innerWidth);

// Computed property for responsive dialog width
const dialogWidth = computed(() => {
  if (windowWidth.value <= 480) {
    return "90%"; // Very small screens
  } else if (windowWidth.value <= 768) {
    return "80%"; // Medium screens
  } else {
    return "700px"; // Large screens
  }
});

function handleResize() {
  windowWidth.value = window.innerWidth; // Update the reactive windowWidth
}

// Watch for window resize events
onMounted(() => {
  window.addEventListener("resize", handleResize);
  handleResize(); // Initialize width on mount
});

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});

// token
const token = computed(() => {
  return useAuthStore().token;
});

const props = defineProps<{
  isProductDeatilsDialogVisible: boolean;
  productId: string;
}>();

// defining the emit
const emit = defineEmits<{
  (e: "close-dialog"): void;
  (e: "fetch-all-proucts"): void;
}>();

// emitting the custom event "close-dialog" for closing the dialog

const closeDialog = () => {
  emit("close-dialog");
};

const editedTitle = ref("");
const editedDescription = ref("");
const editedImage = ref("");
const editedId = ref(0);
const editedLocation = ref("");
const editedContactInfo = ref("");
const editedPrice = ref(0);
const editedPhone = ref("");
const publishedStatus = ref(false);
const viewers = ref(0);
const watchers = ref<Watcher[]>([]);
const loading = ref(false);

// getting the initials of the viewers username

const getUserInitials = (username: string): string => {
  if (!username) return "";

  const initials = username
    .split(" ")
    .map((name) => name.charAt(0))
    .join("");
  return initials.toUpperCase();
};

// checking whethere in we have a link and make them linkable

const linkify = (text: string): string => {
  // Regular expression to match URLs (http, https, www)
  const urlRegex = /(https?:\/\/[^\s]+|www\.[^\s]+)/gi;

  // Replacing URLs with anchor tags
  return text.replace(urlRegex, (match: string): string => {
    // Ensuring the URL starts with http:// or https:// for proper link functionality
    const url =
      match.startsWith("http://") || match.startsWith("https://")
        ? match
        : `http://${match}`;

    // Returning the anchor tag with the matched URL
    return `<a href="${url}" target="_blank" rel="noopener noreferrer">${match}</a>`;
  });
};

// Use computed properties to linkify the edited title and description
const linkifiedTitle = computed(() => linkify(editedTitle.value));
const linkifiedDescription = computed(() => linkify(editedDescription.value));

interface Watcher {
  id: string;
  picture: string;
  username: string;
  country: string;
}

// Method for viewing product details
const viewProductDetails = async () => {
  console.log("Product Id", props.productId);
  try {
    loading.value = true;
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/products",
      {
        method: "product_details",
        product_id: props.productId,
      },
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );
    if (response.data.success) {
      const results = response.data.result;

      editedTitle.value = results.product_info.title;
      editedDescription.value = results.product_info.description;
      editedImage.value = results.product_info.picture;
      editedLocation.value = results.product_info.location;
      editedContactInfo.value = results.product_info.contact_phone_number;
      editedPrice.value = results.product_info.price;
      editedPhone.value = results.product_info.phone_number;
      publishedStatus.value = results.product_info.published;
      viewers.value = results.product_views;
      editedId.value = results.product_info.id;

      // Clearing the watchers array before appending new watchers
      watchers.value = [];
      // Appending new watchers to the watchers array

      results.who_viewed_product.forEach((viewer: Watcher) => {
        watchers.value.push({
          id: viewer.id,
          picture: viewer.picture,
          username: viewer.username,
          country: viewer.country,
        });
      });
    } else {
      // Handle other error cases
      const errorMessage =
        response.data.errors || "Failed to fetch product details";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#d33",
    });
  } finally {
    loading.value = false;
  }
};
</script>

<style scoped>
.image-and-content {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}
.content-image {
  display: flex;
  padding: 1rem;
  border: 10px solid;
  border-image: linear-gradient(to right, #8cc63f, #662d91) 1;
  color: #fff;
}
.image-sesction {
  margin: 3rem auto;
  max-width: 25rem;
  overflow-y: scroll;
}

.image-sesction p:first-child {
  color: black;
  text-transform: capitalize;
}

.details-content {
  background-color: white;
  color: black;
  height: 400px;
  overflow-y: scroll;
  /* width: 25rem; */
  margin: 2rem 0 2rem 2rem;
}
.details-content p {
  margin: 1rem;
}
.details-content p:last-child {
  margin-left: -1rem;
}
.bold-paragraph {
  font-weight: 700;
}
.details-content img {
  margin: 0 -1rem 0 1rem;
}
.content-image {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.detailed-img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.viewer-profile {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
}
.viewer-profile p {
  font-size: 1.2rem;
  margin: 0;
}
.username-and-country {
  display: flex;
  gap: 1.2rem;
}

.username-and-country p {
  font-size: 0.8rem;
  margin-left: 10px;
}
.profile-img {
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
}

.profile-picture {
  display: flex;
  margin-right: 1rem;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  margin-right: 1rem;
}
.initials {
  /* background-color: #662d91;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem; */

  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #662d91;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #fff;
  margin-left: 10px;
}
.initials {
  width: 25px;
  height: 25px;
  font-size: 12px;
}

.icon-word {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.details-value {
  display: flex;
  flex-direction: column;
  /* gap: 1rem; */
}
hr {
  width: 100%;
}
.capitalise {
  text-transform: capitalize;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .content-image {
    padding: 0.5rem;
  }

  .image-and-content {
    flex-direction: column; /* Stack image on top of content */
  }

  .image-or-vid {
    margin-bottom: 1rem;
  }

  .detailed-img,
  .detailed-vid {
    width: 100%;
    height: auto;
  }

  .viewer-profile {
    flex-direction: row;
    align-items: flex-start;
  }

  .profile-picture {
    margin-bottom: 0.5rem;
  }

  .profile-img img {
    width: 30px;
    height: 30px;
  }

  .initials {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 480px) {
  .content-image {
    padding: 0.5rem;
  }

  .image-or-vid {
    margin-bottom: 1rem;
  }

  .detailed-img {
    width: 100%;
    height: auto;
  }

  .viewer-profile {
    margin-bottom: 1rem;
    flex-direction: row;
    align-items: flex-start;
  }

  .profile-picture {
    margin-bottom: 0.5rem;
  }

  .profile-img img {
    width: 25px;
    height: 25px;
  }

  .initials {
    width: 25px;
    height: 25px;
    font-size: 12px;
  }

  .button-container {
    text-align: center;
  }
}
</style>
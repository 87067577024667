<template>
  <div class="paid-advert-container">
    <div class="home-user">
      <span class="home-icon">
        <span class="material-symbols-outlined" @click="goHome()">
          grid_view
        </span>
      </span>
      <div class="right-arrow-h4">
        <el-button class="right-arrow" :icon="arrowIcon" circle></el-button>
        <h4>Paid Adverts</h4>
      </div>
    </div>
    <h3>Paid Adverts</h3>

    <div class="inputs-btn">
      <section class="inputs-section">
        <el-input
          v-model="searchInput"
          type="text"
          style="width: 200px"
          :placeholder="placeholder"
          @input="onSearch"
        />
        <el-select
          v-model="searchFilter"
          placeholder="Search By Reference"
          style="width: 200px"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </section>
    </div>

    <!-- the table for the paid adverts -->

    <div class="paid-adverts-table">
      <el-table v-loading="loading" :data="rows" border style="width: 100%">
        <el-table-column label="#" width="50">
          <template v-slot="{ $index }">
            {{ (currentPage - 1) * pageSize + ($index + 1) }}</template
          >
        </el-table-column>
        <el-table-column label="Title">
          <template v-slot="{ row }"> {{ row.title }}</template>
        </el-table-column>
        <el-table-column label="Created At">
          <template v-slot="{ row }"
            ><el-tooltip
              effect="dark"
              :content="formatDateTime(row.created_at)"
            >
              <span>{{ formatShortDate(row.created_at) }}</span>
            </el-tooltip></template
          >
        </el-table-column>
        <el-table-column label="Advert Id">
          <template v-slot="{ row }">{{ row.advertId }}</template>
        </el-table-column>
        <el-table-column label="Payment Id">
          <template v-slot="{ row }">{{ row.payment_id }}</template>
        </el-table-column>
        <el-table-column label="Phone">
          <template v-slot="{ row }">
            <el-tooltip class="item" effect="dark" :content="row.mobile">
              <div>{{ truncatePhoneNumber(row.mobile) }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="Amount">
          <template v-slot="{ row }">{{ row.amount }}</template>
        </el-table-column>
        <el-table-column label="Ref">
          <template v-slot="{ row }">
            <el-tooltip class="item" effect="dark" :content="row.reference">
              <div>{{ truncateReference(row.reference) }}</div>
            </el-tooltip>
          </template>
        </el-table-column>

        <el-table-column label="Status" width="80">
          <template v-slot="{ row }">
            {{ row.status ? "Paid" : "Unpaid" }}</template
          >
        </el-table-column>
        <el-table-column label="Actions" width="100">
          <template v-slot="{ row }">
            <div class="action-buttons">
              <div class="action-btns-2">
                <div>
                  <el-tooltip content="View Details" placement="top">
                    <el-button
                      type="primary"
                      :icon="detailsIcon"
                      circle
                      @click="advertDetails(row.advertId)"
                    ></el-button>
                  </el-tooltip>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        :current-page="currentPage"
        :small="small"
        :page-size="pageSize"
        :total="total"
        :disabled="disabled"
        :background="background"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, sizes, prev, pager, next, jumper"
      />
    </div>
    <PaidAdvertDetailsDialog
      :is-paid-advert-details-dialog-visible="paidAdvertDetailsDialogVisible"
      :advert-id="advertId"
      @close-dialog="closeAdvertDetailsDialog"
    />
  </div>
</template>
  
  <script lang="ts" setup>
import { ref, computed, onMounted } from "vue";
import axios from "axios";
import { format } from "date-fns";
import Payment from "../../types/Payment";
import { useAuthStore } from "../../store/authStore";
import { View } from "@element-plus/icons-vue";
import debounce from "debounce";
import PaidAdvertDetailsDialog from "../../components/dialogs/adverts/agents-paid-advert-details-dialog.vue";
import { ArrowRight } from "@element-plus/icons-vue";
import Swal from "sweetalert2";
import { useRouter } from "vue-router";

// token
const token = computed(() => {
  return useAuthStore().token;
});

const router = useRouter();
const loading = ref(false);

const currentPage = ref(1);
const pageSize = ref(10);
const small = ref(false);
const background = ref(false);
const disabled = ref(false);
const total = ref(0);
const paidAdvertDetailsDialogVisible = ref(false);
const rows = ref([]);

const advertId = ref("");
const searchInput = ref("");
const searchFilter = ref("Search By Reference");

const placeholder = computed((): string => {
  if (searchFilter.value === "reference") return "Search By Reference";
  else if (searchFilter.value === "advert_id") return "Search By Advert Id";
  else return "Search By Reference";
});

const options = ref([
  { label: "Search By Reference", value: "reference" },
  { label: "Search By Advert Id", value: "advert_id" },
]);

const goHome = () => {
  router.push("/dashboard");
};

const advertDetails = (adId: string) => {
  advertId.value = adId;
  paidAdvertDetailsDialogVisible.value = true;
};
const closeAdvertDetailsDialog = () => {
  paidAdvertDetailsDialogVisible.value = false;
};

const handleCurrentChange = async (val: number) => {
  currentPage.value = val;
  await fetchPaidAdverts();
};

const handleSizeChange = async (val: number) => {
  pageSize.value = val;
  currentPage.value = 1;
  await fetchPaidAdverts();
};

const arrowIcon = ArrowRight;
const detailsIcon = View;

// truncatong the phone number
const truncatePhoneNumber = (phone: string) => {
  if (phone.length > 6) {
    return phone.slice(0, 6) + "...";
  } else {
    return phone;
  }
};

// truncating the refeernce
const truncateReference = (reference: string) => {
  if (reference.length > 6) {
    return reference.slice(0, 6) + "...";
  } else {
    return reference;
  }
};

// methods for formating the time

const formatDateTime = (dateTimeString: string) => {
  return format(new Date(dateTimeString), "dd/MM/yyyy, HH:mm:ss");
};
const formatShortDate = (dateTimeString: string) => {
  return format(new Date(dateTimeString), "dd/MM/yyyy") + "...";
};

// fetchig all the paid adverts
const fetchPaidAdverts = async () => {
  try {
    loading.value = true;
    rows.value = [];
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/payments",
      {
        method: "user_paid_adverts",
        page: currentPage.value,
        reference: searchFilter.value === "reference" ? searchInput.value : "",
        id: searchFilter.value === "advert_id" ? +searchInput.value : null,
      },
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );
    if (response.data.success) {
      const { result } = response.data;
      const { paid_adverts } = result;

      //   pushing mapped values to the rows
      rows.value = paid_adverts.map((advert: Payment) => ({
        created_at: advert.created_at,
        advertId: advert.id,
        title: advert.title,
        amount: advert.amount,
        reference: advert.reference,
        payment_id: advert.payment_id,
        mobile: advert.mobile,
        status: advert.status,
      }));
      total.value = parseInt(response.data.total_adverts);
    } else {
      // Handle other error cases
      const errorMessage =
        response.data.errors || "Failed to fetch paid adverts";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#3085d6",
    });
  } finally {
    loading.value = false;
  }
};

const onSearch = debounce(function () {
  fetchPaidAdverts();
}, 500);

onMounted(() => {
  fetchPaidAdverts();
});
</script>
  
<style scoped>
.paid-advert-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 95%;
}

.home-user {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.home-icon .material-symbols-outlined {
  font-size: 1.2rem;
  margin-left: 0;
}

.material-symbols-outlined:hover {
  cursor: pointer;
}
.right-arrow-h4 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.right-arrow {
  background-color: transparent;
  border: none;
  color: gray;
}
h4 {
  font-size: 1rem;
  font-weight: 300;
  margin-top: 0.5rem;
}
h3 {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 2rem 0 3rem 0;
  color: #662d91;
}
.pagination {
  margin: 1rem auto;
}
.paid-adverts-table {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}
.el-table {
  overflow-x: auto;
}
.el-table th,
.el-table td {
  padding: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.inputs-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 0;
}

.inputs-section {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  margin: 0;
}
button {
  padding: 8px 10px;
  background-color: #662d91;
  color: white;
  margin: 1rem;
  cursor: pointer;
  border-radius: 5px;
  border: none;
}
@media (max-width: 468px) {
  .inputs-btn {
    gap: 0.5rem;
  }

  .inputs-section el-input,
  .inputs-section el-select {
    width: 100%;
  }
  .pagination {
    font-size: 0.75rem;
    padding: 0.5rem;
  }

  .pagination .el-pagination {
    width: auto;
  }

  .pagination .el-pagination .el-pagination__pager {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .pagination .el-pagination .el-pagination__pager button {
    margin: 0.1rem;
    padding: 0.3rem;
  }
}

@media (max-width: 768px) {
  .inputs-section {
    flex-direction: column;
  }

  .inputs-section el-input,
  .inputs-section el-select {
    width: 100%;
  }
  .pagination {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    font-size: 0.875rem;
  }

  .pagination .el-pagination {
    width: auto;
  }

  .pagination .el-pagination .el-pagination__pager {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .pagination .el-pagination .el-pagination__pager button {
    margin: 0.2rem;
  }
}
</style>
  
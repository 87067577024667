<template>
  <el-dialog
    :model-value="isAddAdvertPaymentDialogVisible"
    title="Create Payment"
    :width="dialogWidth"
    :style="{ paddingBottom: '20px' }"
    @close="closeDialog"
  >
    <el-form class="modal-form" label-width="auto" style="max-width: 600px">
      <el-form-item label="Name">
        <el-input type="text" v-model="name" required />
      </el-form-item>
      <el-form-item label="Phone">
        <el-input
          type="text"
          v-model="phone"
          placeholder="Format 2567123456784"
          required
        />
      </el-form-item>
      <el-form-item label="Days">
        <el-input type="text" v-model="days" required />
      </el-form-item>
      <el-form-item label="Amount">
        <el-input type="text" v-model="amount" required />
      </el-form-item>
      <el-form-item label="Currency">
        <div class="user-input-selector">
          <el-select
            v-model="selectedCurrency"
            placeholder="Choose Currecny"
            @change="handleCurrencyChange"
          >
            <el-option
              v-for="currency in currencies"
              :key="currency"
              :label="currency"
              :value="currency"
            />
          </el-select>
        </div>
      </el-form-item>
      <el-form-item label="Country">
        <div class="user-input-selector">
          <el-select v-model="selectedCountry" placeholder="Choose Nationality">
            <el-option
              v-for="country in sortedCountries"
              :key="country.country_code"
              :label="country.country_name"
              :value="country.country_code"
            />
          </el-select>
        </div>
      </el-form-item>
      <el-button
        @click="createPayment"
        type="primary"
        class="create-payments-btn"
        >Create payment</el-button
      >
    </el-form>
  </el-dialog>
</template>

<script lang="ts" setup>
import {
  ref,
  computed,
  defineProps,
  defineEmits,
  onMounted,
  onUnmounted,
} from "vue";
import Country from "../../../types/Country";
import axios from "axios";
import Swal from "sweetalert2";
import { useAuthStore } from "../../../store/authStore";

// defining token
const token = computed(() => {
  return useAuthStore().token;
});

const props = defineProps<{
  isAddAdvertPaymentDialogVisible: boolean;
  advertId: string;
  clientId: number;
}>();

// emitting the custom event "close-dialog" for closing the dialog
const emit = defineEmits<{
  (e: "close-dialog"): void;
  (e: "fetch-adverts"): void;
}>();

const closeDialog = () => {
  emit("close-dialog");
};

const name = ref("");
const phone = ref("");
const amount = ref("");
const userId = ref("");
const days = ref("");
const selectedCurrency = ref("");
const formData = ref(new FormData());
const currencies = ref(["UGX", "USD", "KES", "RWF"]);

const countries = ref<Country[]>([]);
const selectedCountry = ref("");

const windowWidth = ref(window.innerWidth);

// Computed property for responsive dialog width
const dialogWidth = computed(() => {
  const width = windowWidth.value <= 480 ? "90%" : "500px";
  const maxWidth = "800px"; // Maximum width for large screens
  return `min(${width}, ${maxWidth})`;
});

function handleResize() {
  windowWidth.value = window.innerWidth; // Update the reactive windowWidth
}
// Watch for window resize events
onMounted(() => {
  window.addEventListener("resize", handleResize);
  handleResize();
});

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});

const sortedCountries = computed(() => {
  return countries.value
    .slice()
    .sort((a, b) => a.country_name.localeCompare(b.country_name));
});

const handleCurrencyChange = async () => {
  formData.value.set("currency", selectedCurrency.value);
};

// creating the payment
const createPayment = async () => {
  try {
    // Create a FormData object
    const formData = new FormData();
    formData.append("method", "create_advert_payment");
    formData.append("name", name.value);
    formData.append("advert_id", props.advertId);
    formData.append("amount", amount.value.toString());
    formData.append("days", days.value);
    formData.append("mobile", phone.value.toString());
    // this is the client's id represented by User_uid
    formData.append("user_id", props.clientId.toString());
    formData.append("currency", selectedCurrency.value);

    // Fetch country code based on country name
    const countryCodeResponse = await axios.post(
      // additional to global baseUrl endpoint
      "/countries",
      { method: "list_country_codes" },
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );
    if (countryCodeResponse.data.success) {
      const countries = countryCodeResponse.data.countries;
      // Sort countries alphabetically by country_name
      countries.sort((a: Country, b: Country) =>
        a.country_name.localeCompare(b.country_name)
      );
      // Find the country object corresponding to the entered country name
      const selectedCountryData = countries.find(
        (country: Country) => country.country_code === selectedCountry.value
      );
      console.log("Selected Country Data:", selectedCountryData);

      if (selectedCountryData) {
        // Append country code to formData
        formData.append("country", selectedCountryData.country_code);
      }
      const response = await axios.post(
        // additional to global baseUrl endpoint
        "/payments",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token.value}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.success) {
        console.log(response.data);
        closeDialog();
        const result = await Swal.fire({
          title: "Successful",
          text: "Transaction successful",
          icon: "success",
          confirmButtonColor: "#3085d6",
        });

        if (result.isConfirmed) {
          // Clearing input fields after successful advert creation
          emit("fetch-adverts");
          name.value = "";
          phone.value = "";
          days.value = "";
          selectedCountry.value = "";
          amount.value = "";
          userId.value = "";
        }
      } else {
        // Handle other error cases
        const errorMessage = response.data.errors || "Failed to create payment";
        return Swal.fire({
          title: "Error",
          text: errorMessage,
          icon: "error",
          confirmButtonColor: "#d33",
        });
      }
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#d33",
    });
  }
};

// Fetching countries
const fetchCountries = async () => {
  try {
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/countries",
      { method: "list_country_codes" },
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );
    if (response.data.success) {
      const fetchedCountries = response.data.countries;
      // Sort countries alphabetically by country_name
      fetchedCountries.sort((a: Country, b: Country) =>
        a.country_name.localeCompare(b.country_name)
      );
      countries.value = fetchedCountries;
    } else {
      // Handle other error cases
      const errorMessage = response.data.errors || "Failed to fetch countries";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#d33",
    });
  }
};

onMounted(() => {
  fetchCountries();
});
</script>

<style scoped>
.create-payments-btn {
  background-color: #662d91;
  color: #fff;
  border: none;
  padding: 20px 3rem;
  cursor: pointer;
  display: block;
  margin: 1rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

.create-payments-btn:hover {
  background-color: transparent;
  border: 1px solid #662d91;
  color: #662d91;
}
.user-input-selector {
  width: 100%;
}
</style>
// defining pinia
import { defineStore } from "pinia";
import { jwtDecode } from "jwt-decode";

interface DecodedToken {
  id: number;
  username: string;
  country: string;
  picture: string;
  is_agent: boolean;
  admin: boolean;
  exp: number;
}

export const useAuthStore = defineStore({
  id: "auth",
  state: () => ({
    isAuthenticated: false,
    token: "",
    userId: 0,
    userName: "",
    userPicture: "",
    isAdmin: false,
    isAgent: false,
  }),
  actions: {
    async loginSuccess(token: string): Promise<void> {
      try {
        // Decoding the token to access its payload
        const decodedToken = jwtDecode(token) as DecodedToken;
        this.userId = decodedToken.id;
        this.userName = decodedToken.username;
        this.userPicture = decodedToken.picture;
        this.isAdmin = decodedToken.admin;
        this.isAgent = decodedToken.is_agent;

        // Updating authentication state
        this.token = token;
        this.isAuthenticated = true;

        // Storing token in local storage if it exists
        localStorage.setItem("token", token);
        localStorage.setItem("userId", this.userId.toString());
      } catch (error) {
        console.error("Error decoding token:", error);
        // Clearing stored token and log user out
        this.logout();
      }
    },
    logout(): void {
      this.isAuthenticated = false;
      this.token = "";
      this.userId = 0;
      this.userName = "";
      this.userPicture = "";
      this.isAdmin = false;
      this.isAgent = false;
      // Clearing token and userId from local storage or session storage
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
    },
    initializeAuthState() {
      // Retrieving token from local storage
      const storedToken = localStorage.getItem("token");

      if (storedToken) {
        try {
          // Decoding token to extract user information
          const decodedToken = jwtDecode(storedToken) as DecodedToken;

          // Checking if the token is expired
          if (decodedToken.exp * 1000 < Date.now()) {
            // When the token is expired, clear stored token and log user out
            this.logout();
            return;
          }

          // Updating authentication state
          this.token = storedToken;
          this.isAuthenticated = true;
          this.userId = decodedToken.id;
          this.userName = decodedToken.username;
          this.userPicture = decodedToken.picture;
          this.isAdmin = decodedToken.admin;
          this.isAgent = decodedToken.is_agent;
        } catch (error) {
          console.error("Error decoding token:", error);
          // Clearing stored token and log user out
          this.logout();
        }
      }
    },
  },
});

<template>
  <router-view />
</template>

<script lang="ts" setup>
</script>

<style>
@import url("https://fonts.cdnfonts.com/css/euclid-circular-a");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Euclid Circular A", sans-serif;
  font-weight: 100;
  font-style: normal;
}
</style>


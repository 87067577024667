<template>
  <div>
    <el-dialog
      :model-value="isPaymentStatusDetailsDialogVisible"
      title="Payment Status details"
      :width="dialogWidth"
      :style="{ paddingBottom: '20px' }"
      @close="closeDialog"
      @open="viewPaymentsStatusDetails"
    >
      <div id="receiptDialog" v-loading="loading">
        <!-- Your dialog content -->
        <div :style="{ border: '5px dotted #000', padding: '10px' }">
          <el-row :gutter="20">
            <el-col :span="12">
              <b>Trans Status:</b>
            </el-col>
            <el-col :span="12">{{ gridData.transaction_status }}</el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <b>Name:</b>
            </el-col>
            <el-col :span="12">{{ gridData.payer_name }}</el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <b>Amount:</b>
            </el-col>
            <el-col :span="12">{{ gridData.amount_paid }}</el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <b>Payment Id:</b>
            </el-col>
            <el-col :span="12">{{ gridData.payment_id }}</el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <b>Number:</b>
            </el-col>
            <el-col :span="12">{{ gridData.mobile }}</el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <b>Advert Id:</b>
            </el-col>
            <el-col :span="12">{{ gridData.advert_id }}</el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <b>User Id:</b>
            </el-col>
            <el-col :span="12">{{ gridData.user_id }}</el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <b>Payee Currency:</b>
            </el-col>
            <el-col :span="12">{{ gridData.payee_currency }}</el-col>
          </el-row>
          <p style="margin-top: 20px">
            Thank you for your payment. If you have any questions, please
            contact us at
            <a href="mailto:support@cashchatapp.com">support@cashchatapp.com</a
            >.
          </p>
          <div class="button-container">
            <el-button
              class="receipt-btn"
              @click="downloadPDF(gridData)"
              type="primary"
              >Download Receipt</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
  
  <script lang="ts" setup>
import {
  defineProps,
  ref,
  defineEmits,
  computed,
  onMounted,
  onUnmounted,
} from "vue";
import GridData from "../../../types/PaymentData";
import axios from "axios";
import { useAuthStore } from "../../../store/authStore";
import Swal from "sweetalert2";

// endpoint

const token = computed(() => {
  return useAuthStore().token;
});

const loading = ref(false);

const windowWidth = ref(window.innerWidth);

// Computed property for responsive dialog width
const dialogWidth = computed(() => {
  const width = windowWidth.value <= 480 ? "90%" : "500px";
  const maxWidth = "800px"; // Maximum width for large screens
  return `min(${width}, ${maxWidth})`;
});

function handleResize() {
  windowWidth.value = window.innerWidth; // Update the reactive windowWidth
}
// Watch for window resize events
onMounted(() => {
  window.addEventListener("resize", handleResize);
  handleResize();
});

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});

// Defining props

const props = defineProps<{
  isPaymentStatusDetailsDialogVisible: boolean;
  referenceNumber: string;
}>();

// defining the emit
const emit = defineEmits<{
  (e: "close-dialog"): void;
}>();

// emitting the custom event "close-dialog" for closing the dialog

const closeDialog = () => {
  emit("close-dialog");
};

// declaring the gridd data
const gridData = ref<GridData>({
  transaction_status: "",
  amount_paid: "",
  payment_id: "",
  payer_name: "",
  payment_date: "",
  user_id: "",
  advert_id: "",
  payee_currency: "",
  mobile: "",
  title: "",
});

// method for viewing the payment status details
const viewPaymentsStatusDetails = async () => {
  try {
    loading.value = true;
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/payments",
      {
        method: "check_transaction_status",
        reference: props.referenceNumber,
      },

      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );
    if (response.data.success) {
      console.log(response.data);
      const results = response.data;

      // Updating gridData with transaction details
      gridData.value = {
        transaction_status: results.transaction_status,
        payer_name: results.payer_name,
        payment_id: results.payment_id,
        mobile: results.mobile,
        advert_id: results.advert_id,
        user_id: results.user_id,
        payee_currency: results.payee_currency,
        amount_paid: results.amount_paid,
        payment_date: results.payment_date,
        title: results.title,
      };
    } else {
      // Handle other error cases
      const errorMessage =
        response.data.errors || "Failed to load payments status data";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#3085d6",
    });
  } finally {
    loading.value = false;
  }
};

// downloading teh receipt into a pdf format
const downloadPDF = async (row: GridData) => {
  try {
    loading.value = true;
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/payments",
      {
        method: "export_transaction_details_pdf",
        advert_id: row.advert_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
        responseType: "blob", // Setting response type to blob to receive binary data
      }
    );

    // Check if the request was successful
    if (response.status === 200) {
      // Creating a Blob object from the PDF data received from the backend
      const pdfBlob = new Blob([response.data], { type: "application/pdf" });

      // Creating a temporary link element
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(pdfBlob);
      link.download = "receipt.pdf"; // Setting the filename for the downloaded file

      // Triggering the download
      link.click();

      // Revoking the URL to release the resources
      window.URL.revokeObjectURL(link.href);
    } else {
      // Handle other error cases
      const errorMessage = response.data.errors || "Failed to download pdf";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#d33",
    });
  } finally {
    loading.value = false;
  }
};
</script>

<style scoped>
.button-container {
  display: flex;
  justify-content: center;
}
.receipt-btn {
  background-color: #662d91;
  color: #fff;
  border: none;
}
</style>
<template>
  <el-dialog
    :model-value="isAdvertDetailsDialogVisible"
    title="Advert Details"
    :width="dialogWidth"
    :style="{ paddingBottom: '20px' }"
    @close="closeDialog"
    @open="viewAvertDetails"
  >
    <div v-loading="loading" class="content-image">
      <section class="image-and-content">
        <div class="image-or-vid">
          <img
            v-if="editedFileUrl"
            class="detailed-img"
            :src="editedFileUrl"
            alt="Advert Image"
          />

          <iframe
            v-else
            class="detailed-vid"
            :src="editedVideoFileUrl"
          ></iframe>
        </div>
        <div class="details-content">
          <p class="bold-paragraph">
            Title: <span v-html="linkifiedTitle"></span>
          </p>
          <p class="bold-paragraph">
            Description:
            <span v-html="linkifiedDescription"></span>
          </p>
          <p class="bold-paragraph">Advert Id : {{ editedId }}</p>
          <p class="icon-word">
            <strong
              ><span class="material-symbols-outlined">
                visibility
              </span></strong
            >
            <span>{{ viewers }}</span>
          </p>
          <div>
            <p class="bold-paragraph">Watchers</p>
            <div
              v-for="watcher in watchers"
              :key="watcher.id"
              class="viewer-profile"
            >
              <div class="profile-picture">
                <div v-if="watcher.picture" class="profile-img">
                  <img class="persons-img" :src="watcher.picture" />
                </div>
                <div v-else class="initials">
                  {{ getUserInitials(watcher.username) }}
                </div>
              </div>
              <span class="username-and-country">
                <p class="capitalise">{{ watcher.username }}</p>
                <p>({{ watcher.country }})</p>
              </span>
            </div>
          </div>
          <div class="button-container">
            <el-button
              class="export-btn"
              @click="exportWatchers()"
              type="primary"
              >Export Advert Analytics</el-button
            >
          </div>
        </div>
      </section>
    </div>
  </el-dialog>
</template>

<script lang="ts" setup>
import {
  defineProps,
  ref,
  defineEmits,
  computed,
  onMounted,
  onUnmounted,
} from "vue";
import axios from "axios";
import { useAuthStore } from "../../../store/authStore";
import Watcher from "../../../types/Watcher";
import Swal from "sweetalert2";
// token
const token = computed(() => {
  return useAuthStore().token;
});

const props = defineProps<{
  isAdvertDetailsDialogVisible: boolean;
  advertId: string;
}>();

// defining the emit
const emit = defineEmits<{
  (e: "close-dialog"): void;
}>();

// emitting the custom event "close-dialog" for closing the dialog
const closeDialog = () => {
  emit("close-dialog");
};

// getting the wather's username iniatials in case they have no profile picture
const getUserInitials = (username: string): string => {
  if (!username) return "";

  const initials = username
    .split(" ")
    .map((name) => name.charAt(0))
    .join("");
  return initials.toUpperCase();
};

// refs
const publishedStatus = ref(false);
const editedFileUrl = ref("");
const editedVideoFileUrl = ref("");
const verificationStatus = ref(false);
const editedTitle = ref("");
const editedDescription = ref("");
const viewers = ref(0);
const editedId = ref("");
const loading = ref(false);
const watchers = ref<Watcher[]>([]);
const advertId = ref("");

const windowWidth = ref(window.innerWidth);

// Computed property for responsive dialog width
const dialogWidth = computed(() => {
  if (windowWidth.value <= 480) {
    return "90%"; // Very small screens
  } else if (windowWidth.value <= 768) {
    return "80%"; // Medium screens
  } else {
    return "550px";
  }
});

function handleResize() {
  windowWidth.value = window.innerWidth; // Update the reactive windowWidth
}

// Watch for window resize events
onMounted(() => {
  window.addEventListener("resize", handleResize);
  handleResize(); // Initialize width on mount
});

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});

// checking whethere in we have a link and make them linkable

const linkify = (text: string): string => {
  // Regular expression to match URLs (http, https, www)
  const urlRegex = /(https?:\/\/[^\s]+|www\.[^\s]+)/gi;

  // Replacing URLs with anchor tags
  return text.replace(urlRegex, (match: string): string => {
    // Ensuring the URL starts with http:// or https:// for proper link functionality
    const url =
      match.startsWith("http://") || match.startsWith("https://")
        ? match
        : `http://${match}`;

    // Returniing the anchor tag with the matched URL
    return `<a href="${url}" target="_blank" rel="noopener noreferrer">${match}</a>`;
  });
};

// Using computed properties to linkify the edited title and description
const linkifiedTitle = computed(() => linkify(editedTitle.value));
const linkifiedDescription = computed(() => linkify(editedDescription.value));

// Method for viewing the advert details
const viewAvertDetails = async () => {
  try {
    loading.value = true;
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/adverts",
      {
        method: "advert_details",
        advert_id: props.advertId,
      },
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );
    if (response.data.success) {
      const results = response.data.result;

      editedTitle.value = results.advert_info.title;
      editedDescription.value = results.advert_info.description;
      verificationStatus.value = results.advert_info.verified;
      publishedStatus.value = results.advert_info.published;
      editedFileUrl.value = results.advert_info.picture;
      viewers.value = results.adviews;
      editedVideoFileUrl.value = results.advert_info.video;
      editedId.value = results.advert_info.id;
      // Clearing the watchers array before appending new watchers
      watchers.value = [];

      // Appending new watchers to the watchers array

      results.who_viewed_advert.forEach((viewer: Watcher) => {
        watchers.value.push({
          id: viewer.id,
          picture: viewer.picture,
          username: viewer.username,
          country: viewer.country,
        });
      });
    } else {
      // Handle other error cases
      const errorMessage =
        response.data.errors || "Failed to show adverts details";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#d33",
    });
  } finally {
    loading.value = false;
  }
};

const exportWatchers = async () => {
  console.log("Advert id ", props.advertId);
  try {
    loading.value = true;

    // Create a FormData object
    const formData = new FormData();
    formData.append("method", "advert_stats");
    formData.append("advert_id", props.advertId);

    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/adverts",
      formData,
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "blob", // Setting response type to blob to receive binary data
      }
    );

    // Check if the request was successful
    if (response.status === 200) {
      // Creating a Blob object from the PDF data received from the backend
      const pdfBlob = new Blob([response.data], { type: "application/pdf" });

      // Creating a temporary link element
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(pdfBlob);
      link.download = "exportwatcher.pdf"; // Setting the filename for the downloaded file

      // Triggering the download
      link.click();

      // Revoking the URL to release the resources
      window.URL.revokeObjectURL(link.href);
    } else {
      // Handle other error cases
      const errorMessage = response.data.errors || "Failed to download pdf";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#d33",
    });
  } finally {
    loading.value = false;
  }
};
</script>

<style scoped>
.image-and-content {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: space-between;
}
.content-image {
  display: flex;
  padding: 1rem;
  border: 10px solid;
  border-image: linear-gradient(to right, #8cc63f, #662d91) 1;
}

.image-or-vid {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
}

.details-content {
  background-color: white;
  color: black;
  height: 350px;
  overflow-y: scroll;
  max-width: 25rem;
  margin: 2rem 0 2rem 2rem;
  padding: 1rem;
}
.details-content p {
  margin: 1rem;
}
.details-content p:last-child {
  margin-left: -1rem;
}

.detailed-img,
.detailed-vid {
  max-width: 100%; /* Prevent overflow */
  max-height: 300px;
  object-fit: cover;
}
.details-content img {
  margin: 0 -1rem 0 1rem;
}
.content-image {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.bold-paragraph {
  font-weight: 700;
}

.viewer-profile {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
}

.viewer-profile p {
  font-size: 1.2rem;
  margin: 0;
}
.username-and-country {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
}

.username-and-country p {
  font-size: 0.8rem;
  margin-left: 10px;
}

.icon-word {
  display: flex;
  align-items: center;
}

.profile-picture {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
}

.profile-img {
  margin-bottom: 0.5rem;
}
.persons-img {
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -0.1rem !important;
}
.content-image {
  display: flex;
  padding: 1rem;
  border: 10px solid;
  border-image: linear-gradient(to right, #8cc63f, #662d91) 1;
  overflow: hidden; /* Prevent overflow */
  border-radius: 8px; /* Optional: add border radius for rounded corners */
}

.initials {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #662d91;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #fff;
  margin-left: 10px;
}
.capitalise {
  text-transform: capitalize;
}
.export-btn {
  background-color: #662d91;
  color: #fff;
  border: none;
  margin-top: 2rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .content-image {
    padding: 0.5rem;
  }

  .image-and-content {
    flex-direction: column;
  }

  .image-or-vid {
    margin-bottom: -3rem;
    margin-top: 1rem;
  }

  .detailed-img,
  .detailed-vid {
    width: 100%;
    height: auto;
  }

  .viewer-profile {
    flex-direction: row;
    align-items: flex-start;
  }

  .profile-picture {
    margin-bottom: 0.5rem;
  }

  .profile-img img {
    width: 30px;
    height: 30px;
  }

  .initials {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 480px) {
  .content-image {
    padding: 0.5rem;
  }

  .image-or-vid {
    margin-bottom: -3rem;
    margin-top: 1rem;
  }

  .detailed-img,
  .detailed-vid {
    width: 100%;
    height: auto;
  }

  .viewer-profile {
    margin-bottom: 1rem;
    flex-direction: row;
    align-items: flex-start;
  }

  .profile-picture {
    margin-bottom: 0.5rem;
  }

  .profile-img img {
    width: 25px;
    height: 25px;
  }

  .initials {
    width: 25px;
    height: 25px;
    font-size: 12px;
  }

  .button-container {
    text-align: center;
  }
}
</style>
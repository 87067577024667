<template>
  <div class="dashboard-home">
    <div class="base-header">
      <BaseHeader />
    </div>
    <div class="sidebar-routerview">
      <div
        :class="{
          'sidebar-hidden': isSidebarHidden && isSmallScreen,
          'sidebar-visible': !isSidebarHidden || !isSmallScreen,
        }"
        class="dashboard-sidebar"
      >
        <DashboardSidebar @hideSidebar="hideSidebar" />
      </div>
      <div class="router-view">
        <router-view />
      </div>
      <el-button
        class="menu-toggle custom-button large-button"
        @click="toggleSidebar"
        :icon="expandIcon"
        size="large"
      ></el-button>
      <!-- <el-button
        class="menu-toggle custom-button"
        @click="toggleSidebar"
        size="large"
      >
        <i class="fa fa-bars" aria-hidden="true"></i>
      </el-button> -->
      <div
        :class="{
          'content-expanded': !isSidebarHidden || !isSmallScreen,
          'content-collapsed': isSidebarHidden && isSmallScreen,
        }"
        class="main-content"
      ></div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, onUnmounted, computed } from "vue";
import { Expand } from "@element-plus/icons-vue";
import DashboardSidebar from "../../components/dashboard-sidebar-component.vue";
import BaseHeader from "../../components/base-header-component.vue";

const expandIcon = Expand;

const isSidebarHidden = ref(true);
const isSmallScreen = ref(window.innerWidth <= 768);

function handleResize() {
  isSmallScreen.value = window.innerWidth <= 768;
  if (!isSmallScreen.value) {
    isSidebarHidden.value = false; // Ensure sidebar is visible on larger screens
  }
}

function toggleSidebar() {
  if (isSmallScreen.value) {
    isSidebarHidden.value = !isSidebarHidden.value;
    // isSidebarHidden.value = false;
  }
}

function hideSidebar() {
  if (isSmallScreen.value) {
    isSidebarHidden.value = true;
  }
}

onMounted(() => {
  handleResize();
  window.addEventListener("resize", handleResize);
});

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});
</script>

<style scoped>
@import url("https://fonts.cdnfonts.com/css/euclid-circular-a");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Euclid Circular A", sans-serif;
  font-weight: 100;
  font-style: normal;
}
.dashboard-home {
  display: block;
  width: 100%;
  background-color: whitesmoke;
}
.sidebar-routerview {
  display: flex;
}
.main-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  transition: margin-left 0.3s ease;
}
.dashboard-sidebar {
  width: 17%;
  background-color: lightgray;
  overflow-y: scroll;
  position: fixed;
  top: 3rem;
  bottom: 0;
  z-index: 99;
  transition: transform 0.3s ease;
  /* border: 2px solid red; */
}
.router-view {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 83%;
  height: 100%;
  margin-left: 17%;
  margin-top: 3rem;
}
.menu-toggle {
  display: none;
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1000;
}
.content-collapsed {
  margin-left: 0;
}
.custom-button {
  font-size: 18px;
  padding: 5px;
}
.large-button {
  font-size: 30px;
  padding: 12px 24px;
  height: auto;
}

/* responsiveness for different screens */
@media (max-width: 768px) {
  .menu-toggle {
    display: block;
    border: none;
    padding: 5px;
  }

  .dashboard-sidebar {
    width: 200px;
    display: block;
  }

  .sidebar-hidden {
    transform: translateX(-100%);
  }

  .sidebar-visible {
    transform: translateX(0);
  }

  .main-content {
    margin-left: 0;
  }

  .router-view {
    width: 100%;
    margin-left: 0;
  }
}
</style>

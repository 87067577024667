<template>
  <el-dialog
    :model-value="isEditAdvertDialogVisible"
    title="Edit Advert"
    :width="dialogWidth"
    :style="{ paddingBottom: '20px' }"
    @close="closeDialog"
    @open="startEditing"
  >
    <el-form class="advert-add-modal-content">
      <el-form-item label="Title">
        <el-input v-model="editedTitle" />
      </el-form-item>
      <el-form-item label="Description">
        <el-input type="textarea" v-model="editedDescription" />
      </el-form-item>

      <div v-loading="loading" class="file-input">
        <div
          v-if="editedFileUrl"
          class="position-relative category-photo"
          style="background: #fdfdfd"
        >
          <img
            class="edited-img"
            :src="editedFileUrl"
            style="object-fit: cover"
          />
          <div
            class="remove-img w-100 d-flex align-items-center justify-content-center"
            style="height: 100%"
          >
            <button
              class="remove-img-btn"
              style="
                font-size: 1.5rem;
                color: #fff;
                background-color: rgba(0, 0, 0, 0.45);
              "
              @click="handleImageEdit"
            >
              Edit Image
            </button>
          </div>
        </div>
        <div
          v-else-if="editedVideoFileUrl"
          class="position-relative category-video"
          style="background: #fdfdfd"
        >
          <iframe
            class="edited-vid"
            :src="editedVideoFileUrl"
            frameborder="0"
            style="object-fit: cover"
          ></iframe>
          <div
            class="remove-vid w-100 d-flex align-items-center justify-content-center"
            style="height: 100%"
          >
            <button
              class="remove-vid-btn"
              style="
                font-size: 1.5rem;
                color: #fff;
                background-color: rgba(0, 0, 0, 0.45);
              "
              @click="handleVideoEdit"
            >
              Edit video
            </button>
          </div>
        </div>
        <div v-else>
          <label class="label-new-upload"> Add a new Video / Picture</label>
          <label class="add-img">
            <div
              class="w-100 d-flex align-items-center justify-content-center"
              style="height: 200px"
            >
              <input type="file" class="d-none" @change="handleFileUpload" />
              <span class="el-icon-plus"></span>
              <span class="material-symbols-outlined"> add </span>
            </div>
          </label>
        </div>
      </div>
      <el-button @click="saveEditedAdvert" type="submit" class="save-button"
        >Save</el-button
      >
    </el-form>
  </el-dialog>
</template>
  
  <script lang="ts" setup>
import {
  ref,
  defineProps,
  defineEmits,
  computed,
  onMounted,
  onUnmounted,
} from "vue";

import axios from "axios";
import Swal from "sweetalert2";
import { useAuthStore } from "../../../store/authStore";

// declaring the token
const token = computed(() => {
  return useAuthStore().token;
});

const props = defineProps<{
  isEditAdvertDialogVisible: boolean;
  advertId: string;
}>();

// emitting the custom event "close-dialog" for closing the dialog
const emit = defineEmits<{
  (e: "close-dialog"): void;
  (e: "fetch-adverts"): void;
}>();

// clearig the inputs
const clearInputs = () => {
  editedTitle.value = "";
  editedDescription.value = "";
  pictureAd.value = "";
  editedVideo.value = "";
  editedImage.value = "";
  editedFileUrl.value = "";
  editedVideoFileUrl.value = "";
  editedId.value = "";
};

const closeDialog = () => {
  emit("close-dialog");
  clearInputs();
};

// refs
const editedTitle = ref("");
const editedDescription = ref("");
const pictureAd = ref("");
const editedVideo = ref("");
const editedImage = ref("");
const editedFileUrl = ref("");
const editedVideoFileUrl = ref("");
const editedId = ref("");
const loading = ref(false);

const windowWidth = ref(window.innerWidth);

// Computed property for responsive dialog width
const dialogWidth = computed(() => {
  const width = windowWidth.value <= 480 ? "90%" : "350px";
  const maxWidth = "400px"; // Maximum width for large screens
  return `min(${width}, ${maxWidth})`;
});

function handleResize() {
  windowWidth.value = window.innerWidth; // Update the reactive windowWidth
}
// Watch for window resize events
onMounted(() => {
  window.addEventListener("resize", handleResize);
  handleResize();
});

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});

// warning swal
const warningSwal = async () => {
  // Show error message
  const result = await Swal.fire({
    title: "Warning",
    text: "Invalid file type. Please select an image or a video.",
    icon: "warning",
    confirmButtonColor: "#3085d6",
  });
  //   keeping the dialog there.
  if (result.isConfirmed) {
    // dialogVisible.value = true;
  }
};
// handling image editing
const handleImageEdit = () => {
  editedFileUrl.value = "";
  pictureAd.value = "";
  editedImage.value = "";
};

// handling video editing
const handleVideoEdit = () => {
  editedVideoFileUrl.value = "";
  editedVideo.value = "";
};

// handling the  file upload
const handleFileUpload = (event: any) => {
  event.preventDefault();
  const file = event.target.files?.[0];
  if (file) {
    const fileType = file.type;
    if (fileType.startsWith("image/") || fileType.startsWith("video/")) {
      const fileUrl = URL.createObjectURL(file);
      //   Set the selected file URL to the appropriate reactive variable
      if (fileType.startsWith("image/")) {
        editedImage.value = file;
        editedFileUrl.value = fileUrl;
        editedVideoFileUrl.value = "";
      } else {
        editedVideo.value = file;
        editedVideoFileUrl.value = fileUrl;
        editedFileUrl.value = "";
        console.log("video");
      }
    } else {
      console.log("File type not allowed");
      warningSwal();
    }
    console.log("Uploaded file:", file);
  }
};

// starting to edit the advert
const startEditing = async () => {
  try {
    loading.value = true;
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/adverts",
      {
        method: "advert_details",
        advert_id: props.advertId,
      },
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );
    if (response.data.success) {
      const results = response.data.result;

      editedTitle.value = results.advert_info.title;
      editedDescription.value = results.advert_info.description;
      editedFileUrl.value =
        results.advert_info.picture == null ? "" : results.advert_info.picture;
      editedVideoFileUrl.value =
        results.advert_info.video == null ? "" : results.advert_info.video;
      editedId.value = results.advert_info.id;
    } else {
      // Handle other error cases
      const errorMessage = response.data.errors || "Failed to load data";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#d33",
    });
  } finally {
    loading.value = false;
  }
};

// saving the edited advert
const saveEditedAdvert = async () => {
  try {
    loading.value = true;
    if (
      editedTitle.value === "" ||
      editedDescription.value === "" ||
      editedId.value.toString() === "" ||
      (editedFileUrl.value === "" &&
        editedVideoFileUrl.value === "" &&
        editedImage.value === "" &&
        editedVideo.value === "")
    ) {
      const result = await Swal.fire({
        title: "Warning",
        text: "All fields are required",
        icon: "warning",
        confirmButtonColor: "#3085d6",
      });
      if (result.isConfirmed) {
        return "";
      }
    } else {
      const formData = new FormData();
      formData.append("method", "edit_advert");
      formData.append("title", editedTitle.value);
      formData.append("advert_id", editedId.value.toString());
      formData.append("description", editedDescription.value);

      // Appending file data based on whether it's a picture or video
      if (editedImage.value) {
        formData.append("picture", editedImage.value);
      } else if (editedVideo.value) {
        formData.append("video", editedVideo.value);
      }

      const response = await axios.post(
        // additional to global baseUrl endpoint
        "/adverts",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        }
      );
      if (response.data.success) {
        closeDialog();
        emit("fetch-adverts");
        Swal.fire({
          title: "Successful",
          text: "Your Advert has been successfully updated",
          icon: "success",
          confirmButtonColor: "#3085d6",
        });
        //   showEditModal.value = false;
        closeDialog();
        emit("fetch-adverts");
      } else {
        // Handle error cases
        const errorMessage =
          response.data.errors || "Failed to restore advert.";
        return Swal.fire({
          title: "Error",
          text: errorMessage,
          icon: "error",
          confirmButtonColor: "#d33",
        });
      }
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#3085d6",
    });
  } finally {
    loading.value = false;
  }
};
</script>
  
  <style scoped>
.modal,
.edit-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content,
.advert-add-modal-content {
  background-color: #fefefe;
  margin: 2% auto;
  padding: 20px;
  border-radius: 10px;
  /* width: 30rem; */
  height: auto;
}
.edited-img,
.edited-vid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  margin: 2rem auto;
  object-fit: cover;
}

.remove-img,
.remove-vid {
  display: none;
}
.remove-img-btn,
.remove-vid-btn {
  display: none;
}
.category-photo:hover .remove-img-btn {
  display: block;
}

.save-button {
  background-color: #662d91;
  color: #fff;
  border: none;
  padding: 20px 3rem;
  cursor: pointer;
  display: block;
  margin: 1rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

/* CSS for button hover effect */
.save-button:hover {
  background-color: transparent;
  border: 1px solid #662d91;
  color: #662d91;
}
.material-symbols-outlined:hover {
  cursor: pointer;
}
.material-symbols-outlined {
  font-size: 2rem;
  padding-right: 0.6rem;
}
.category-photo:hover .remove-img {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgb(0, 0, 0, 0.45);
}
.category-video:hover .remove-vid {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgb(0, 0, 0, 0.45);
}

.category-photo:hover .remove-img-btn,
.category-video:hover .remove-vid-btn {
  display: block;
}
.add-img {
  border: 1px dashed rgb(189, 187, 187);
  border-radius: 5px;
  height: 80%;
  font-size: 3em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
<template>
  <div id="app" v-loading="loading">
    <div id="chart">
      <p class="chart-description">
        Active Weekly, Monthly users and Total yearly user increase
      </p>
      <VueApexCharts type="donut" :options="chartOptions" :series="series" />
    </div>
    <div class="calendar">
      <div class="header">
        <h2>{{ month }}</h2>
        <div class="buttons-btn">
          <button class="arrow-button" @click="previousMonth">
            <i class="fas fa-arrow-left"></i>
          </button>
          <button class="arrow-button" @click="nextMonth">
            <i class="fas fa-arrow-right"></i>
          </button>
        </div>
      </div>
      <div class="days">
        <div v-for="day in daysOfWeek" :key="day" class="day">{{ day }}</div>
        <div
          v-for="blankDay in leadingBlanks"
          :key="'blank-' + blankDay"
          class="blank-day"
        ></div>
        <div
          v-for="date in daysInMonth"
          :key="date"
          class="date"
          :class="{ today: isToday(date) }"
        >
          {{ date }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import axios from "axios";
import { ref, computed, onMounted } from "vue";
import VueApexCharts from "vue3-apexcharts";
import { useAuthStore } from "../store/authStore";
import Swal from "sweetalert2";

// token
const token = computed(() => useAuthStore().token);

// loader
const loading = ref(false);

const labels = [
  " Weekly Users",
  " Monthly Users",
  "Users This Year",
  "Total Users",
];

const series = ref<number[]>([]);

const chartOptions = ref({
  chart: {
    type: "donut",
  },
  labels: labels,
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 300,
          height: 300,
        },
        legend: {
          position: "bottom",
        },
      },
    },
  ],
});

const fetchUserVariations = async () => {
  try {
    loading.value = true;
    const response = await axios.post(
      "/users",
      { method: "stats" },
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );
    if (response.data.success) {
      const data = response.data;
      const percentageData = [
        parseFloat(data.percent_new_weekly_users),
        parseFloat(data.percent_new_monthly_users),
        parseFloat(data.percent_new_users_this_year),
        parseFloat(data.percent_total_users),
      ];
      series.value = percentageData;
    } else {
      // Handle other error cases
      const errorMessage = response.data.errors || "Failed to fetch data";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#d33",
    });
  } finally {
    loading.value = false;
  }
};

onMounted(() => {
  fetchUserVariations();
});

// calander
const daysOfWeek = ref(["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]);
const currentDate = ref(new Date());

// calculating the month
const month = computed(() => {
  return currentDate.value.toLocaleString("default", {
    month: "long",
    year: "numeric",
  });
});

const daysInMonth = computed(() => {
  const year = currentDate.value.getFullYear();
  const month = currentDate.value.getMonth();
  const daysInMonth = new Date(year, month + 1, 0).getDate();
  return Array.from({ length: daysInMonth }, (_, index) => index + 1);
});

const leadingBlanks = computed(() => {
  const year = currentDate.value.getFullYear();
  const month = currentDate.value.getMonth();
  const firstDayOfMonth = new Date(year, month, 1).getDay();
  const shiftedFirstDayOfMonth =
    firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1;
  return Array.from({ length: shiftedFirstDayOfMonth }, (_, index) => index);
});

// going back to the previous month
const previousMonth = () => {
  const year = currentDate.value.getFullYear();
  const month = currentDate.value.getMonth();
  if (month === 0) {
    currentDate.value = new Date(year - 1, 11, 1);
  } else {
    currentDate.value = new Date(year, month - 1, 1);
  }
};

// going to the next month
const nextMonth = () => {
  const year = currentDate.value.getFullYear();
  const month = currentDate.value.getMonth();
  if (month === 11) {
    currentDate.value = new Date(year + 1, 0, 1);
  } else {
    currentDate.value = new Date(year, month + 1, 1);
  }
};

// checking the day today
const isToday = (date: number) => {
  const today = new Date();
  return (
    date === today.getDate() &&
    currentDate.value.getMonth() === today.getMonth() &&
    currentDate.value.getFullYear() === today.getFullYear()
  );
};
</script>

<style scoped>
#app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

#chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex-grow: 1;
  margin-top: -2rem;
}

.chart-description {
  margin-top: 1rem;
  padding: 1rem;
  color: #662d91;
}
.calendar {
  background-color: white;
  width: 100%;
  margin: 0.5rem;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem;
}
.arrow-button {
  background-color: #cce1f6;
  border: none;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
}
i {
  color: #2a6fb5;
  font-size: 10px;
}

.arrow-button:hover {
  opacity: 0.8;
}
.buttons-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.days {
  display: grid;
  padding: 0.5rem;
  grid-template-columns: repeat(7, 1fr);
  margin-top: 1rem;
}

.day {
  text-align: center;
  padding: 0.5rem;
  font-size: 10px;
  font-weight: 500;
}

.blank-day {
  padding: 0.5rem;
}
.date {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.7rem;
}
.today {
  border: 1px solid #2a6fb5;
  border-radius: 1px;
  width: 20px;
  height: 20px;
}

h2 {
  font-size: 0.8rem;
}
.upcoming-lectures {
  margin: 3rem 0 0 1rem;
}
.upcoming-lectures p:first-child {
  color: #2a6fb5;
  font-size: 0.7rem;
  margin-bottom: 5px;
  font-weight: 500;
}
.upcoming-lectures p:last-child {
  font-size: 0.7rem;
}
</style>
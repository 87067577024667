<template>
  <section class="cards">
    <div class="top-cards">
      <div class="adminStatsCards" style="margin-left: 0">
        <p>Total Registered Users</p>
        <P>{{ totalUsers }}</P>
      </div>
      <div class="adminStatsCards">
        <p>Total Created Adverts</p>
        <P>{{ totalAdverts }}</P>
      </div>
      <div class="adminStatsCards">
        <p>Total Verified Adverts</p>
        <P>{{ totalVerifiedAdverts }}</P>
      </div>
      <div class="adminStatsCards">
        <p>Top Purchased Adverts</p>
        <P>{{ topPurchasedAdverts }}</P>
      </div>
    </div>
    <div class="bottom-cards">
      <div class="adminStatsCards" style="margin-right: 0">
        <p>Purchased Today</p>
        <P>{{ purchasedToday }}</P>
      </div>
      <div class="adminStatsCards">
        <p>Total Monthly sales</p>
        <P>{{ Math.floor(monthlySales) }}</P>
      </div>
      <div class="adminStatsCards">
        <p>Total Yearly sales</p>
        <P>{{ Math.floor(yearlySales) }}</P>
      </div>
    </div>
  </section>
</template>
  
  <script lang="ts" setup>
// imports
import { ref, onMounted, computed, onUnmounted, defineEmits } from "vue";
import axios from "axios";
import { useAuthStore } from "../../store/authStore";
import Swal from "sweetalert2";

// token
const token = computed(() => {
  return useAuthStore().token;
});

// refs
const totatlAdvertsToday = ref(0);
const advertPurchaseToday = ref(0);
const totalUsers = ref("");
const totalAdverts = ref(0);
const totalVerifiedAdverts = ref(0);
const topPurchasedAdverts = ref(0);
const purchasedToday = ref(0);
const monthlySales = ref(0);
const yearlySales = ref(0);

// dashboard statistics
const dashboardStat = async () => {
  try {
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/payments",
      { method: "stats" },
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );
    if (response.data.success) {
      const results = response.data.result;
      // Assigning each local variable to the value from the server
      totalUsers.value = results.total_users;
      totalAdverts.value = results.total_Adverts;
      totalVerifiedAdverts.value = results.total_verified_adverts;
      topPurchasedAdverts.value = results.top_purchased_Adverts.length;
      purchasedToday.value = results.purchases_today;
      monthlySales.value = results.total_sales_this_month;
      yearlySales.value = results.total_sales_this_year;
    } else {
      // Handle other error cases
      const errorMessage =
        response.data.errors || "Failed to fetch dashboard stat";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#3085d6",
    });
  }
};

// Method for payments starts for adverts today
const paymentsStatsOnAdvertsToday = async () => {
  try {
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/payments",
      {
        method: "stats",
      },
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );
    if (response.data.success) {
      totatlAdvertsToday.value = response.data.result.total_Adverts;
      advertPurchaseToday.value = response.data.result.purchases_today;
    } else {
      // Handle other error cases
      const errorMessage =
        response.data.errors ||
        "Failed to fetch payment stats on adverts today.";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#3085d6",
    });
  }
};
// Defining custom events to emit
const emits = defineEmits(["loaded"]);

// Emiting events when data is loaded
const emitLoadedEvent = () => {
  emits("loaded");
};

let interval: number;

onMounted(() => {
  paymentsStatsOnAdvertsToday();
  dashboardStat();
  emitLoadedEvent();
});

onUnmounted(() => {
  clearInterval(interval);
});
</script>
  
  <style scoped>
.cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin: 2rem auto;
  width: 90%; /* Ensure content fits well on smaller screens */
}
.top-cards,
.bottom-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  justify-content: center;
  width: 100%;
}

.bottom-cards .adminStatsCards {
  background-color: #662d91;
  color: white;
}

.adminStatsCards {
  background-color: white;
  border-radius: 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 1rem;
  text-align: center;
  flex: 1;
  min-width: 100px;
}
.adminStatsCards p {
  text-align: center;
}
.adminStatsCards p:first-child {
  font-size: 0.8rem;
  font-weight: 700;
  /* margin-top: 0.5rem; */
  padding: 5px 1rem;
}
.adminStatsCards p:last-child {
  font-size: 1rem;
  margin-bottom: 20px;
}
/* Media Query for Smaller Screens */
@media (max-width: 768px) {
  .adminStatsCards {
    flex: 1 1 calc(50% - 1rem);
  }
}

@media (max-width: 480px) {
  .adminStatsCards {
    flex: 1 1 100%;
  }
}
</style>
<template>
  <el-dialog
    :model-value="isPaymentDetailsDialogVisible"
    title="Transaction details for an AD"
    :width="dialogWidth"
    :style="{ paddingBottom: '20px' }"
    @close="closeDialog"
    @open="viewPaymentsDetails"
  >
    <div v-loading="loading">
      <div :style="{ border: '5px dotted #000', padding: '10px' }">
        <el-row :style="{ paddingBottom: '10px' }">
          <el-col :span="12" :style="{ paddingLeft: '20px' }"
            ><b>Trans Status:</b></el-col
          >
          <el-col :span="12">{{ advertDetails.transaction_status }}</el-col>
        </el-row>
        <el-row :style="{ paddingBottom: '10px' }">
          <el-col :span="12" :style="{ paddingLeft: '20px' }"
            ><b>Amount:</b></el-col
          >
          <el-col :span="12">{{ advertDetails.amount_paid }}</el-col>
        </el-row>
        <el-row :style="{ paddingBottom: '10px' }">
          <el-col :span="12" :style="{ paddingLeft: '20px' }"
            ><b>Payment Id:</b></el-col
          >
          <el-col :span="12">{{ advertDetails.payment_id }}</el-col>
        </el-row>
        <el-row :style="{ paddingBottom: '10px' }">
          <el-col :span="12" :style="{ paddingLeft: '20px' }"
            ><b>Name:</b></el-col
          >
          <el-col :span="12">{{ advertDetails.payer_name }}</el-col>
        </el-row>
        <el-row :style="{ paddingBottom: '10px' }">
          <el-col :span="12" :style="{ paddingLeft: '20px' }">
            <b>Payment Date:</b>
          </el-col>
          <el-col :span="12">{{
            formatDate(advertDetails.payment_date)
          }}</el-col>
        </el-row>
        <el-row :style="{ paddingBottom: '10px' }">
          <el-col :span="12" :style="{ paddingLeft: '20px' }"
            ><b>User Id</b></el-col
          >
          <el-col :span="12">{{ advertDetails.user_id }}</el-col>
        </el-row>
        <el-row :style="{ paddingBottom: '10px' }">
          <el-col :span="12" :style="{ paddingLeft: '20px' }"
            ><b>Advert Id</b></el-col
          >
          <el-col :span="12">{{ advertDetails.advert_id }}</el-col>
        </el-row>
      </div>
    </div>
  </el-dialog>
</template>
  
  <script lang="ts" setup>
import {
  ref,
  defineProps,
  defineEmits,
  computed,
  onMounted,
  onUnmounted,
} from "vue";
import axios from "axios";
import { useAuthStore } from "../../../store/authStore";
import AdvertDetails from "../../../types/AdvertDetails";
import Swal from "sweetalert2";

// Declaring the token
const token = computed(() => {
  return useAuthStore().token;
});

// defining loading
const loading = ref(false);
// Define props
const props = defineProps<{
  isPaymentDetailsDialogVisible: boolean;
  advertId: string;
}>();

// defining the emit
const emit = defineEmits<{
  (e: "close-dialog"): void;
}>();

// emitting the custom event "close-dialog" for closing the dialog

const closeDialog = () => {
  emit("close-dialog");
};

const windowWidth = ref(window.innerWidth);

// Computed property for responsive dialog width
const dialogWidth = computed(() => {
  const width = windowWidth.value <= 480 ? "90%" : "500px";
  const maxWidth = "800px"; // Maximum width for large screens
  return `min(${width}, ${maxWidth})`;
});

function handleResize() {
  windowWidth.value = window.innerWidth; // Update the reactive windowWidth
}
// Watch for window resize events
onMounted(() => {
  window.addEventListener("resize", handleResize);
  handleResize();
});

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});

const formatDate = (dateString: string) => {
  // Convert the date string to a Date object
  const date = new Date(dateString);
  // Format the date using toLocaleString method
  return date.toLocaleString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};

// declaring the advert details and giving it a type of AdvertDetails
const advertDetails = ref<AdvertDetails>({
  transaction_status: "",
  amount_paid: "",
  payment_id: "",
  payer_name: "",
  payment_date: "",
  user_id: "",
  advert_id: "",
  payee_currency: "",
  mobile: "",
});

// Method for viewing the payment details
const viewPaymentsDetails = async () => {
  try {
    loading.value = true;
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/payments",
      {
        method: "transaction_details",
        advert_id: props.advertId,
      },

      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );
    if (response.data.success) {
      console.log(response.data);

      const results = response.data;

      // Updating advertDetails with transaction details
      advertDetails.value = {
        transaction_status: results.transaction_status,
        amount_paid: results.amount_paid,
        payment_id: results.payment_id,
        advert_id: results.advert_id,
        mobile: results.mobile,
        payer_name: results.payer_name,
        payment_date: results.payment_date,
        payee_currency: results.payee_currency,
        user_id: results.user_id,
      };
    } else {
      // Handle other error cases
      const errorMessage =
        response.data.errors || "Failed to load payment details";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#3085d6",
    });
  } finally {
    loading.value = false;
  }
};
//

// Define type for advert details
</script>

  
<template>
  <el-dialog
    :model-value="isEditProductDialogVisible"
    title="Edit Product"
    :width="dialogWidth"
    :style="{ paddingBottom: '20px' }"
    @close="closeDialog"
    @open="startEditingProduct"
  >
    <el-form class="advert-add-modal-content">
      <el-form-item label="Title">
        <el-input v-model="editedTitle" />
      </el-form-item>
      <el-form-item label="Price">
        <el-input v-model="editedPrice" />
      </el-form-item>
      <el-form-item label="Phone">
        <el-input v-model="editedPhone" />
      </el-form-item>
      <el-form-item label="Location">
        <el-input v-model="editedLocation" />
      </el-form-item>
      <el-form-item label="Description">
        <el-input type="textarea" v-model="editedDescription" />
      </el-form-item>

      <div v-loading="loading" class="file-input">
        <!-- Showing the file preview if a file has been selected -->
        <template v-if="editedFileUrl">
          <div
            v-if="editedFileUrl"
            class="position-relative category-photo"
            style="background: #fdfdfd"
          >
            <img
              class="edited-img"
              :src="editedFileUrl"
              style="object-fit: cover"
            />
            <div
              class="remove-img w-100 d-flex align-items-center justify-content-center"
              style="height: 100%"
            >
              <el-button
                class="remove-img-btn"
                style="
                  font-size: 1.5rem;
                  color: #fff;
                  background-color: rgba(0, 0, 0, 0.45);
                "
                @click="handleImageEdit"
              >
                Edit Image
              </el-button>
            </div>
          </div>
        </template>
        <!-- Showing the file selection input if no file has been selected -->
        <div v-else>
          <label class="label-new-upload"> Add a new Picture</label>
          <label class="add-img">
            <div
              class="w-100 d-flex align-items-center justify-content-center"
              style="height: 200px"
            >
              <input type="file" class="d-none" @change="handleFileUpload" />
              <span class="el-icon-plus"></span>
              <span class="material-symbols-outlined"> add </span>
            </div>
          </label>
        </div>
      </div>
      <el-button @click="saveEditedProduct" type="submit" class="update-btn"
        >Update</el-button
      >
    </el-form>
  </el-dialog>
</template>
    
    <script lang="ts" setup>
// imports
import {
  ref,
  defineProps,
  defineEmits,
  computed,
  onMounted,
  onUnmounted,
} from "vue";

import axios from "axios";
import Swal from "sweetalert2";
import { useAuthStore } from "../../../store/authStore";

// the token
const token = computed(() => {
  return useAuthStore().token;
});

const props = defineProps<{
  isEditProductDialogVisible: boolean;
  productId: string;
}>();

// emitting the custom event "close-dialog" for closing the dialog
const emit = defineEmits<{
  (e: "close-dialog"): void;
  (e: "fetch-all-proucts"): void;
}>();

const closeDialog = () => {
  emit("close-dialog");
};
// refs
const editedTitle = ref("");
const editedPrice = ref("");
const editedPhone = ref("");
const editedLocation = ref("");
const editedDescription = ref("");
const pictureAd = ref("");
const editedImage = ref("");
const editedFileUrl = ref("");
const editedId = ref("");
const loading = ref(false);

const windowWidth = ref(window.innerWidth);

// Computed property for responsive dialog width
const dialogWidth = computed(() => {
  const width = windowWidth.value <= 480 ? "90%" : "300px";
  const maxWidth = "400px"; // Maximum width for large screens
  return `min(${width}, ${maxWidth})`;
});

function handleResize() {
  windowWidth.value = window.innerWidth; // Update the reactive windowWidth
}
// Watch for window resize events
onMounted(() => {
  window.addEventListener("resize", handleResize);
  handleResize();
});

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});

// sweet alert fro warning
const warningSwal = async () => {
  // Show error message
  const result = await Swal.fire({
    title: "Warning",
    text: "Invalid file type. Please select an image or a video.",
    icon: "warning",
    confirmButtonColor: "#3085d6",
  });
  //   keeping the dialog there.
  if (result.isConfirmed) {
    return "";
  }
};
// handling  the image editing
const handleImageEdit = () => {
  editedFileUrl.value = "";
  pictureAd.value = "";
};

// handling file upload
const handleFileUpload = (event: any) => {
  // event.preventDefault();
  const file = event.target.files?.[0];
  if (file) {
    const fileType = file.type;
    if (fileType.startsWith("image/")) {
      const fileUrl = URL.createObjectURL(file);
      editedImage.value = file;
      editedFileUrl.value = fileUrl;
    } else {
      warningSwal();
    }
    console.log("Uploaded file:", file);
  }
};

// starting to edit editing the product
const startEditingProduct = async () => {
  try {
    loading.value = true;
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/products",
      {
        method: "product_details",
        product_id: props.productId,
      },
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );
    if (response.data.success) {
      //  declaring results to hold the entire result array
      const results = response.data.result;

      editedTitle.value = results.product_info.title;
      editedDescription.value = results.product_info.description;
      editedFileUrl.value = results.product_info.picture;
      editedId.value = results.product_info.id;
      editedPrice.value = results.product_info.price;
      editedLocation.value = results.product_info.location;
      editedPhone.value = results.product_info.phone_number;
    } else {
      // Handle other error cases
      const errorMessage = response.data.errors || "Failed to fetch data";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#d33",
    });
  } finally {
    loading.value = false;
  }
};

// saving the edited product
const saveEditedProduct = async () => {
  try {
    loading.value = true;
    if (
      editedTitle.value === "" ||
      editedDescription.value === "" ||
      editedLocation.value === "" ||
      editedPrice.value.toString() === "" ||
      editedPhone.value.toString() === "" ||
      editedId.value.toString() === "" ||
      editedFileUrl.value === "" ||
      !editedFileUrl.value
    ) {
      const result = await Swal.fire({
        title: "Warniing",
        text: "All fields are required",
        icon: "warning",
        confirmButtonColor: "#3085d6",
      });
      if (result.isConfirmed) {
        return "";
      }
    } else {
      const formData = new FormData();
      formData.append("method", "edit_product");
      formData.append("title", editedTitle.value);
      formData.append("description", editedDescription.value);
      formData.append("location", editedLocation.value);
      formData.append("price", editedPrice.value.toString());
      formData.append("phone_number", editedPhone.value);
      formData.append("product_id", editedId.value.toString());
      formData.append("picture", editedImage.value);

      const response = await axios.post(
        // additional to global baseUrl endpoint
        "/products",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        }
      );
      if (response.data.success) {
        console.log(response.data);
        closeDialog();

        const result = await Swal.fire({
          title: "Successful",
          text: "Product has been successfully updated",
          icon: "success",
          confirmButtonColor: "#3085d6",
        });
        if (result.isConfirmed) {
          closeDialog();
          return emit("fetch-all-proucts");
        }
      } else {
        // Handle other error cases
        const errorMessage = response.data.errors || "Failed to update product";
        return Swal.fire({
          title: "Error",
          text: errorMessage,
          icon: "error",
          confirmButtonColor: "#d33",
        });
      }
    }
  } catch (error: any) {
    // checking of the product is being published, we send the swal to show the error
    if (error.response.data.errors === "Cannot edit a published product") {
      const result = await Swal.fire({
        title: "Can't Edit",
        text: "Cannot edit a published product",
        icon: "error",
        confirmButtonColor: "#3085d6",
      });
      if (result.isConfirmed) {
        closeDialog();
        return emit("fetch-all-proucts");
      }
    } else {
      return Swal.fire({
        title: "Error",
        text: error.message,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } finally {
    loading.value = false;
  }
};
</script>
    
    <style scoped>
.modal-content,
.advert-add-modal-content {
  background-color: #fefefe;
  margin: 5% auto;
  padding: 20px;
  border-radius: 10px;
  /* width: 30rem; */
  height: auto;
}
.edited-img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 250px;
  margin: 2rem auto;
}
.remove-img {
  display: none;
}
.remove-img-btn {
  display: none;
}

.category-photo:hover .remove-img {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgb(0, 0, 0, 0.45);
}
.category-photo:hover .remove-img-btn {
  display: block;
}

.update-btn {
  background-color: #662d91;
  color: #fff;
  border: none;
  padding: 20px 3rem;
  cursor: pointer;
  display: block;
  margin: 1rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

/* CSS for button hover effect */
.update-btn:hover {
  background-color: transparent;
  border: 1px solid #662d91;
  color: #662d91;
}
.material-symbols-outlined:hover {
  cursor: pointer;
}
.material-symbols-outlined {
  font-size: 2rem;
  padding-right: 0.6rem;
}
.add-img {
  border: 1px dashed rgb(189, 187, 187);
  border-radius: 5px;
  /* height: 80%; */
  font-size: 3em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
<template>
  <div class="payments-container">
    <div class="home-user">
      <span class="material-symbols-outlined" @click="goHome()">
        grid_view
      </span>
      <div class="right-arrow-h4">
        <el-button class="right-arrow" :icon="arrowIcon" circle></el-button>
        <h4>All users withdraws</h4>
      </div>
    </div>
    <h3>All users withdraws</h3>
    <div class="inputs-btn">
      <section class="inputs-section">
        <el-input
          v-model="searchInput"
          type="text"
          style="width: 200px"
          :placeholder="placeholder"
          @input="onSearch"
        />
        <el-select
          v-model="searchFilter"
          placeholder="Search By Reference"
          style="width: 200px"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </section>

      <div>
        <el-button class="download-csv-btn" @click="downloadCsv" type="primary"
          >Download CSV</el-button
        >
      </div>
    </div>

    <!-- spinning loader -->

    <!-- table of payments -->
    <div class="users-withdraw-table">
      <el-table v-loading="loading" :data="rows" border style="width: 100%">
        <el-table-column label="#" width="50">
          <template v-slot="{ $index }">
            {{ (currentPage - 1) * pageSize + ($index + 1) }}
          </template>
        </el-table-column>

        <el-table-column label="Id" class="bold-label">
          <template v-slot="{ row }">
            <el-tooltip class="item" effect="dark" :content="row.id.toString()">
              <div>
                {{ truncateAdvertId(row.id) }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>

        <el-table-column label="Mode">
          <template v-slot="{ row }">{{ row.mode }}</template>
        </el-table-column>
        <el-table-column label="Amount">
          <template v-slot="{ row }">{{ row.amount }}</template>
        </el-table-column>
        <el-table-column label="Status">
          <template v-slot="{ row }">{{
            row.status === "t" ? "Success" : row.status
          }}</template>
        </el-table-column>
        <el-table-column label="Currency">
          <template v-slot="{ row }">{{ row.currency }}</template>
        </el-table-column>
        <el-table-column label="Phone">
          <template v-slot="{ row }">
            <el-tooltip class="item" effect="dark" :content="row.phone">
              <div>{{ truncatePhoneNumber(row.phone) }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="Trans Id">
          <template v-slot="{ row }">
            <el-tooltip
              class="item"
              effect="dark"
              :content="row.transaction_id"
            >
              <div>{{ row.transaction_id }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="Time">
          <template v-slot="{ row }">
            <el-tooltip
              class="item"
              effect="dark"
              :content="getFullTimestamp(row.datetime)"
            >
              <div>{{ formatFullTimestamp(row.datetime) }}</div>
            </el-tooltip>
          </template>
        </el-table-column>

        <el-table-column label="Ref">
          <template v-slot="{ row }">
            <el-tooltip class="item" effect="dark" :content="row.reference">
              <div>{{ truncateReference(row.reference) }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <div>
        <el-pagination
          class="pagination"
          :current-page="currentPage"
          :small="small"
          :page-size="pageSize"
          :total="total"
          :disabled="disabled"
          :background="background"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          layout="total, sizes, prev, pager, next, jumper"
        />
      </div>
    </div>
  </div>
</template>
    
    <script lang="ts" setup>
import { ref, onMounted, computed } from "vue";
import axios from "axios";
import Payment from "../../types/Payment";
import User from "../../types/User";
import { ArrowRight } from "@element-plus/icons-vue";
import { useRouter } from "vue-router";
import debounce from "debounce";
import { useAuthStore } from "../../store/authStore";
import Swal from "sweetalert2";

// token
const token = computed(() => {
  return useAuthStore().token;
});

//  refs
const currentPage = ref(1);
const pageSize = ref(10);
const small = ref(false);
const background = ref(false);
const disabled = ref(false);
const total = ref(0);

const searchInput = ref("");
const searchFilter = ref("Search By Reference");

// we are creating an empty array of Payments objects
const rows = ref<Payment[]>([]);

// LOADING SPINNER

const loading = ref(false);

const arrowIcon = ArrowRight;

// defingin router
const router = useRouter();

// redirecting home
const goHome = () => {
  router.push("/dashboard");
};
// getting the first name

// truncating phone number
const truncatePhoneNumber = (phone: string) => {
  if (phone.length > 6) {
    return phone.slice(0, 6) + "...";
  } else {
    return phone;
  }
};

// truncating advert id
const truncateAdvertId = (id: number) => {
  const advertIdString = id.toString();
  if (advertIdString.length > 6) {
    return advertIdString.slice(0, 6) + "...";
  } else {
    return advertIdString;
  }
};

// truncating reference
const truncateReference = (reference: string) => {
  if (reference.length > 6) {
    return reference.slice(0, 6) + "...";
  } else {
    return reference;
  }
};

// time truncating
const formatFullTimestamp = (datetime: string) => {
  const date = new Date(datetime);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  return `${day}/${month}...`;
};

const formatTruncatedTimestamp = (datetime: string) => {
  return new Date(datetime).toLocaleString(); // Display full timestamp
};

const getFullTimestamp = (datetime: string) => {
  return formatTruncatedTimestamp(datetime);
};

// option in the search box
const options = ref([
  { label: "Search By Reference", value: "reference" },
  { label: "Search By Id", value: "id" },
]);

// dynamic placeholder
const placeholder = computed((): string => {
  if (searchFilter.value === "reference") return "Search By Reference";
  else if (searchFilter.value === "id") return "Search By Id";
  else return "Search By Reference";
});

// fetching all the payment from the api
const fatchAllUsersWithdraws = async () => {
  try {
    loading.value = true;
    rows.value = [];
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/payments",
      {
        method: "get_users_withdraws",
        page: currentPage.value,
        reference: searchFilter.value === "reference" ? searchInput.value : "",
        id: searchFilter.value === "id" ? +searchInput.value : null,
        amount: searchFilter.value === "amount" ? +searchInput.value : null,
      },
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );
    if (response.data.success) {
      const results = response.data.result;

      //   looping through the results and getting all the payments
      results.forEach((payment: Payment) => {
        const paymentInfo = {
          id: payment.id,
          status: payment.status,
          transaction_id: payment.transaction_id,
          amount: payment.amount,
          datetime: payment.datetime,
          currency: payment.currency,
          mode: payment.mode,
          transaction_type: payment.transaction_type,
          reference: payment.reference,
          username: (payment.user as User).username,
          country: (payment.user as User).country,
          phone: (payment.user as User).phone,
        };
        // pushing the data into our rows ( the entire data)
        rows.value.push(paymentInfo as Payment);
      });
      total.value = parseInt(response.data.total_payments);
    } else {
      // Handle other error cases
      const errorMessage = response.data.errors || "Failed to fetch payments.";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    let errorMessage = "An unexpected error occurred.";
    if (error.response && error.response.data && error.response.data.errors) {
      errorMessage = error.response.data.errors;
    } else if (error.message) {
      errorMessage = error.message;
    }
    return Swal.fire({
      title: "Error",
      text: errorMessage,
      icon: "error",
      confirmButtonColor: "#3085d6",
    });
  } finally {
    loading.value = false;
  }
};

// handles the  clicks of the current page clicked
const handleCurrentChange = async (val: number) => {
  currentPage.value = val;
  await fatchAllUsersWithdraws();
};

// handles the page size
const handleSizeChange = async (val: number) => {
  pageSize.value = val;
  currentPage.value = 1;
  await fatchAllUsersWithdraws();
};

// downloading the csv (comma separated values) file
const downloadCsv = async () => {
  try {
    loading.value = true;
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/payments",
      {
        method: "download_payments",
      },
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
        responseType: "text", // Setting response type to plain text
      }
    );

    if (response.status === 200) {
      const csvData = response.data;

      // Creating a Blob object containing the CSV content
      const blob = new Blob([csvData], {
        type: "text/csv;charset=utf-8;",
      });
      // Creating a temporary anchor element
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", "withdraws.csv");
      // Triggering the click event on the anchor element to initiate the download
      document.body.appendChild(link);
      link.click();
      // Removing the temporary anchor element
      document.body.removeChild(link);
    } else {
      // Handle other error cases
      const errorMessage = response.data.errors || "Failed to download csv.";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#3085d6",
    });
  } finally {
    loading.value = false;
  }
};

const onSearch = debounce(function () {
  fatchAllUsersWithdraws();
}, 500);
onMounted(fatchAllUsersWithdraws);
</script>
    
    <style scoped>
.payments-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 95%;
}
.home-user {
  display: flex;
  align-items: center;
  margin-left: 0;
}
.right-arrow {
  background-color: transparent;
  border: none;
  color: gray;
}
h4 {
  font-size: 1rem;
  font-weight: 300;
  margin-top: 0.5rem;
}
.right-arrow-h4 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.material-symbols-outlined {
  font-size: 1.2rem;
  margin-left: 0;
}
.material-symbols-outlined:hover {
  cursor: pointer;
}

h3 {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 2rem 0 3rem 0;
  color: #662d91;
}

.pagination {
  margin: 1rem auto;
}
.users-withdraw-table {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}
.el-table {
  overflow-x: auto;
}
.el-table th,
.el-table td {
  padding: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.download-csv-btn {
  padding: 17px 20px;
  margin: 5px;
  font-size: 12px;
  border: none;
  margin-right: 0;
  background: #662d91;
}

.download-csv-btn:hover {
  border: 1px solid #662d91;
  background-color: transparent;
  color: #662d91;
}

.inputs-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 0;
}
.inputs-section {
  display: flex;
  gap: 2rem;
  margin: 0;
}
.action-btns {
  display: flex;
}

.bold-label label {
  font-weight: bold;
}
/* Responsive adjustments for larger screens */
@media (min-width: 768px) {
  .inputs-btn {
    flex-direction: row; /* Align elements horizontally on larger screens */
    gap: 1rem; /* Space between elements */
  }

  .inputs-section {
    flex-direction: row; /* Align input and select horizontally */
    gap: 1rem; /* Space between input and select */
    width: auto; /* Adjust width to content on larger screens */
  }

  .inputs-section el-input,
  .inputs-section el-select {
    width: 200px; /* Fixed width on larger screens */
  }

  .download-csv-btn {
    margin-left: 1rem; /* Space between select and button */
  }
}

/* Responsive adjustments for screens smaller than 768px */
@media (max-width: 768px) {
  .inputs-section {
    flex-direction: column; /* Stack input and select vertically on smaller screens */
  }

  .inputs-section el-input,
  .inputs-section el-select {
    width: 100%; /* Full width on smaller screens */
  }

  .download-csv-btn {
    width: 100%; /* Full width button on smaller screens */
    margin-top: 1rem; /* Space between input/select and button */
  }
  .pagination {
    flex-direction: row; /* Maintain row layout */
    flex-wrap: wrap; /* Allow wrapping to fit pagination */
    align-items: center; /* Center items vertically */
    font-size: 0.875rem; /* Slightly smaller font size for better fit */
  }

  .pagination .el-pagination {
    width: auto; /* Let pagination take up necessary space */
  }

  .pagination .el-pagination .el-pagination__pager {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center pagination buttons */
  }

  .pagination .el-pagination .el-pagination__pager button {
    margin: 0.2rem; /* Add margin between buttons */
  }
}

/* Responsive adjustments for screens smaller than 468px */
@media (max-width: 468px) {
  .inputs-btn {
    gap: 0.5rem; /* Reduce space between elements */
  }

  .inputs-section el-input,
  .inputs-section el-select {
    width: 100%; /* Full width on extra small screens */
  }

  .download-csv-btn {
    font-size: 0.875rem; /* Smaller font size for better fit */
    padding: 0.5rem; /* Adjust padding for better fit on small screens */
  }
  .pagination {
    font-size: 0.75rem; /* Smaller font size for very small screens */
    padding: 0.5rem; /* Reduce padding around pagination */
  }

  .pagination .el-pagination {
    width: auto; /* Ensure full-width usage */
  }

  .pagination .el-pagination .el-pagination__pager {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center pagination buttons */
  }

  .pagination .el-pagination .el-pagination__pager button {
    margin: 0.1rem; /* Reduce margin between buttons */
    padding: 0.3rem; /* Smaller padding for buttons */
  }
}
</style>
<template>
  <div class="advert-container">
    <div class="home-user">
      <span class="home-icon">
        <span class="material-symbols-outlined" @click="goHome()">
          grid_view
        </span>
      </span>
      <div class="right-arrow-h4">
        <el-button class="right-arrow" :icon="arrowIcon" circle></el-button>
        <h4>Adverts</h4>
      </div>
    </div>
    <h3>Adverts</h3>

    <div class="btns">
      <div>
        <div>
          <el-button @click="addAdvert()" type="primary" class="add-advert-btn"
            >Add Advert</el-button
          >
        </div>
      </div>
      <div>
        <el-button
          @click="toggleVerifiedAds"
          type="primary"
          class="add-advert-btn"
        >
          {{ showVerifiedAds ? "All Adverts" : "Verified Ads" }}
        </el-button>
      </div>
      <div>
        <el-button
          @click="togglePublishedAdverts"
          style="margin-right: 0"
          type="primary"
          class="add-advert-btn"
        >
          {{ showPublishedAds ? "All Adverts" : "Published Ads" }}
        </el-button>
      </div>
    </div>

    <!-- admin table -->
    <div class="base-adverts-table">
      <el-table v-loading="loading" :data="rows" border>
        <el-table-column label="#">
          <template v-slot="{ $index }">
            {{ (currentPage - 1) * pageSize + ($index + 1) }}</template
          >
        </el-table-column>
        <el-table-column label="Client Id">
          <template v-slot="{ row }">{{ row.clientId }}</template>
        </el-table-column>
        <el-table-column label="Title">
          <template v-slot="{ row }">
            <el-tooltip effect="dark" :content="row.title" placement="top">
              <div>{{ truncateTitle(row.title) }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="Description">
          <template v-slot="{ row }">
            <el-tooltip
              effect="dark"
              :content="row.description"
              placement="top"
            >
              <div>{{ truncateDescription(row.description) }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="Created By">
          <template v-slot="{ row }">{{ row.createdBy }}</template>
        </el-table-column>
        <el-table-column label="Created At">
          <template v-slot="{ row }">{{
            row.last_created_at
              ? formatTimestamp(row.last_created_at)
              : getCurrentTime()
          }}</template>
        </el-table-column>
        <el-table-column label="Adverts">
          <template v-slot="{ row }">
            <template v-if="row.picture">
              <a :href="row.picture" target="_blank" class="image-link">
                <img
                  class="advert-img"
                  :src="row.picture"
                  alt="Advert Image"
                  style="max-width: 200px; max-height: 50px"
                />
              </a>
            </template>
            <template v-else>
              <div class="viewed-vid">
                <video
                  class="advert-video"
                  :src="row.video"
                  style="max-width: 200px; max-height: 50px"
                ></video>
              </div>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="Actions">
          <template v-slot="{ row }">
            <div class="action-buttons">
              <el-row class="block-col-2">
                <el-col :span="8">
                  <el-dropdown>
                    <span class="el-dropdown-link">
                      <el-button
                        type="primary"
                        :icon="moreIcon"
                        circle
                      ></el-button>
                    </span>
                    <template #dropdown>
                      <el-dropdown-menu class="custom-dropdown-menu">
                        <el-dropdown-item
                          @click="deleteTheAdvert(row.advertId)"
                          class="dropdown-content"
                        >
                          <span class="material-symbols-outlined">
                            delete
                          </span>
                          <span class="text">Delete Advert</span>
                        </el-dropdown-item>
                        <el-dropdown-item
                          @click="editAdvert(row.advertId)"
                          class="dropdown-content"
                        >
                          <span class="material-symbols-outlined">
                            edit_square
                          </span>
                          <span class="text">Edit Advert</span>
                        </el-dropdown-item>
                        <el-dropdown-item
                          @click="advertDetails(row.advertId)"
                          class="dropdown-content"
                        >
                          <span class="material-symbols-outlined"> info </span>
                          <span class="text">Advert Details</span>
                        </el-dropdown-item>
                        <el-dropdown-item
                          @click="verifyAdvert(row.advertId)"
                          v-if="!row.verified"
                          class="dropdown-content"
                        >
                          <span class="material-symbols-outlined">
                            task_alt
                          </span>
                          <span class="text">Verify Advert</span>
                        </el-dropdown-item>
                        <el-dropdown-item
                          v-else
                          @click="unVerifyAdvert(row.advertId)"
                          class="dropdown-content"
                        >
                          <span class="material-symbols-outlined">
                            cancel
                          </span>
                          <span class="text">Unverify Advert</span>
                        </el-dropdown-item>
                        <el-dropdown-item
                          @click="publishAdvert(row.advertId)"
                          v-if="!row.published"
                          class="dropdown-content"
                        >
                          <span class="material-symbols-outlined">
                            published_with_changes
                          </span>
                          <span class="text">Publish Advert</span>
                        </el-dropdown-item>
                        <el-dropdown-item
                          @click="unPublishAdvert(row.advertId)"
                          v-else
                          class="dropdown-content"
                        >
                          <span class="material-symbols-outlined">
                            unpublished
                          </span>
                          <span class="text">Unpublish Advert</span>
                        </el-dropdown-item>
                        <el-dropdown-item
                          @click="addAdvertPayment(row.advertId, row.clientId)"
                          class="dropdown-content"
                        >
                          <span class="material-symbols-outlined">
                            database
                          </span>
                          <span class="text">Add Manual Payment</span>
                        </el-dropdown-item>
                        <el-dropdown-item
                          @click="
                            addAdvertMobilePayment(row.advertId, row.clientId)
                          "
                          class="dropdown-content"
                        >
                          <span class="material-symbols-outlined"> paid </span>
                          <span class="text">Add Mobile Payment</span>
                        </el-dropdown-item>
                        <el-dropdown-item
                          @click="payFromCashchatapp(row.advertId)"
                          class="dropdown-content"
                        >
                          <span class="material-symbols-outlined">
                            request_quote
                          </span>
                          <span class="text">Pay from Cashchatapp</span>
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </el-col>
              </el-row>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        :current-page="currentPage"
        :small="small"
        :page-size="pageSize"
        :total="total"
        :disabled="disabled"
        :background="background"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, sizes, prev, pager, next, jumper"
      />
    </div>

    <!-- advert detals dialog -->
    <AdvertDetailsDialog
      :is-advert-details-dialog-visible="advertDetailsDialogVisible"
      :advert-id="advertId"
      @close-dialog="closeAdvertDetailsDialog"
    />

    <!--  adding  advert dialog -->
    <AddingAdvertDialog
      :is-add-advert-dialog-visible="addAdvertDialogVisible"
      @close-dialog="closeAddingAdvertDialog"
      @fetch-adverts="fetchAdverts"
    />

    <!-- adding the advert payments dialog -->
    <AddAdvertPaymentDialog
      :is-add-advert-payment-dialog-visible="addAdvertPaymentDialogVisible"
      @close-dialog="closeAddAdvertPaymentDialog"
      :advert-id="advertId"
      :client-id="clientId"
      @fetch-adverts="fetchAdverts"
    />
    <!-- adding  mobile payment dialog -->
    <AddMobilePaymentDialog
      :is-add-advert-payment-dialog-visible="addMobilePaymentDialogVisible"
      @close-dialog="closeCardMobilePaymentDialog"
      :advert-id="advertId"
      :client-id="clientId"
      @fetch-adverts="fetchAdverts"
    />

    <PayFromCashchatDialog
      :is-pay-from-cashchatapp-dialog-visible="payFromCashchatappWalletVisible"
      @close-dialog="closePayFromCashchatappDialog"
      :advert-id="advertId"
      :client-id="clientId"
      @fetch-adverts="fetchAdverts"
    />
    <!-- editing advert dialog -->
    <EditAdvertDialog
      :is-edit-advert-dialog-visible="editAdvertDialogVisible"
      :advert-id="advertId"
      @close-dialog="closeEditingAdvertDialog"
      @fetch-adverts="fetchAdverts"
    />
    <AllDeletedAdvertsDialog
      :is-all-deleted-adverts-dialog-visible="deletedAdvertsDialogVisible"
      @close-dialog="closeDeletedAdvertsDialog"
      :advert-id="advertId"
      @fetch-adverts="fetchAdverts"
    />

    <!-- trash can -->
    <div class="fixed-trashcan-button">
      <span class="material-symbols-outlined" @click="viewAllDeletedAdverts()"
        >delete</span
      >
    </div>
  </div>
</template>
    
    <script lang="ts" setup>
//   imports
import { ref, computed, onMounted } from "vue";
import axios from "axios";
import { AxiosError } from "axios";
import { useAuthStore } from "../../store/authStore";
import Advert from "../../types/Advert";
import Swal from "sweetalert2";
import { format } from "date-fns";
import { useRouter } from "vue-router";
import AdvertDetailsDialog from "../../components/dialogs/adverts/advert-details-dialog.vue";
import AddingAdvertDialog from "../../components/dialogs/adverts/adding-advert-dialog.vue";
import AddAdvertPaymentDialog from "../../components/dialogs/adverts/add-advert-payment-dialog.vue";
import PayFromCashchatDialog from "../../components/dialogs/payments/pay-from-cashchatapp-dialog.vue";
import EditAdvertDialog from "../../components/dialogs/adverts/edit-advert-dialog.vue";
import AddMobilePaymentDialog from "../../components/dialogs/payments/mobile-advert-payment-dialog.vue";
import AllDeletedAdvertsDialog from "../../components/dialogs/trashcan/all-deleted-adverts-dialog.vue";
import { More, ArrowRight } from "@element-plus/icons-vue";

// token
const token = computed(() => {
  return useAuthStore().token;
});

// refs
const advertDetailsDialogVisible = ref(false);
const addAdvertDialogVisible = ref(false);
const addAdvertPaymentDialogVisible = ref(false);
const addMobilePaymentDialogVisible = ref(false);
const payFromCashchatappWalletVisible = ref(false);
const editAdvertDialogVisible = ref(false);
const deletedAdvertsDialogVisible = ref(false);

const currentPage = ref(1);
const pageSize = ref(10);
const small = ref(false);
const background = ref(false);
const disabled = ref(false);
const total = ref(0);
// const totalPages = ref(0);
// const page = ref(1);

const advertId = ref("");
const clientId = ref("");

const showVerifiedAds = ref(false);
const showPublishedAds = ref(false);

const loading = ref(false);
const rows = ref<Advert[]>([]);

const arrowIcon = ArrowRight;
const moreIcon = More;

const router = useRouter();

// redirecting to home
const goHome = () => {
  router.push("/dashboard");
};

// gettng the current time
const getCurrentTime = () => {
  const currentTime = new Date();
  const formattedTime = `${currentTime.toLocaleDateString()} ${currentTime.toLocaleTimeString()}`;
  return formattedTime;
};

// opening advert details dialog
const advertDetails = (adId: string) => {
  advertId.value = adId;
  advertDetailsDialogVisible.value = true;
};
// opening add advert dialog
const addAdvert = () => {
  addAdvertDialogVisible.value = true;
};
// opening add advert payment dialog
const addAdvertPayment = (adId: string, clntId: string) => {
  advertId.value = adId;
  clientId.value = clntId;
  addAdvertPaymentDialogVisible.value = true;
};
// opening add advert mobile payment dialog
const addAdvertMobilePayment = (adId: string, clntId: string) => {
  advertId.value = adId;
  clientId.value = clntId;
  addMobilePaymentDialogVisible.value = true;
};
const payFromCashchatapp = (adId: string) => {
  advertId.value = adId;
  payFromCashchatappWalletVisible.value = true;
};
// opening edit advert dialog
const editAdvert = (adId: string) => {
  advertId.value = adId;
  editAdvertDialogVisible.value = true;
};

const viewAllDeletedAdverts = () => {
  deletedAdvertsDialogVisible.value = true;
};
// closing advert details dialog
const closeAdvertDetailsDialog = () => {
  advertDetailsDialogVisible.value = false;
};
const closeAddingAdvertDialog = () => {
  addAdvertDialogVisible.value = false;
};
const closeAddAdvertPaymentDialog = () => {
  addAdvertPaymentDialogVisible.value = false;
};
const closeCardMobilePaymentDialog = () => {
  addMobilePaymentDialogVisible.value = false;
};
const closePayFromCashchatappDialog = () => {
  payFromCashchatappWalletVisible.value = false;
};
const closeEditingAdvertDialog = () => {
  editAdvertDialogVisible.value = false;
};
const closeDeletedAdvertsDialog = () => {
  deletedAdvertsDialogVisible.value = false;
};

// handling the current change for the pagination
const handleCurrentChange = async (val: number) => {
  currentPage.value = val;
  await fetchAdverts();
};

// handling the size
const handleSizeChange = async (val: number) => {
  pageSize.value = val;
  currentPage.value = 1;
  await fetchAdverts();
};

// GETTING ALL ADVERTS FROM THE DB
const fetchAdverts = async () => {
  try {
    loading.value = true;
    rows.value = [];
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/adverts",
      {
        method: "get_all_adverts",
        page: currentPage.value,
      },
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );
    if (response.data.success) {
      // rows.value = response.data.result;
      total.value = parseInt(response.data.total_adverts);

      // Mapping the result array to popuate data in the rows array
      rows.value = response.data.result.map((advert: Advert) => ({
        last_created_at: advert.last_created_at,
        userId: advert.user_id,
        username: advert.username,
        advertId: advert.id,
        title: advert.title,
        description: advert.description,
        thumbnail: advert.thumbnail,
        video: advert.video,
        picture: advert.picture,
        verified: advert.verified,
        published: advert.published,
        createdBy: advert.created_by,
        clientId: advert.client_id,
      }));
    } else {
      // Handle error cases
      const errorMessage = response.data.errors || "Failed to fetch adverts.";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#3085d6",
    });
  } finally {
    loading.value = false;
  }
};
// onmounting fetch adverts, all adverts will be displayed
onMounted(() => {
  fetchAdverts();
});

// trancating the description
const truncateDescription = (description: string) => {
  const words = description.split(" ");
  if (words.length > 3) {
    return words.slice(0, 3).join(" ") + "...";
  } else {
    return description;
  }
};
// trancating the title
const truncateTitle = (title: string) => {
  const words = title.split(" ");
  if (words.length > 3) {
    return words.slice(0, 3).join(" ") + "...";
  } else {
    return title;
  }
};

// deleting the advert
const deleteTheAdvert = async (advertId: string | number) => {
  try {
    // Show the confirmation dialog to delete the advert
    const result = await Swal.fire({
      title: "Dangerous",
      text: "Are you sure you want to delete this advert?",
      icon: "warning",
      confirmButtonColor: "#662d91",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      const response = await axios.post(
        // additional to global baseUrl endpoint
        "/adverts",
        {
          method: "delete_advert",
          advert_id: advertId,
        },
        {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        }
      );

      if (response.data.success) {
        fetchAdverts();
        await Swal.fire({
          title: "Success",
          text: "You have deleted the advert successfully.",
          icon: "success",
          confirmButtonColor: "#3085d6",
        });
      } else {
        // Handle other error cases
        const errorMessage =
          response.data.errors || "Failed to delete the advert.";
        return Swal.fire({
          title: "Error",
          text: errorMessage,
          icon: "error",
          confirmButtonColor: "#d33",
        });
      }
    }
  } catch (error: unknown) {
    // Check if the error is an instance of AxiosError
    if (isAxiosError(error)) {
      // Perform a type assertion on error.response.data since it's typed as 'unknown'
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === "object"
      ) {
        const responseData = error.response.data as { errors?: string };
        // Checking if the errors property exists and matches the expected string
        if (
          responseData.errors ===
          "Cannot delete the advert as it is already verified and published"
        ) {
          await Swal.fire({
            title: "Error",
            text: responseData.errors,
            icon: "error",
            confirmButtonColor: "#d33",
          });
        } else {
          // Handling other error cases
          await Swal.fire({
            title: "Error",
            text: error.message,
            icon: "error",
            confirmButtonColor: "#d33",
          });
        }
      }
    } else {
      // General error handling for other types of errors
      await Swal.fire({
        title: "Error",
        text: "An unexpected error occurred. Please try again later.",
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  }
};

// Helper function to check if error is an AxiosError
function isAxiosError(error: unknown): error is AxiosError {
  return (error as AxiosError).isAxiosError === true;
}

// verifying the advert
const verifyAdvert = async (advertId: string | number) => {
  try {
    loading.value = true;
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/adverts",
      {
        method: "approve_advert",
        advert_id: advertId,
      },
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );
    if (
      response.data.success &&
      response.data.message === "Advert verified successfully."
    ) {
      fetchAdverts();
      return Swal.fire({
        title: "Successful",
        text: "Advert verified successfully",
        icon: "success",
        confirmButtonColor: "#3085d6",
      });
    } else {
      // Handle other error cases
      const errorMessage = response.data.errors || "Failed to verify advert.";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#3085d6",
    });
  } finally {
    loading.value = false;
  }
};

// Unverifying the advert
const unVerifyAdvert = async (advertId: string | number) => {
  try {
    loading.value = true;
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/adverts",
      {
        method: "unverify_advert",
        advert_id: advertId,
      },
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );
    if (
      response.data.success &&
      response.data.message === "Advert Unverified Successfully"
    ) {
      fetchAdverts();
      return Swal.fire({
        title: "Successful",
        text: "Advert Unverified Successfully",
        icon: "success",
        confirmButtonColor: "#3085d6",
      });
    } else {
      // Handle other error cases
      const errorMessage =
        response.data.errors || "Failed to unverify the advert.";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#3085d6",
    });
  } finally {
    loading.value = false;
  }
};

// GETTING VERIFIED ADVERTDS

const toggleVerifiedAds = async () => {
  showVerifiedAds.value = !showVerifiedAds.value;
  if (showVerifiedAds.value) {
    await fetchVerifiedAdverts();
  } else {
    await fetchAdverts();
  }
};

// changing the format of the time using fns
const formatTimestamp = (timestamp: string) => {
  if (!timestamp) return ""; // Handling case where timestamp is null or undefined
  return format(new Date(timestamp), "yyyy-MM-dd HH:mm:ss");
};

// getting verified adverts
const fetchVerifiedAdverts = async () => {
  try {
    loading.value = true;
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/adverts",
      {
        method: "get_verified_adverts_web",
        page: currentPage.value,
      },
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );
    if (response.data.success) {
      // total.value = parseInt(response.data.total_adverts);

      // Mapping the result array to populate data in the rows array
      rows.value = response.data.data.map((advert: Advert) => ({
        last_created_at: advert.last_created_at,
        userId: advert.user_id,
        username: advert.username,
        advertId: advert.id,
        title: advert.title,
        description: advert.description,
        thumbnail: advert.thumbnail,
        video: advert.video,
        picture: advert.picture,
        verified: advert.verified,
        published: advert.published,
        createdBy: advert.username,
        clientId: advert.user_id,
      }));
    } else {
      // Handle other error cases
      const errorMessage =
        response.data.errors || "Failed to fetch verified adverts.";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#3085d6",
    });
  } finally {
    loading.value = false;
  }
};

// fetching all published adverts
const publishAdvert = async (advertId: string | number) => {
  try {
    loading.value = true;
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/adverts",
      {
        method: "publish_advert",
        advert_id: advertId,
      },
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );
    if (
      response.data.success &&
      response.data.message === "advert published successfully."
    ) {
      fetchAdverts();
      return Swal.fire({
        title: "Successful",
        text: "Advert published successfully.",
        icon: "success",
        confirmButtonColor: "#3085d6",
      });
    } else {
      // Handle other error cases
      const errorMessage = response.data.errors || "Failed to publish advert.";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#3085d6",
    });
  } finally {
    loading.value = false;
  }
};

// Unpublishing the adverts
const unPublishAdvert = async (advertId: string | number) => {
  try {
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/adverts",
      {
        method: "unpublish_advert",
        advert_id: advertId,
        publishedStatus: false,
      },
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );
    if (
      response.data.success &&
      response.data.message === "advert unpublished successfully."
    ) {
      fetchAdverts();
      return Swal.fire({
        title: "Successful",
        text: "Advert Unpublished Successfully",
        icon: "success",
        confirmButtonColor: "#3085d6",
      });
    } else {
      // Handle other error cases
      const errorMessage =
        response.data.errors || "Failed to Unpublished advert.";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#3085d6",
    });
  }
};

// toggling published adverts
const togglePublishedAdverts = async () => {
  showPublishedAds.value = !showPublishedAds.value;
  if (showPublishedAds.value) {
    await fetchPublishedAdverts();
  } else {
    await fetchAdverts();
  }
};

// getting verified adverts
const fetchPublishedAdverts = async () => {
  try {
    loading.value = true;
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/adverts",
      {
        method: "get_published_adverts",
        page: currentPage.value,
      },
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );
    if (response.data.success) {
      // total.value = parseInt(response.data.total_adverts);

      // Mapping the result array to populate data in the rows array
      rows.value = response.data.data.map((advert: Advert) => ({
        last_created_at: advert.last_created_at,
        userId: advert.user_id,
        username: advert.username,
        advertId: advert.id,
        title: advert.title,
        description: advert.description,
        thumbnail: advert.thumbnail,
        video: advert.video,
        picture: advert.picture,
        verified: advert.verified,
        published: advert.published,
        createdBy: advert.username,
        clientId: advert.user_id,
      }));
    } else {
      // Handle other error cases
      const errorMessage =
        response.data.errors || "Failed to fetch published advert.";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Successful",
      text: error.message,
      icon: "success",
      confirmButtonColor: "#3085d6",
    });
  } finally {
    loading.value = false;
  }
};
</script>
    
    <style scoped>
.advert-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 95%;
  background-color: whitesmoke;
  overflow: hidden;
  margin: 0 auto;
}
.home-user {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0;
}
.right-arrow {
  background-color: transparent;
  border: none;
  color: gray;
}
h4 {
  font-size: 1rem;
  font-weight: 300;
  margin-top: 0.5rem;
}
.right-arrow-h4 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.material-symbols-outlined:hover {
  cursor: pointer;
}
.home-icon .material-symbols-outlined {
  font-size: 1.2rem;
  margin-left: 0;
}

h3 {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 2rem 0 3rem 0;
  color: #662d91;
}
.btns {
  display: flex;
  justify-content: flex-end;
  margin-right: 0;
}
.btns button {
  padding: 17px 20px;
  margin: 5px;
  font-size: 0.9rem;
  /* border: none; */
}

.action-buttons {
  display: flex;
}
.add-advert-btn {
  background: #662d91;
  padding: 15px 25px;
}
.add-advert-btn:hover {
  color: #662d91;
  border: 1px solid #662d91;
  background-color: #f0e9f5;
}

.base-adverts-table {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}
.el-table {
  width: 100%;
  overflow-x: auto;
}

input,
textarea {
  padding: 10px;
}

/* teble starts here */
table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  border: 1px solid #eae9e9;
  padding: 8px;
  /* padding: 1px; */
  text-align: left;
  font-size: 0.9rem;
}
th {
  padding: 5px;
  background-color: #f2f2f2;
}
tr:hover {
  background-color: #d9edf4;
}

th {
  background-color: #f2f2f2;
}

.advert-video,
.advert-img {
  width: 100%;
  /* height: 100%; */
  height: auto;
  object-fit: cover;
}
.pagination {
  margin: 1rem auto;
  overflow-x: auto;
}
.image-link {
  text-decoration: none;
}

.material-symbols-outlined {
  font-size: 2rem;
  padding-right: 0.6rem;
}

strong {
  font-weight: 700;
}

.dropdown-content .material-symbols-outlined {
  font-size: 24px;
  margin-right: 0.5rem;
}

.dropdown-content .text {
  font-size: 18px;
  padding: 0.5rem;
}
button {
  padding: 8px 12px;
  background-color: #662d91;
  color: white;
  margin: 1rem;
  cursor: pointer;
  border-radius: 5px;
  border: none;
}

/* trashcan */
.fixed-trashcan-button {
  position: fixed;
  bottom: 10px;
  right: 10px;
  right: 5px;
  color: red;
  padding: 10px;
  cursor: pointer;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fixed-trashcan-button .material-symbols-outlined {
  font-size: 50px;
}
/* .table-wrapper {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
} */

/* Media Queries for Responsiveness */
@media (max-width: 480px) {
  .btns button {
    padding: 8px 12px;
    font-size: 0.7rem;
  }

  h3 {
    font-size: 1rem;
  }

  table {
    font-size: 0.7rem;
  }

  th,
  td {
    padding: 6px;
  }

  .fixed-trashcan-button {
    bottom: 5px;
    right: 5px;
    padding: 5px;
  }

  .fixed-trashcan-button .material-symbols-outlined {
    font-size: 2rem;
  }

  th,
  td {
    display: block;
    width: 100%;
    box-sizing: border-box;
    text-align: right;
  }

  th {
    background: #f2f2f2;
    text-align: center;
  }

  td {
    position: relative;
    padding-left: 50%;
    text-align: left;
  }

  td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 45%;
    padding-left: 10px;
    white-space: nowrap;
    font-weight: bold;
    text-align: left;
  }
  .pagination {
    font-size: 0.75rem; /* Smaller font size for very small screens */
    padding: 0.5rem; /* Reduce padding around pagination */
  }

  .pagination .el-pagination {
    width: auto; /* Ensure full-width usage */
  }

  .pagination .el-pagination .el-pagination__pager {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center pagination buttons */
  }

  .pagination .el-pagination .el-pagination__pager button {
    margin: 0.1rem; /* Reduce margin between buttons */
    padding: 0.3rem; /* Smaller padding for buttons */
  }
}

@media (max-width: 768px) {
  .btns button {
    padding: 10px 14px;
    font-size: 0.8rem;
  }

  h3 {
    font-size: 1.25rem;
  }

  table {
    font-size: 0.8rem;
  }

  .material-symbols-outlined {
    font-size: 2rem;
  }

  .fixed-trashcan-button {
    bottom: 5px;
    right: 5px;
    padding: 10px;
  }

  .fixed-trashcan-button .material-symbols-outlined {
    font-size: 3rem;
  }
  .pagination {
    flex-direction: row; /* Maintain row layout */
    flex-wrap: wrap; /* Allow wrapping to fit pagination */
    align-items: center; /* Center items vertically */
    font-size: 0.875rem; /* Slightly smaller font size for better fit */
  }

  .pagination .el-pagination {
    width: auto; /* Let pagination take up necessary space */
  }

  .pagination .el-pagination .el-pagination__pager {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center pagination buttons */
  }

  .pagination .el-pagination .el-pagination__pager button {
    margin: 0.2rem; /* Add margin between buttons */
  }
}
</style>
    
<template>
  <div>
    <el-dialog
      :model-value="isAddAdvertDialogVisible"
      title="Add Advert"
      :width="dialogWidth"
      :style="{ paddingBottom: '20px' }"
      @close="closeDialog"
    >
      <el-form
        class="advert-add-modal-content"
        :model="form"
        label-width="80px"
      >
        <el-form-item label="Title">
          <el-input v-model="form.title.value" />
        </el-form-item>
        <el-form-item label="Clients Id">
          <el-input v-model="form.clientsId.value" />
        </el-form-item>
        <el-form-item label="Description">
          <el-input type="textarea" v-model="form.description.value" />
        </el-form-item>
        <div v-loading="loading" class="file-input">
          <!-- Showing the file preview if a file has been selected -->
          <template v-if="pictureAd || videoAd">
            <div
              v-if="pictureAd"
              class="position-relative category-photo"
              style="background: #fdfdfd"
            >
              <img
                class="edited-img"
                :src="fileUrl"
                style="object-fit: cover"
              />
              <div
                class="remove-img w-100 d-flex align-items-center justify-content-center"
                style="height: 100%"
              >
                <el-button
                  class="remove-img-btn"
                  style="
                    font-size: 1.5rem;
                    color: #fff;
                    background-color: rgba(0, 0, 0, 0.45);
                  "
                  @click="handleImageEdit"
                >
                  Change image
                </el-button>
              </div>
            </div>
            <div
              v-else-if="videoAd"
              class="position-relative category-video"
              style="background: #fdfdfd"
            >
              <iframe
                class="edited-vid"
                :src="fileUrl"
                frameborder="0"
                style="object-fit: cover"
              ></iframe>
              <div
                class="remove-vid w-100 d-flex align-items-center justify-content-center"
                style="height: 100%"
              >
                <el-button
                  class="remove-vid-btn"
                  style="
                    font-size: 1.5rem;
                    color: #fff;
                    background-color: rgba(0, 0, 0, 0.45);
                  "
                  @click="handleVideoEdit"
                >
                  Change vid
                </el-button>
              </div>
            </div>
          </template>
          <!-- Show the file selection input if no file has been selected -->
          <template v-else>
            <label for="media" class="choose-file"
              >Choose Video / Picture</label
            >
            <label class="add-img">
              <div class="image-container">
                <input
                  type="file"
                  class="d-none"
                  accept="image/, video/"
                  @change="handleFileAdd"
                  style="display: none"
                />
                <span class="el-icon-plus"></span>
                <span class="material-symbols-outlined"> add </span>
              </div>
            </label>
          </template>
        </div>
        <div class="button-container">
          <el-button type="submit" class="save-button" @click="saveAdvert"
            >Save</el-button
          >
        </div>
      </el-form>
    </el-dialog>

    <!-- user verification dialog -->
  </div>
</template>

<script lang="ts" setup>
import {
  ref,
  defineProps,
  defineEmits,
  computed,
  onMounted,
  onUnmounted,
} from "vue";
import "element-plus/theme-chalk/el-icon.css";
import axios from "axios";
import Swal from "sweetalert2";
import { useAuthStore } from "../../../store/authStore";

// defining token
const token = computed(() => {
  return useAuthStore().token;
});

const props = defineProps<{
  isAddAdvertDialogVisible: boolean;
}>();

// emitting the custom event "close-dialog" for closing the dialog
const emit = defineEmits<{
  (e: "close-dialog"): void;
  (e: "fetch-adverts"): void;
}>();

const closeDialog = () => {
  emit("close-dialog");
};
// defining form
const form = {
  title: ref<string>(""),
  clientsId: ref<string>(""),
  description: ref<string>(""),
};

// refs
const isVideo = ref<boolean>(false);
const isPicture = ref<boolean>(false);
const videoAd = ref("");
const pictureAd = ref("");
const editedVideo = ref("");
const editedImage = ref("");
const fileUrl = ref("");
const isSaving = ref(false);
const loading = ref(false);

const username = ref("");

const windowWidth = ref(window.innerWidth);

// Computed property for responsive dialog width
const dialogWidth = computed(() => {
  const width = windowWidth.value <= 480 ? "90%" : "500px";
  const maxWidth = "500px"; // Maximum width for large screens
  return `min(${width}, ${maxWidth})`;
});

function handleResize() {
  windowWidth.value = window.innerWidth; // Update the reactive windowWidth
}
// Watch for window resize events
onMounted(() => {
  window.addEventListener("resize", handleResize);
  handleResize();
});

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});

// this is the warning  swal
const warningSwal = async () => {
  // Show error message
  const result = await Swal.fire({
    title: "Warning",
    text: "Invalid file type. Please select an image or a video.",
    icon: "warning",
    confirmButtonColor: "#3085d6",
  });

  if (result.isConfirmed) {
    return "";
  }
};
// capitalising the username
const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

// saving the advert after confirming
const saveAdvert = async () => {
  try {
    // Calling user verification
    const formData = new FormData();
    formData.append("method", "user_verification");
    formData.append("user_id", form.clientsId.value);
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "users",
      formData,
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response.data.success) {
      // Setting username and country
      username.value = response.data.info.username;
      closeDialog();

      // Displaying confirmation dialog before saving the advert
      const result = await Swal.fire({
        title: "Confirm User",
        html:
          "You are creating an advert for:<br><strong>" +
          `${capitalize(username.value)}</strong><br>` +
          "Are you sure you want to proceed?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        allowEscapeKey: false,
        allowOutsideClick: false,
        showLoaderOnConfirm: true,
      });

      if (result.isConfirmed) {
        Swal.showLoading();
        // Proceed with saving the advert
        loading.value = true;
        isSaving.value = true;
        const formData = new FormData();
        formData.append("method", "add_advert");
        formData.append("user_id", form.clientsId.value);
        formData.append("title", form.title.value);
        formData.append("description", form.description.value);
        if (isPicture.value) {
          formData.append("picture", pictureAd.value);
          formData.append("is_picture", isPicture.value.toString());
        } else if (isVideo.value) {
          formData.append("video", videoAd.value);
          formData.append("is_video", isVideo.value.toString());
        }

        const response = await axios.post(
          // additional to global baseUrl endpoint
          "/adverts",
          formData,
          {
            headers: {
              Authorization: `Bearer ${token.value}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.data.success) {
          console.log(response.data);
          closeDialog();
          emit("fetch-adverts");
          isSaving.value = false;

          await Swal.fire({
            title: "Successful",
            text: "You have added the advert successfully",
            icon: "success",
            confirmButtonColor: "#3085d6",
          });

          // Clear input fields after successful advert creation
          form.title.value = "";
          form.description.value = "";
          form.clientsId.value = "";
          pictureAd.value = "";
          videoAd.value = "";
        }
      } else {
        // Handle other error cases
        const errorMessage =
          response.data.errors || "Failed to show adverts details";
        return Swal.fire({
          title: "Error",
          text: errorMessage,
          icon: "error",
          confirmButtonColor: "#d33",
        });
      }
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#d33",
    });
  } finally {
    loading.value = false;
  }
};

// handling the image editing
const handleImageEdit = () => {
  editedImage.value = "";
  pictureAd.value = "";
};

// handling the video editing
const handleVideoEdit = () => {
  editedVideo.value = "";
  pictureAd.value = "";
};

// handling the file upload
const handleFileAdd = async (event: any) => {
  event.preventDefault();
  const file = event.target.files?.[0];

  if (!file) {
    console.log("No file selected");
    return;
  }

  try {
    const fileType = file.type;
    if (!fileType.startsWith("image/") && !fileType.startsWith("video/")) {
      console.log("File type not allowed");
      warningSwal();
      return;
    }

    fileUrl.value = URL.createObjectURL(file);

    if (fileType.startsWith("image/")) {
      pictureAd.value = file;
      isPicture.value = true;
      isVideo.value = false;
    } else {
      // Create a video element to get video duration
      const video = document.createElement("video");
      video.src = URL.createObjectURL(file);

      // Wait for metadata to load
      await new Promise<void>((resolve) => {
        video.onloadedmetadata = () => resolve();
      });

      // Check video duration
      const videoDuration = Math.round(video.duration);

      // Check if video duration is more than 30 seconds
      if (videoDuration > 120) {
        closeDialog();

        const result = await Swal.fire({
          title: "Video too long",
          text: `The video duration (${videoDuration} seconds) exceeds 120 seconds. Please upload a video that is 120 seconds or less.`,
          icon: "warning",
          confirmButtonText: "OK",
        });

        if (result.isConfirmed) {
          return "";
        }

        // Clear the file input
        event.target.value = "";
        return;
      } else {
        // If video duration is 60 seconds or less, proceed as usual
        videoAd.value = file;
        isVideo.value = true;
        isPicture.value = false;
      }
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#d33",
    });
  }
};
</script>

<style scoped>
.modal-content,
.advert-add-modal-content {
  background-color: #fefefe;
  margin: 0.5rem auto;
  padding: 20px;
  border-radius: 10px;

  height: auto;
}
.remove-img,
.remove-vid {
  display: none;
}
.remove-img-btn,
.remove-vid-btn {
  display: none;
}
.category-photo:hover .remove-img-btn {
  display: block;
}
.button-container {
  display: flex;
  justify-content: center;
}
.save-button {
  background-color: #662d91;
  color: #fff;
  border: none;
  padding: 20px 3rem;
  cursor: pointer;
  display: block;
  margin: 1rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

/* CSS for button hover effect */
.save-button:hover {
  background-color: transparent;
  border: 1px solid #662d91;
  color: #662d91;
}
.choose-file {
  margin: 0.5rem;
}
.material-symbols-outlined:hover {
  cursor: pointer;
}

.material-symbols-outlined {
  font-size: 2rem;
  /* padding-right: 0.6rem; */
}

.material-symbols-outlined:hover {
  cursor: pointer;
}
.material-symbols-outlined {
  font-size: 2rem;
  /* padding-right: 0.6rem; */
}
.category-photo:hover .remove-img {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgb(0, 0, 0, 0.45);
}
.category-video:hover .remove-vid {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgb(0, 0, 0, 0.45);
}
.add-img {
  border: 1px dashed rgb(189, 187, 187);
  border-radius: 5px;
  height: 80%;
  font-size: 3em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

input[type="file"] {
  display: none;
}
.category-photo:hover .remove-img-btn {
  display: block;
}
.category-video:hover .remove-vid-btn {
  display: block;
}

.category-photo,
.category-video {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  margin: 2rem auto;
}

.edited-img,
.edited-vid {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .el-dialog {
    max-height: 50vh !important;
    overflow-y: auto;
  }

  .advert-add-modal-content .el-form-item .el-form-item__label {
    margin-bottom: 5px;
  }
}
</style>
<template>
  <div class="product-container">
    <div class="home-user">
      <span class="home-icon">
        <span class="material-symbols-outlined" @click="goHome()">
          grid_view
        </span>
      </span>
      <div class="right-arrow-h4">
        <el-button class="right-arrow" :icon="arrowIcon" circle></el-button>
        <h4>Products</h4>
      </div>
    </div>
    <h3>Products</h3>

    <div class="btns">
      <div>
        <div>
          <el-button @click="addProduct" class="add-product-btn"
            >Add Product</el-button
          >
        </div>
      </div>
      <!--           @click="toggleVerifiedProducts"             @click="togglePublishedProducts"-->
      <div>
        <el-button type="primary" class="add-product-btn">
          {{ showVerifiedPdts ? "All Products" : "Verified Products" }}
        </el-button>
      </div>
      <div>
        <el-button
          style="margin-right: 0"
          type="primary"
          class="add-product-btn"
        >
          {{ showPublishedPdts ? "All Products" : "Published Products" }}
        </el-button>
      </div>
    </div>

    <!-- spinning loader -->

    <div class="my-products-table">
      <el-table v-loading="loading" :data="rows" border style="width: 100%">
        <el-table-column label="#" width="50">
          <template v-slot="{ $index }">
            {{ (currentPage - 1) * pageSize + ($index + 1) }}</template
          >
        </el-table-column>
        <el-table-column label="Title">
          <template v-slot="{ row }">{{ row.title }}</template>
        </el-table-column>
        <el-table-column label="Prdt Id">
          <template v-slot="{ row }">{{ row.productId }}</template>
        </el-table-column>
        <el-table-column label="Price">
          <template v-slot="{ row }">{{ row.price }}</template>
        </el-table-column>
        <el-table-column label="Phone">
          <template v-slot="{ row }">{{ row.phone }}</template>
        </el-table-column>
        <el-table-column label="Description">
          <template v-slot="{ row }">
            <el-tooltip :content="row.description" placement="top">
              <div>{{ truncateDescription(row.description) }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="Location">
          <template v-slot="{ row }">{{ row.location }}</template>
        </el-table-column>
        <el-table-column label="Created At">
          <template v-slot="{ row }">{{
            formatTimestamp(row.created_at)
          }}</template>
        </el-table-column>
        <el-table-column label="View Count">
          <template v-slot="{ row }">{{ row.views_count }}</template>
        </el-table-column>
        <el-table-column label="Products">
          <template v-slot="{ row }">
            <template v-if="row.picture">
              <a :href="row.picture" target="_blank" class="image-link">
                <img
                  class="pdt-img"
                  :src="row.picture"
                  alt="Product Image"
                  style="max-width: 200px; max-height: 50px"
                />
              </a>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="Actions" width="100">
          <template v-slot="{ row }">
            <div class="action-buttons">
              <div class="action-btns-2">
                <el-row class="block-col-2">
                  <el-col :span="8">
                    <el-dropdown>
                      <span class="el-dropdown-link">
                        <el-button
                          type="primary"
                          :icon="moreIcon"
                          circle
                        ></el-button>
                      </span>

                      <template #dropdown>
                        <el-dropdown-menu class="custom-dropdown-menu">
                          <el-dropdown-item
                            @click="deleteProduct(row.productId)"
                            class="dropdown-content"
                          >
                            <span class="material-symbols-outlined">
                              delete
                            </span>
                            <span class="text">Delete Product</span>
                          </el-dropdown-item>
                          <el-dropdown-item
                            @click="editProductDetails(row.productId)"
                            class="dropdown-content"
                          >
                            <span class="material-symbols-outlined">
                              edit_square
                            </span>
                            <span class="text">Edit Product</span>
                          </el-dropdown-item>
                          <el-dropdown-item
                            @click="productDetails(row.productId)"
                            class="dropdown-content"
                          >
                            <span class="material-symbols-outlined">
                              info
                            </span>
                            <span class="text">Product Details</span>
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        :current-page="currentPage"
        :small="small"
        :page-size="pageSize"
        :total="total"
        :disabled="disabled"
        :background="background"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, sizes, prev, pager, next, jumper"
      />
    </div>

    <AddProductDialog
      :is-add-product-dialog-visible="addProductsDialogVisible"
      @close-dialog="closeAddProductDialog"
      @fetch-all-products="fetchAllProducts"
    />

    <!-- product details -->
    <ProductDetailsDialog
      :is-product-deatils-dialog-visible="productDetailsDialogVisible"
      :product-id="productId"
      @close-dialog="closeProductDetailsDialog"
      @fetch-all-proucts="fetchAllProducts"
    />

    <!-- editing the product -->
    <EditProductDialog
      :is-edit-product-dialog-visible="editProductDetailsDialogVisible"
      :product-id="productId"
      @fetch-all-proucts="fetchAllProducts"
      @close-dialog="closeEditProductDetailsDialog"
    />
  </div>
</template>
  
  <script lang="ts" setup>
import { ref, computed, onMounted } from "vue";
import axios from "axios";
import { useAuthStore } from "../../store/authStore";
import Product from "../../types/Product";
import Swal from "sweetalert2";
import { useRouter } from "vue-router";
import { ArrowRight } from "@element-plus/icons-vue";
import { More } from "@element-plus/icons-vue";
import ProductDetailsDialog from "../../components/dialogs/products/product-details-dialog.vue";
import EditProductDialog from "../../components/dialogs/products/edit-product-dialog.vue";
import AddProductDialog from "../../components/dialogs/products/add-product-dialog.vue";
import { format } from "date-fns";

// token
const token = computed(() => {
  return useAuthStore().token;
});

const productDetailsDialogVisible = ref(false);
const addProductsDialogVisible = ref(false);

const productDetails = (pdtId: string) => {
  productId.value = pdtId;
  productDetailsDialogVisible.value = true;
};

const closeProductDetailsDialog = () => {
  productDetailsDialogVisible.value = false;
};

const editProductDetailsDialogVisible = ref(false);

const editProductDetails = (pdtId: string) => {
  productId.value = pdtId;
  editProductDetailsDialogVisible.value = true;
};
const addProduct = () => {
  addProductsDialogVisible.value = true;
};

const closeAddProductDialog = () => {
  addProductsDialogVisible.value = false;
};

const closeEditProductDetailsDialog = () => {
  editProductDetailsDialogVisible.value = false;
};

const currentPage = ref(1);
const pageSize = ref(10);
const small = ref(false);
const background = ref(false);
const disabled = ref(false);
const total = ref(0);
const totalPages = ref(0);
const page = ref(1);

const loading = ref(false);

const router = useRouter();

const goHome = () => {
  router.push("/dashboard");
};

// closing edited dialogue

const showVerifiedPdts = ref(false);
const showPublishedPdts = ref(false);
const productId = ref("");

const arrowIcon = ArrowRight;
// const detailsIcon = View;
const moreIcon = More;

const rows = ref<Product[]>([]);

const formatTimestamp = (timestamp: string) => {
  if (!timestamp) return ""; // Handling case where timestamp is null or undefined
  return format(new Date(timestamp), "yyyy-MM-dd");
};

const handleCurrentChange = async (val: number) => {
  currentPage.value = val;
  await fetchAllProducts();
};

const handleSizeChange = async (val: number) => {
  pageSize.value = val;
  currentPage.value = 1;
  await fetchAllProducts();
};

// fetching all the products method
const fetchAllProducts = async () => {
  try {
    loading.value = true;
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/products",
      {
        method: "my_products",
        page: currentPage.value,
      },
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );
    if (response.data.success) {
      // Assigning total number of products
      total.value = parseInt(response.data.total_products);

      // Flattening the result array and map it to the desired format
      rows.value = response.data.result.products.flatMap(
        (product: Product) => ({
          id: product.id,
          created_at: product.created_at,
          userId: product.user_id,
          productId: product.id,
          title: product.title,
          description: product.description,
          picture: product.picture,
          location: product.location,
          price: product.price,
          phone: product.phone_number,
          views_count: product.views_count,
        })
      );
    } else {
      // Handle other error cases
      const errorMessage = response.data.errors || "Failed to fetch products";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#3085d6",
    });
  } finally {
    loading.value = false;
  }
};

// deleting the product
const deleteProduct = async (productId: string | number) => {
  try {
    const result = await Swal.fire({
      title: "Dangerous",
      text: "Are you sure you want to delete this product? Content will be deleted permanently",
      icon: "warning",
      confirmButtonColor: "#3085d6",
      showCancelButton: true,
    });
    if (result.isConfirmed) {
      const response = await axios.post(
        // additional to global baseUrl endpoint
        "/products",
        {
          method: "delete_product",
          product_id: productId,
        },
        {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        }
      );

      if (response.data.success) {
        fetchAllProducts();
        return Swal.fire({
          title: "Successful",
          text: "You have deleted this product successfully",
          icon: "success",
          confirmButtonColor: "#3085d6",
        });
      } else {
        // Handle other error cases
        const errorMessage = response.data.errors || "Failed to delete product";
        return Swal.fire({
          title: "Error",
          text: errorMessage,
          icon: "error",
          confirmButtonColor: "#d33",
        });
      }
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#3085d6",
    });
  }
};

// trancating the description

const truncateDescription = (description: string) => {
  const words = description.split(" ");
  if (words.length > 3) {
    return words.slice(0, 2).join(" ") + "...";
  } else {
    return description;
  }
};

// GETTING VERIFIED products

// const toggleVerifiedProducts = async () => {
//   showVerifiedPdts.value = !showVerifiedPdts.value;
//   if (showVerifiedPdts.value) {
//     await fetchVerifiedProducts();
//   } else {
//     await fetchAllProducts();
//   }
// };

// getting published pdts
// const togglePublishedProducts = async () => {
//   showPublishedPdts.value = !showPublishedPdts.value;
//   if (showPublishedPdts.value) {
//     await fetchPublishedProducts();
//   } else {
//     await fetchAllProducts();
//   }
// };

// Define a computed property to dynamically filter products based on showVerifiedPdts and publishedPrdts
// const filteredRows = computed(() => {
//   const startIndex = (currentPage.value - 1) * pageSize.value;
//   const endIndex = startIndex + pageSize.value;
//   if (showVerifiedPdts.value) {
//     const verifiedPdts = rows.value.filter((pdt) => pdt.verified);
//     return verifiedPdts.slice(startIndex, endIndex);
//   } else if (showPublishedPdts.value) {
//     const publishedPdts = rows.value.filter((pdt) => pdt.published);
//     return publishedPdts.slice(startIndex, endIndex);
//   } else {
//     return rows.value;
//   }
// });

// fetching verified products
// const fetchVerifiedProducts = async () => {
//   try {
//     loading.value = true;
//     const response = await axios.post(
//       // additional to global baseUrl endpoint
//       "/products",
//       {
//         method: "get_verified_products",
//         page: currentPage.value,
//       },
//       {
//         headers: {
//           Authorization: `Bearer ${token.value}`,
//         },
//       }
//     );
//     if (response.data.success) {
//       rows.value = response.data.data.map((product: Product) => ({
//         last_created_at: product.last_created_at,
//         userId: product.user_id,
//         username: product.username,
//         productId: product.id,
//         title: product.title,
//         description: product.description,
//         picture: product.picture,
//         verified: product.verified,
//         published: product.published,
//         createdBy: product.username,
//         clientId: product.user_id,
//         price: product.price,
//         location: product.location,
//         phone: product.phone_number,
//       }));
//     } else {
//       return Swal.fire({
//         title: "Error",
//         text: "Failed to fetch verified products",
//         icon: "error",
//         confirmButtonColor: "#3085d6",
//       });
//     }
//   } catch (error: any) {
//     return Swal.fire({
//       title: "Error",
//       text: error.message,
//       icon: "error",
//       confirmButtonColor: "#3085d6",
//     });
//   } finally {
//     loading.value = false;
//   }
// };

// const fetchPublishedProducts = async () => {
//   try {
//     loading.value = true;
//     const response = await axios.post(
//       // additional to global baseUrl endpoint
//       "/products",
//       {
//         method: "get_published_products",
//         page: page,
//       },
//       {
//         headers: {
//           Authorization: `Bearer ${token.value}`,
//         },
//       }
//     );
//     if (response.data.success) {
//       rows.value = response.data.data.map((product: Product) => ({
//         last_created_at: product.last_created_at,
//         userId: product.user_id,
//         username: product.username,
//         productId: product.id,
//         title: product.title,
//         description: product.description,
//         picture: product.picture,
//         verified: product.verified,
//         published: product.published,
//         createdBy: product.username,
//         clientId: product.user_id,
//         price: product.price,
//         location: product.location,
//         phone: product.phone_number,
//       }));
//     } else {
//       return Swal.fire({
//         title: "Error",
//         text: "Failed to fetch published products",
//         icon: "error",
//         confirmButtonColor: "#3085d6",
//       });
//     }
//   } catch (error: any) {
//     return Swal.fire({
//       title: "Error",
//       text: error.message,
//       icon: "error",
//       confirmButtonColor: "#3085d6",
//     });
//   } finally {
//     loading.value = false;
//   }
// };

onMounted(() => {
  fetchAllProducts();
});
</script>
  
  <style scoped>
.product-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 95%;
}
.home-user {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.right-arrow {
  background-color: transparent;
  border: none;
  color: gray;
}
h4 {
  font-size: 1rem;
  font-weight: 300;
  margin-top: 0.5rem;
}
.right-arrow-h4 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.material-symbols-outlined:hover {
  cursor: pointer;
}
.home-icon .material-symbols-outlined {
  font-size: 1.2rem;
  margin-left: 0;
}

h3 {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 2rem 0 3rem 0;
  color: #662d91;
}
.btns {
  display: flex;
  justify-content: flex-end;
  margin-right: 0;
}
.btns button {
  padding: 17px 20px;
  margin: 5px;
  font-size: 12px;
  border: none;
}
.add-product-btn {
  background: #662d91;
}
.add-product-btn:hover {
  color: #662d91;
  border: 1px solid #662d91;
  background-color: #f0e9f5;
}
.my-products-table {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

input,
textarea {
  padding: 10px;
}

/* teble starts here */
table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  border: 1px solid #eae9e9;

  padding: 1px;
  text-align: left;
  font-size: 0.8rem;
}
th {
  padding: 5px;
}
tr:hover {
  background-color: #d9edf4;
}

th {
  background-color: #f2f2f2;
}
/* .gray-row {
  background-color: #f2f2f2;
} */
.action-btns-2 .el-button {
  margin: 2px;
}

button {
  padding: 8px 10px;
  background-color: #662d91;
  color: white;
  margin: 1rem;
  cursor: pointer;
  border-radius: 5px;
  border: none;
}
.action-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.action-btns-2 {
  display: flex;
  flex-direction: row;
}
.delete-btn {
  background-color: red;
  border-radius: 50%;
}
.fa-check-circle,
.fa-times-circle {
  font-size: 2rem;
}
.edit-btn {
  border-radius: 50%;
}

.unverify-btn {
  background-color: transparent;
}
.verify-btn {
  background-color: transparent;
}
.verification-switch {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

iframe {
  height: 3rem;
  width: 7rem;
}

.action-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.1rem;
}

.icon-word {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.demo-image__error .image-slot {
  font-size: 30px;
}
.demo-image__error .image-slot .el-icon {
  font-size: 30px;
}
.demo-image__error .el-image {
  width: 100%;
  height: 200px;
}
.demo-image__preview .el-image:hover {
  cursor: pointer;
}
.demo-image__preview .el-image {
  z-index: 9999;
}

.clickable-media {
  cursor: pointer;
  height: 5rem;
  width: 9rem;
}

.image-link {
  text-decoration: none;
}
.edit-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
}
.edit-modal h2 {
  margin-top: 0;
}
.edit-modal label {
  display: block;
  margin-bottom: 5px;
}
.edit-modal input,
.edit-modal textarea {
  width: 100%;
  margin-bottom: 10px;
}
.edit-modal button {
  display: block;
  margin-top: 10px;
}
.viewed-img {
  height: 3rem;
  width: 15rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem 2rem;
}
.viewed-vid video {
  height: 20rem;
  width: 20rem;
}

.addDialogTrigger {
  border: 1px dashed black;
  border-radius: 5px;
  height: 100%;
  font-size: 3em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.material-symbols-outlined {
  font-size: 2rem;
  padding-right: 0.6rem;
}

strong {
  font-weight: 700;
}

.dropdown-content .material-symbols-outlined {
  font-size: 24px;
  margin-right: 8px;
}

.dropdown-content .text {
  font-size: 18px;
  padding: 0.5rem;
}
.pdt-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* Responsive adjustments for larger screens */
@media (max-width: 768px) {
  .btns {
    flex-direction: column;
    align-items: flex-start;
  }

  .add-product-btn {
    width: 100%;
    min-width: unset;
    margin-bottom: 1rem;
  }
  .pagination {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    font-size: 0.875rem;
  }

  .pagination .el-pagination {
    width: auto;
  }

  .pagination .el-pagination .el-pagination__pager {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .pagination .el-pagination .el-pagination__pager button {
    margin: 0.2rem;
  }
}

/* Responsive adjustments for screens smaller than 468px */
@media (max-width: 468px) {
  .btns {
    gap: 0.5rem;
  }

  .add-product-btn {
    padding: 8px 16px;
    font-size: 12px;
    width: 100%;
    min-width: unset;
    margin-bottom: 0.5rem;
  }
  .pagination {
    font-size: 0.75rem;
    padding: 0.5rem;
  }

  .pagination .el-pagination {
    width: auto;
  }

  .pagination .el-pagination .el-pagination__pager {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .pagination .el-pagination .el-pagination__pager button {
    margin: 0.1rem;
    padding: 0.3rem;
  }
}
</style>
<template>
  <el-dialog
    :model-value="isAddAdvertPaymentDialogVisible"
    title="Create Payment"
    :width="dialogWidth"
    :style="{ paddingBottom: '20px' }"
    @close="closeDialog"
  >
    <!--       style="max-width: 600px" -->
    <el-form v-loading="loading" class="modal-form" label-width="auto">
      <el-form-item label="Phone">
        <el-input
          type="text"
          v-model="phone"
          placeholder="256712345678"
          required
        />
      </el-form-item>
      <el-form-item label="Days">
        <el-input type="text" v-model="days" required />
      </el-form-item>
      <el-form-item label="Currency">
        <div class="user-input-selector">
          <el-select
            v-model="selectedCurrency"
            placeholder="Choose Currecny"
            @change="handleCurrencyChange"
          >
            <el-option
              v-for="currency in currencies"
              :key="currency"
              :label="currency"
              :value="currency"
            />
          </el-select>
        </div>
      </el-form-item>
      <el-button
        @click="createPayment"
        type="primary"
        class="create-payments-btn"
        >Create payment</el-button
      >
    </el-form>
  </el-dialog>
</template>
  
  <script lang="ts" setup>
import {
  ref,
  defineProps,
  defineEmits,
  computed,
  onMounted,
  onUnmounted,
} from "vue";

import axios from "axios";
import Swal from "sweetalert2";
import { useAuthStore } from "../../../store/authStore";
//  declaring the token
const token = computed(() => {
  return useAuthStore().token;
});

const props = defineProps<{
  isAddAdvertPaymentDialogVisible: boolean;
  advertId: string;
  clientId: string;
}>();

// emitting the custom event "close-dialog" for closing the dialog
const emit = defineEmits<{
  (e: "close-dialog"): void;
  (e: "fetch-adverts"): void;
}>();

const closeDialog = () => {
  emit("close-dialog");
};

// refs
const mode = ref("mm");
const phone = ref("");
const days = ref("");
const selectedCurrency = ref("");
const formData = ref(new FormData());
const currencies = ref(["UGX", "KES", "RWF"]);
const loading = ref(false);

const windowWidth = ref(window.innerWidth);

// Computed property for responsive dialog width
const dialogWidth = computed(() => {
  const width = windowWidth.value <= 480 ? "90%" : "350px";
  const maxWidth = "350px"; // Maximum width for large screens
  return `min(${width}, ${maxWidth})`;
});

function handleResize() {
  windowWidth.value = window.innerWidth; // Update the reactive windowWidth
}
// Watch for window resize events
onMounted(() => {
  window.addEventListener("resize", handleResize);
  handleResize();
});

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});
// handing the currency change
const handleCurrencyChange = async () => {
  formData.value.set("currency", selectedCurrency.value);
};

// Method for creatig the payment
const createPayment = async () => {
  try {
    loading.value = true;
    // Create a FormData object
    const formData = new FormData();
    formData.append("method", "make_payment");
    formData.append("mode", mode.value);
    formData.append("advert_id", props.advertId);
    formData.append("mobile", phone.value.toString());
    formData.append("days", days.value);
    // clientId represented by userId
    formData.append("user_id", props.clientId);
    formData.append("currency", selectedCurrency.value);

    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/payments",
      formData,
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response.data.success) {
      closeDialog();

      // Showing the loading Swal with a timeout
      const swalWithTimeout = async () => {
        const swalPromise = Swal.fire({
          title: "Transaction Started",
          text: response.data.info,
          icon: "info",
          confirmButtonColor: "#3085d6",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        // Set a timeout to automatically close the Swal after 1 minute (60,000 milliseconds)
        const timeoutPromise = new Promise<void>((resolve) => {
          setTimeout(() => {
            Swal.close(); // Close Swal dialog
            resolve();
          }, 60000); // 60 seconds
        });

        // Use Promise.race to handle whichever promise resolves first
        await Promise.race([swalPromise, timeoutPromise]);
      };

      // Calling the swalWithTimeout function
      await swalWithTimeout();

      // Checking the transaction status from the server
      const transactionStatusResponse = await axios.post(
        // additional to global baseUrl endpoint
        "/payments",
        {
          method: "check_transaction",
          advert_id: props.advertId,
        },
        {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        }
      );

      // Process the transaction status response
      if (
        transactionStatusResponse.data.success &&
        transactionStatusResponse.data.transaction_status ===
          "Transaction Successful"
      ) {
        // Show success Swal
        await Swal.fire({
          title: "Transaction Successful",
          text: "Your transaction was completed successfully.",
          icon: "success",
          confirmButtonColor: "#3085d6",
        });
        // Emit event to fetch adverts
        emit("fetch-adverts");
        closeDialog;
        // Clear payment form fields
        mode.value = "";
        phone.value = "";
        days.value = "";
        selectedCurrency.value = "";
      } else {
        // Show error Swal
        closeDialog;
        await Swal.fire({
          title: "Transaction Failed",
          text: "No payments found for this advert.",
          icon: "error",
          confirmButtonColor: "#d33",
        });
        mode.value = "";
        phone.value = "";
        days.value = "";
        selectedCurrency.value = "";
      }
    } else {
      // Handle other error cases
      const errorMessage = response.data.errors || "Failed to make payment";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }

    closeDialog();
  } catch (error: any) {
    await Swal.fire({
      title: "Payment Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#d33",
    });
  } finally {
    loading.value = false;
  }
};
</script>
  
  <style scoped>
.create-payments-btn {
  background-color: #662d91;
  color: #fff;
  border: none;
  padding: 20px 3rem;
  cursor: pointer;
  display: block;
  margin: 1rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

.create-payments-btn:hover {
  background-color: transparent;
  border: 1px solid #662d91;
  color: #662d91;
}
.user-input-selector {
  width: 100%;
}
</style>
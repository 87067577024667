<template>
  <div
    class="sidebar-container"
    :class="{
      'sidebar-hidden': !isSidebarVisible,
      'sidebar-visible': isSidebarVisible,
    }"
  >
    <div class="cashchat-logo">
      <img
        src="https://cashchatapp.com/assets/images/ddd.png"
        alt="cashchat logo"
      />
    </div>
    <div class="links">
      <router-link
        to="/dashboard/home-dashboard"
        @click="handleLinkClick('/dashboard/home-dashboard')"
        :class="{ 'active-link': $route.path === '/dashboard/home-dashboard' }"
      >
        <span class="icon-word">
          <span class="material-symbols-outlined"> grid_view </span>
          <span class="sidebar-word">Dashboard</span>
        </span></router-link
      >
      <router-link
        v-if="isAdmin"
        to="/dashboard/base-adverts"
        @click="handleLinkClick('/dashboard/base-adverts')"
        :class="{ 'active-link': $route.path === '/dashboard/base-adverts' }"
      >
        <span class="icon-word">
          <span class="material-symbols-outlined"> ad_group </span>
          <span class="sidebar-word">Adverts</span>
        </span></router-link
      >
      <router-link
        v-if="isAgent"
        to="/dashboard/my-adverts"
        @click="handleLinkClick('/dashboard/my-adverts')"
        :class="{ 'active-link': $route.path === '/dashboard/my-adverts' }"
      >
        <span class="icon-word">
          <span class="material-symbols-outlined"> ad_group </span>
          <span class="sidebar-word">My Adverts</span>
        </span></router-link
      >
      <router-link
        v-if="isAdmin"
        class="icon-word"
        to="/dashboard/adverts-payments"
        @click="handleLinkClick('/dashboard/adverts-payments')"
        :class="{
          'active-link': $route.path === '/dashboard/adverts-payments',
        }"
      >
        <span class="icon-word">
          <span class="material-symbols-outlined"> database </span>
          <span class="sidebar-word">Payments</span>
        </span></router-link
      >
      <router-link
        v-if="isAdmin"
        to="/dashboard/manage-users"
        @click="handleLinkClick('/dashboard/manage-users')"
        :class="{ 'active-link': $route.path === '/dashboard/manage-users' }"
      >
        <span class="icon-word">
          <span class="material-symbols-outlined"> group </span>
          <span class="sidebar-word"> Manage Users</span>
        </span></router-link
      >
      <router-link
        v-if="isAdmin"
        to="/dashboard/all-products"
        @click="handleLinkClick('/dashboard/all-products')"
        :class="{ 'active-link': $route.path === '/dashboard/all-products' }"
      >
        <span class="icon-word">
          <span class="material-symbols-outlined"> inventory_2 </span>
          <span class="sidebar-word"> Products</span>
        </span></router-link
      >
      <router-link
        v-if="isAgent"
        to="/dashboard/my-products"
        @click="handleLinkClick('/dashboard/my-products')"
        :class="{ 'active-link': $route.path === '/dashboard/my-products' }"
      >
        <span class="icon-word">
          <span class="material-symbols-outlined"> inventory_2 </span>
          <span class="sidebar-word"> My Products</span>
        </span></router-link
      >
      <router-link
        v-if="isAgent"
        to="/dashboard/my-earnings"
        @click="handleLinkClick('/dashboard/my-earnings')"
        :class="{ 'active-link': $route.path === '/dashboard/my-earnings' }"
      >
        <span class="icon-word">
          <span class="material-symbols-outlined"> database </span>
          <span class="sidebar-word"> My Earnings</span>
        </span></router-link
      >

      <router-link
        to="/dashboard/my-commissions"
        @click="handleLinkClick('/dashboard/my-commissions')"
        :class="{ 'active-link': $route.path === '/dashboard/my-commissions' }"
      >
        <span class="icon-word">
          <span class="material-symbols-outlined"> payments </span>
          <span class="sidebar-word"> My Commissions</span>
        </span></router-link
      >

      <router-link
        v-if="isAgent"
        to="/dashboard/paid-adverts"
        @click="handleLinkClick('/dashboard/paid-adverts')"
        :class="{ 'active-link': $route.path === '/dashboard/paid-adverts' }"
      >
        <span class="icon-word">
          <span class="material-symbols-outlined"> paid </span>
          <span class="sidebar-word"> Paid Adverts</span>
        </span></router-link
      >
      <router-link
        v-if="isAdmin"
        to="/dashboard/admin-paid-adverts"
        @click="handleLinkClick('/dashboard/admin-paid-adverts')"
        :class="{
          'active-link': $route.path === '/dashboard/admin-paid-adverts',
        }"
      >
        <span class="icon-word">
          <span class="material-symbols-outlined"> paid </span>
          <span class="sidebar-word"> Admin Paid Ads</span>
        </span></router-link
      >
      <router-link
        v-if="isAdmin"
        to="/dashboard/manage-announcements"
        @click="handleLinkClick('/dashboard/manage-announcements')"
        :class="{
          'active-link': $route.path === '/dashboard/manage-announcements',
        }"
      >
        <span class="icon-word">
          <span class="material-symbols-outlined"> campaign </span>
          <span class="sidebar-word"> Announcements</span>
        </span></router-link
      >
      <router-link
        v-if="isAdmin"
        to="/dashboard/all-commissions"
        @click="handleLinkClick('/dashboard/all-commissions')"
        :class="{
          'active-link': $route.path === '/dashboard/all-commissions',
        }"
      >
        <span class="icon-word">
          <span class="material-symbols-outlined"> local_atm </span>
          <span class="sidebar-word"> All Commissions</span>
        </span></router-link
      >
      <router-link
        v-if="isAdmin"
        to="/dashboard/all-user-widthdraws"
        @click="handleLinkClick('/dashboard/all-user-widthdraws')"
        :class="{
          'active-link': $route.path === '/dashboard/all-user-widthdraws',
        }"
      >
        <span class="icon-word">
          <span class="material-symbols-outlined"> receipt_long </span>
          <span class="sidebar-word"> User Withdraws</span>
        </span></router-link
      >
      <router-link
        to="/dashboard/my-widthdraws"
        @click="handleLinkClick('/dashboard/my-widthdraws')"
        :class="{
          'active-link': $route.path === '/dashboard/my-widthdraws',
        }"
      >
        <span class="icon-word">
          <span class="material-symbols-outlined"> finance_chip </span>
          <span class="sidebar-word"> My Withdraws</span>
        </span></router-link
      >
      <div class="down-links">
        <router-link
          to="/dashboard/account-settings"
          @click="handleLinkClick('/dashboard/account-settings')"
          :class="{
            'active-link': $route.path === '/dashboard/account-settings',
          }"
        >
          <span class="icon-word">
            <span class="material-symbols-outlined"> settings </span>
            <span class="sidebar-word">Account Settings</span>
          </span></router-link
        >
        <router-link
          class="logout-section"
          plain
          @click="showLogoutDialog"
          to="#"
          :class="{ 'active-link': $route.path === '/logout' }"
        >
          <span class="icon-word">
            <span class="material-symbols-outlined"> logout </span>
            <span class="sidebar-word">Log Out</span>
          </span>
        </router-link>
      </div>
    </div>
  </div>
</template>
  
  <script lang="ts" setup>
import { defineEmits, computed, ref, onMounted, onUnmounted } from "vue";
import { useAuthStore } from "../store/authStore";
import { useRouter } from "vue-router";
import { ElMessageBox } from "element-plus";

const emit = defineEmits(["hideSidebar"]);

const handleLinkClick = (route: string) => {
  emit("hideSidebar");
};

const authStore = useAuthStore();
const router = useRouter();

// const isSidebarVisible = ref(true);
const isSidebarVisible = ref(window.innerWidth < 768);

const handleResize = () => {
  isSidebarVisible.value = window.innerWidth < 768;
};

onMounted(() => {
  handleResize();
  window.addEventListener("resize", handleResize);
});

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});

const showLogoutDialog = async () => {
  try {
    await ElMessageBox.confirm("Are you sure you want to log out?", "Warning", {
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      type: "warning",
    });
    authStore.logout();
    router.push("/login");
  } catch (error) {
    if (error !== "cancel") {
      throw new Error();
    }
  }
};

const isAdmin = computed(() => {
  return useAuthStore().isAdmin;
});
const isAgent = computed(() => {
  return useAuthStore().isAgent;
});
</script>

<style scoped>
.sidebar-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 100vh;
  z-index: 99;
  overflow-y: scroll;
  /* border: 2px solid red; */
}
/* .sidebar-hidden .links {
  display: none;
} */
.cashchat-logo img {
  height: 1rem;
  width: 7rem;
  margin: 2rem 1rem;
}
.icon-word {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  gap: 5px;
}
.sidebar-word {
  font-size: 0.9rem;
  font-weight: 400;
}
.links {
  display: flex;
  flex-direction: column;
}
.links a {
  text-decoration: none;
  color: gray;
  padding: 15px;
  margin-left: -0.5rem;
}
.links a:hover {
  color: #662d91;
  background-color: #f0e9f5;
}
.active-link {
  color: #662d91;
  background-color: #f0e9f5;
}
.down-links {
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
  margin-bottom: 2rem;
}
.material-symbols-outlined {
  font-size: 1.2rem;
}
.logout-section {
  margin-bottom: 2rem;
}
.custom-dialog {
  z-index: 99999 !important;
}
.sidebar-hidden {
  display: none;
}
.sidebar-visible {
  display: block;
}
@media (max-width: 768px) {
  .sidebar-hidden {
    transform: translateX(-100%);
  }
  .sidebar-container {
    display: block;
  }
}
@media (min-width: 769px) {
  .sidebar-hidden,
  .sidebar-visible {
    transform: translateX(0);
  }
  .sidebar-container {
    display: block;
  }
}
</style>
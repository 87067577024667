<template>
  <div v-loading="loading" class="acount-settings-container">
    <div class="home-user">
      <span class="home-icon">
        <span class="material-symbols-outlined" @click="goHome()">
          grid_view
        </span>
      </span>
      <div class="right-arrow-h4">
        <el-button class="right-arrow" :icon="arrowIcon" circle></el-button>
        <h4>Account Settings</h4>
      </div>
    </div>
    <h3>Account Settings</h3>
    <section class="user-info">
      <div class="profile">
        <div class="profile-picture">
          <img
            v-loading="loading"
            class="profile-image"
            v-if="picture"
            :src="picture"
            alt="Profile Picture"
          />
          <div v-else class="initials">
            {{ getUserInitials(username) }}
          </div>
        </div>
        <div>
          <el-button
            type="file"
            class="upload-btn"
            @click="uploadProfilePicture"
            >Upload</el-button
          >
          <el-button type="btn" class="remove-btn" @click="removeProfilePicture"
            >Remove</el-button
          >
        </div>
      </div>
      <hr />
      <div class="basic-info">
        <div class="basic-info-content">
          <h4>Basic Info</h4>
          <hr />
          <div class="info-item">
            <span class="label">User Id</span>
            <span class="value">{{ userId }}</span>
          </div>
          <div class="info-item">
            <span class="label">Username</span>
            <span class="value">{{ username }}</span>
          </div>
          <div class="info-item">
            <span class="label">About</span>
            <span class="message-and-edit-icon">
              <span class="value message">{{ aboutMessage }}</span>
              <el-tooltip content="update about" placement="top">
                <el-button
                  class="edit-btn"
                  @click="startUpdatingAbout"
                  type="primary"
                  :icon="Edit"
                  circle
                />
              </el-tooltip>
            </span>
          </div>
          <div class="info-item">
            <span class="label">Phone</span>
            <span class="value">{{ phone }}</span>
          </div>
          <div class="info-item">
            <span class="label">Country</span>
            <span class="value">{{ country }}</span>
          </div>
          <div class="info-item">
            <span class="label">Is blocked</span>
            <span class="value">{{ blockStatus }}</span>
          </div>
          <div class="info-item">
            <span class="label">Date Account Created</span>
            <span class="value">{{ formattedDate }}</span>
          </div>
        </div>
      </div>
      <div class="info-contact">
        <span>Contact Our Support Team</span>
        <a :href="`mailto:${emailSupport}`">{{ emailSupport }}</a>
      </div>
    </section>
    <el-dialog
      v-model="updatDialogVisible"
      title="Update About"
      :width="dialogWidth"
      @close="closeUpdateDialog"
    >
      <el-form class="modal-form" label-width="auto">
        <el-form-item label="Status">
          <el-input
            type="text"
            v-model="editedAbout"
            :placeholder="editedAbout"
            style="height: 35px"
            required
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button class="cancel-button" @click="updatDialogVisible = false"
            >Cancel</el-button
          >
          <el-button class="confirm-button" type="primary" @click="editAbout">
            Update
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted, onUnmounted } from "vue";
import axios from "axios";
import { ArrowRight, Edit } from "@element-plus/icons-vue";
import { useRouter } from "vue-router";
import { useAuthStore } from "../../store/authStore";
import Swal from "sweetalert2";

// defining the token
const token = computed(() => {
  return useAuthStore().token;
});
//

const router = useRouter();

const arrowIcon = ArrowRight;
// refs
const userId = ref("");
const username = ref("");
const phone = ref("");
const country = ref("");
const picture = ref<string | null>(null);
const isBlocked = ref(false);
const about = ref("");
const editedAbout = ref("");
const profileViews = ref(0);
const createdOn = ref("");
const emailSupport = ref("");
const loading = ref(false);
const updatDialogVisible = ref(false);
const closeUpdateDialog = ref(false);

const windowWidth = ref(window.innerWidth);

// Computed property for responsive dialog width
const dialogWidth = computed(() => {
  const width = windowWidth.value <= 480 ? "90%" : "500px";
  const maxWidth = "800px"; // Maximum width for large screens
  return `min(${width}, ${maxWidth})`;
});

function handleResize() {
  windowWidth.value = window.innerWidth; // Update the reactive windowWidth
  console.log(`New window width: ${windowWidth.value}`);
}
// Watch for window resize events
onMounted(() => {
  window.addEventListener("resize", handleResize);
});

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});

// getting  username initials of the user
const getUserInitials = (username: string): string => {
  if (!username) return "";

  const initials = username
    .split(" ")
    .map((name) => name.charAt(0))
    .join("");
  return initials.toUpperCase();
};

// formatting the time
const formattedDate = computed(() => {
  const date = new Date(createdOn.value);
  return date.toLocaleDateString(undefined, {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
});

// method for redirecting to home
const goHome = () => {
  router.push("/dashboard");
};

// checking for the status if blocked
const blockStatus = computed(() => {
  return isBlocked.value ? "Blocked" : "Not Blocked";
});

// Method to handle profile picture upload
const uploadProfilePicture = async () => {
  const fileInput = document.createElement("input");
  fileInput.type = "file";
  fileInput.accept = "image/*";

  fileInput.onchange = async () => {
    const file = fileInput.files?.[0];

    if (file) {
      const formData = new FormData();
      formData.append("method", "edit_profile");
      formData.append("user_id", userId.value);
      formData.append("picture", file);

      try {
        loading.value = true;
        // additional to global baseUrl endpoint
        const response = await axios.post("/users", formData, {
          headers: {
            Authorization: `Bearer ${token.value}`,
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.data.success) {
          accountSettings();
          return Swal.fire({
            title: "Success",
            text: "Profile picture uploaded successfully.",
            icon: "success",
            confirmButtonColor: "#3085d6",
          });
        } else {
          // Handle other error cases
          const errorMessage =
            response.data.errors || "Failed to upload profile picture";
          return Swal.fire({
            title: "Error",
            text: errorMessage,
            icon: "error",
            confirmButtonColor: "#d33",
          });
        }
      } catch (error: any) {
        return Swal.fire({
          title: "Success",
          text: error.message,
          icon: "success",
          confirmButtonColor: "#3085d6",
        });
      } finally {
        loading.value = false;
      }
    }
  };
  fileInput.click();
};

// Method to handle profile picture removal
const removeProfilePicture = async () => {
  loading.value = true;
  const formData = new FormData();
  formData.append("method", "remove_picture");
  try {
    loading.value = true;
    // additional to global baseUrl endpoint
    const response = await axios.post("/users", formData, {
      headers: {
        Authorization: `Bearer ${token.value}`,
      },
    });

    if (response.data.message === "Picture removed successfully") {
      accountSettings();
      return Swal.fire({
        title: "Success",
        text: response.data.message,
        icon: "success",
        confirmButtonColor: "#3085d6",
      });
    } else {
      // Handle other error cases
      const errorMessage = response.data.errors || "Failed to remove picture";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#3085d6",
    });
  } finally {
    loading.value = false;
  }
};

// creating a message for about, if there is no about, then add this test
const aboutMessage = computed(() => {
  return about.value && about.value.trim() !== ""
    ? about.value
    : "Wow, it's a beautiful day";
});

// triggering the dialog for updating about
const startUpdatingAbout = async () => {
  try {
    loading.value = true;
    updatDialogVisible.value = true;
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/users",
      {
        method: "account_settings",
      },
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );
    if (response.data.success) {
      editedAbout.value = response.data.info.about;
    } else {
      // Handle other error cases
      const errorMessage = response.data.errors || "Failed to fetch data";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#3085d6",
    });
  } finally {
    loading.value = false;
  }
};

// Editing the about
const editAbout = async () => {
  const formData = new FormData();
  formData.append("method", "edit_profile");
  formData.append("user_id", userId.value);
  formData.append("about", editedAbout.value);
  try {
    loading.value = true;
    // additional to global baseUrl endpoint
    const response = await axios.post("/users", formData, {
      headers: {
        Authorization: `Bearer ${token.value}`,
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.data.success) {
      about.value = editedAbout.value;
      accountSettings();
      updatDialogVisible.value = false;
      return Swal.fire({
        title: "Success",
        text: "Successfully updated",
        icon: "success",
        confirmButtonColor: "#3085d6",
      });
    } else {
      // Handle other error cases
      const errorMessage = response.data.errors || "Failed to update about";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#3085d6",
    });
  } finally {
    loading.value = false;
  }
};

// Method for account settings
const accountSettings = async () => {
  try {
    loading.value = true;
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/users",
      {
        method: "account_settings",
      },
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );

    if (response.data.success) {
      const results = response.data.info;

      userId.value = results.user_account_id;
      username.value = results.username;
      phone.value = results.phone;
      country.value = results.country;
      picture.value = results.picture;
      isBlocked.value = results.is_blocked;
      about.value = results.about;
      profileViews.value = results.profile_views;
      createdOn.value = results.account_created_on;
      emailSupport.value = results.email_support;
    } else {
      // Handle other error cases
      const errorMessage = response.data.errors || "Failed to fetch data";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#3085d6",
    });
  } finally {
    loading.value = false;
  }
};

onMounted(() => {
  accountSettings();
});
</script>

<style scoped>
.acount-settings-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 95%;
}

.home-user {
  display: flex;
  align-items: center;
  /* gap: 0.5rem; */
  margin-left: 0;
}
.profile {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  margin-bottom: 3rem;
}
.home-icon .material-symbols-outlined {
  font-size: 1.2rem;
  margin-left: 0;
  margin-right: 1rem;
}
.home-icon .material-symbols-outlined:hover {
  cursor: pointer;
}
h4 {
  font-size: 1rem;
  font-weight: 300;
  margin-top: 0.5rem;
}
.right-arrow-h4 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.right-arrow {
  background-color: transparent;
  border: none;
  color: gray;
}

h3 {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 2rem 0 3rem 0;
  color: #662d91;
}

.profile-image {
  display: flex;
  height: 6rem;
  width: 6rem;
  border-radius: 50%;
  object-fit: cover;
}
.initials {
  background-color: #662d91;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  height: 6rem;
  width: 6rem;
  border-radius: 50%;
}

.remove-btn,
.upload-btn {
  padding: 20px 25px;
  height: 2rem;
  background: transparent;
  color: #662d91;
  border: 1px solid #662d91;
}
.upload-btn {
  background: #662d91;
  color: #fff;
  border: 1px solid #662d91;
}

.upload-btn:hover {
  color: #662d91;
  border: 1px solid #662d91;
  background-color: #f0e9f5;
}
.remove-btn:hover {
  background: #662d91;
  color: #fff;
}
.basic-info {
  width: 100%;
  background-color: #f9f9f9;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.basic-info-content {
  padding: 2rem;
}

.basic-info h4 {
  margin-bottom: 10px;
  font-size: 1.5rem;
}

.basic-info hr {
  margin: 10px 0;
  border: none;
  border-top: 1px solid #ddd;
}

.info-item,
.info-contact {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
}
.info-contact {
  justify-content: space-around;
}
.info-contact span:first-child {
  font-size: 1.2rem;
  color: #662d91;
}

.label {
  font-weight: bold;
  margin-right: 10px;
}

.value {
  flex: 1;
  text-align: right;
}

.edit-btn {
  margin-left: 1.5rem;
  background-color: #662d91;
  border: none;
  color: #fff;
}

.cancel-button,
.confirm-button:hover {
  background-color: transparent;
  border: 1px solid #662d91;
  color: #662d91;
}

.confirm-button::after {
  content: "  Me";
  opacity: 0;
  transition: opacity 0.3s ease;
}

.confirm-button:hover::after {
  opacity: 1;
}

.confirm-button,
.cancel-button:hover {
  background-color: #662d91;
  border: none;
  color: white;
}

/* responsiveness for different screens */

@media (max-width: 468px) {
  .info-item,
  .info-contact {
    padding: 10px 0;
  }
  .about-info {
    display: flex;
    align-items: flex-start;
  }
  .message-and-edit-icon {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 2rem;
  }
}

@media (max-width: 480px) {
  .acount-settings-container {
    width: 100%;
  }
  .info-item,
  .info-contact {
    flex-direction: column;
    align-items: flex-start;
  }
  .message-and-edit-icon {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
  }
  .home-user,
  .user-info {
    margin-left: 1rem;
  }
  .home-icon .material-symbols-outlined {
    font-size: 0.8rem;
    margin-right: 0.3rem;
  }

  h4 {
    font-size: 0.8rem;
  }

  .profile-image,
  .initials {
    height: 3.5rem;
    width: 3.5rem;
    font-size: 19px;
  }

  h3 {
    font-size: 1rem;
    margin-left: 1rem;
  }

  .basic-info-content {
    padding: 0.8rem;
  }

  .basic-info h4 {
    font-size: 1rem;
  }

  .info-contact span:first-child {
    font-size: 0.8rem;
  }

  .remove-btn,
  .upload-btn {
    padding: 0.5rem 0.6rem;
    height: 1.5rem;
  }
}
@media (max-width: 768px) {
  .info-item,
  .info-contact {
    flex-direction: column;
    align-items: flex-start;
  }
  .info-item .label,
  .info-item .value {
    margin-right: 0;
    margin-bottom: 5px;
  }

  .info-item .edit-btn {
    margin-left: 0;
    margin-top: 10px;
  }
  .message-and-edit-icon {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
  }
}
</style>
<template>
  <div v-loading="loading" class="home-container">
    <template v-if="isAdmin">
      <AdminStatistics @loaded="handleLoaded" />
      <section class="graph-container">
        <div class="dashboard-graph">
          <DashboardGraph @loaded="handleLoaded" />
        </div>
        <div class="user-variations">
          <UserVariations @loaded="handleLoaded" />
        </div>
      </section>
    </template>
    <template v-else>
      <AgentsStatistics @loaded="handleLoaded" />
      <section class="graph-container">
        <div class="dashboard-graph">
          <DashboardGraph @loaded="handleLoaded" />
        </div>
        <div class="user-variations" v-if="isAdmin">
          <UserVariations @loaded="handleLoaded" />
        </div>
        <div class="agents-income-stats" v-else>
          <AgentsIncomeStat @loaded="handleLoaded" />
        </div>
      </section>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from "vue";
import AdminStatistics from "./admin-statistics-page.vue";
import AgentsStatistics from "./agents-statistics-page.vue";
import DashboardGraph from "../../components/dashboard-graph-component.vue";
import UserVariations from "../../components/user-variations-component.vue";
import AgentsIncomeStat from "../../components/agents-income-stats-component.vue";
import { useAuthStore } from "../../store/authStore";

const isAdmin = computed(() => {
  return useAuthStore().isAdmin;
});

// initialising the loading
const loading = ref(true);

// listening to  the emitted event from the child
const handleLoaded = () => {
  // Setting loading to false when all components have loaded
  loading.value = false;
};
</script>


<style scoped>
.home-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 95%;
}
.graph-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 2rem;
  margin: 2rem auto;
}

.dashboard-graph {
  flex: 2;
}

.user-variations,
.agents-income-stats {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

/* Media Queries for Responsive Design */
@media (max-width: 468px) {
  .graph-container {
    flex-direction: column;
  }
}
@media (max-width: 768px) {
  .graph-container {
    flex-direction: column;
  }
}
</style>
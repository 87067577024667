<template>
  <el-dialog
    :model-value="isTopupCommissionsDialogVisible"
    title="Top up user commissions"
    :width="dialogWidth"
    :style="{ paddingBottom: '20px' }"
    @close="closeDialog"
  >
    <!--       style="max-width: 600px" -->
    <el-form v-loading="loading" class="modal-form" label-width="auto">
      <el-form-item label="Amount">
        <el-input type="text" v-model="amount" required />
      </el-form-item>
      <el-button
        @click="topupAgentsCommissions"
        type="primary"
        class="topup-btn"
        >Top up Commissions</el-button
      >
    </el-form>
  </el-dialog>
</template>
      
      <script lang="ts" setup>
import {
  ref,
  defineProps,
  defineEmits,
  computed,
  onMounted,
  onUnmounted,
} from "vue";

import axios from "axios";
import Swal from "sweetalert2";
import { useAuthStore } from "../../../store/authStore";
//  declaring the token
const token = computed(() => {
  return useAuthStore().token;
});

const props = defineProps<{
  isTopupCommissionsDialogVisible: boolean;
  userId: string;
}>();

// emitting the custom event "close-dialog" for closing the dialog
const emit = defineEmits<{
  (e: "close-dialog"): void;
  (e: "fetch-all-users"): void;
}>();

const closeDialog = () => {
  emit("close-dialog");
};

// refs
const amount = ref("");
const loading = ref(false);
const username = ref("");

const windowWidth = ref(window.innerWidth);

// Computed property for responsive dialog width
const dialogWidth = computed(() => {
  let width = "90%"; // Default for very small screens
  if (windowWidth.value > 768) {
    width = "80%"; // Medium screens
  }
  if (windowWidth.value > 1024) {
    width = "300px"; // Large screens
  }
  return `min(${width}, 300px)`;
});

function handleResize() {
  windowWidth.value = window.innerWidth; // Update the reactive windowWidth
}

// Watch for window resize events
onMounted(() => {
  window.addEventListener("resize", handleResize);
  handleResize(); // Initialize width on mount
});

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});
// const form = {
//     clientsId: ref<string>(""),
// }

const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const topupAgentsCommissions = async () => {
  try {
    loading.value = true;

    const formData = new FormData();

    formData.append("method", "user_verification");
    formData.append("user_id", props.userId);

    const response = await axios.post(
      // additional to global baseUrl endpoint
      "users",
      formData,
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (response.data.success) {
      username.value = response.data.info.username;
      closeDialog();

      const result = await Swal.fire({
        title: "Confirm User",
        html:
          "You are toppingup commission for :<br><strong>" +
          `${capitalize(username.value)}</strong><br>` +
          "Are you sure you want to proceed?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        allowEscapeKey: false,
        allowOutsideClick: false,
        showLoaderOnConfirm: true,
      });
      if (result.isConfirmed) {
        // Swal.showLoading();
        // Create a FormData object
        const formData = new FormData();
        formData.append("method", "top_up_commission");
        formData.append("user_id", props.userId);
        formData.append("amount", amount.value);

        const response = await axios.post(
          // additional to global baseUrl endpoint
          "/payments",
          formData,
          {
            headers: {
              Authorization: `Bearer ${token.value}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (
          response.data.success &&
          response.data.message === "Commission topped up successfully"
        ) {
          closeDialog();
          return Swal.fire({
            title: "Transaction Successful",
            text: "Commission topped up successfully",
            icon: "success",
            confirmButtonColor: "#3085d6",
          });
        } else {
          // Handle other error cases
          const errorMessage =
            response.data.errors || "Failed to make a commission topup";
          return Swal.fire({
            title: "Error",
            text: errorMessage,
            icon: "error",
            confirmButtonColor: "#d33",
          });
        }
      } else {
        await Swal.fire({
          title: "Cancelled",
          text: "Process was cancelled",
          icon: "info",
          confirmButtonColor: "#662d91",
        });
      }
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Topup Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#d33",
    });
  } finally {
    loading.value = false;
  }
};
</script>
  
    <style scoped>
.topup-btn {
  background-color: #662d91;
  color: #fff;
  border: none;
  padding: 20px 3rem;
  cursor: pointer;
  display: block;
  margin: 1rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

.topup-btn:hover {
  background-color: transparent;
  border: 1px solid #662d91;
  color: #662d91;
}
@media (max-width: 768px) {
  .profile-picture-details {
    flex-direction: column; /* Stack profile picture and details vertically */
    align-items: flex-start; /* Align items to the start */
  }

  .profile-picture {
    margin-bottom: 1rem; /* Space below the profile picture */
  }
  .username-and-id {
    text-align: center; /* Center text on smaller screens */
  }

  .phone-and-country,
  .views-created-at {
    flex-direction: column; /* Stack phone and country details vertically */
  }
}

/* Adjust layout for very small screens */
@media (max-width: 480px) {
  .user-details-content {
    padding: 0.5rem; /* Reduced padding */
  }

  .profile-picture-details {
    gap: 0.5rem; /* Reduced gap */
  }

  .profile-picture img {
    width: 80px; /* Smaller profile picture */
    height: 80px; /* Maintain aspect ratio */
  }

  .username-and-id h5 {
    font-size: 1.2rem; /* Smaller font size for username */
  }

  .phone-and-country,
  .views-created-at {
    flex-direction: column; /* Stack items vertically */
    text-align: center; /* Center text */
  }

  .paragraph-spacing {
    margin-top: 0.5rem; /* Space between phone and country details */
  }
}
</style>
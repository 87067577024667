<template>
  <div>
    <el-dialog
      :model-value="isUserDetailsDialogVisible"
      :width="dialogWidth"
      :style="{ paddingBottom: '20px' }"
      @close="closeDialog"
      @open="viewUserDetails"
      :show-close="false"
    >
      <div v-loading="loading" class="user-details-content">
        <div class="profile-picture-details">
          <!-- Check if picture is available -->
          <div class="profile-picture">
            <img
              class="profile-image"
              v-if="userDetails.picture"
              :src="userDetails.picture"
              alt="Profile Picture"
            />
            <!-- If picture is null, display username initials -->
            <div v-else class="initials">
              {{ getUserInitials(userDetails.username) }}
            </div>
          </div>
          <div class="username-and-id">
            <span class="username">
              <h5>{{ capitalize(userDetails.username) }}</h5>
            </span>
            <span class="userid">
              <p class="bold-paragraph">User Id</p>
              <p>{{ userDetails.id }}</p>
            </span>
          </div>
        </div>
        <div class="user-details">
          <h3>User Details</h3>
          <hr />
          <div>
            <p class="bold-paragraph">About</p>
            <p v-if="userDetails.about">{{ userDetails.about }}</p>
            <p v-else>Wow, it's a beautiful day</p>
          </div>
          <div class="phone-and-country">
            <div>
              <p class="bold-paragraph">Phone</p>
              <p>{{ userDetails.phone }}</p>
            </div>
            <div class="paragraph-spacing">
              <p class="bold-paragraph">Country</p>
              <p>{{ userDetails.country }}</p>
            </div>
          </div>
          <div class="views-created-at">
            <div>
              <p class="bold-paragraph">Profile Views</p>
              <p>{{ userDetails.profile_views }}</p>
            </div>
            <div>
              <p class="bold-paragraph">Account Created on</p>
              <p>{{ formatDate(userDetails.account_created) }}</p>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script lang="ts" setup>
import {
  ref,
  defineEmits,
  defineProps,
  computed,
  onMounted,
  onUnmounted,
} from "vue";
import UserDetails from "../../../types/UserDetails";
import axios from "axios";
import { useAuthStore } from "../../../store/authStore";
import Swal from "sweetalert2";

const windowWidth = ref(window.innerWidth);

// Computed property for responsive dialog width
const dialogWidth = computed(() => {
  let width = "90%"; // Default for very small screens
  if (windowWidth.value > 768) {
    width = "80%"; // Medium screens
  }
  if (windowWidth.value > 1024) {
    width = "700px"; // Large screens
  }
  return `min(${width}, 900px)`;
});

function handleResize() {
  windowWidth.value = window.innerWidth; // Update the reactive windowWidth
}

// Watch for window resize events
onMounted(() => {
  window.addEventListener("resize", handleResize);
  handleResize(); // Initialize width on mount
});

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});

// token
const token = computed(() => {
  return useAuthStore().token;
});

const props = defineProps<{
  isUserDetailsDialogVisible: boolean;
  userId: string;
}>();

const loading = ref(false);

const emit = defineEmits<{
  (e: "close-dialog"): void;
  (e: "fetch-all-users"): void;
}>();

const closeDialog = () => {
  emit("close-dialog");
};

// declaring the  userdetails and giving it a type of UserDetails
const userDetails = ref<UserDetails>({
  username: "",
  phone: "",
  country: "",
  about: "",
  profile_views: 0,
  profile_visitors: [],
  account_created: "",
  id: 0,
  is_blocked: false,
  picture: "",
});

// capitalising the username
const capitalize = (username: string) => {
  if (username) {
    return username.charAt(0).toUpperCase() + username.slice(1);
  }
  return username;
};

// formating for the date to be in this format  day/month/year
const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  // Defining options for Intl.DateTimeFormat to format date as day/month/year
  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "short",
    year: "numeric",
  };

  // / /g, is by using RegEx for an occurancies of spaces and it's being replaced with a "/"
  return date.toLocaleDateString("en-GB", options).replace(/ /g, "/");
};

// Method for viewing  user's details
const viewUserDetails = async () => {
  try {
    loading.value = true;
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/users",
      {
        method: "user_details",
        user_id: props.userId,
      },

      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );
    if (response.data.success) {
      console.log(response.data);
      // emitting the event for the parent to listen to it
      emit("fetch-all-users");

      // declaring results to hold the entire data set from the server
      const results = response.data;

      // Update user details
      userDetails.value = {
        username: results.username,
        phone: results.phone,
        country: results.country,
        about: results.about,
        profile_views: results.profile_views,
        profile_visitors: results.profile_visitors.length,
        account_created: results.account_created,
        id: results.id,
        is_blocked: results.is_blocked,
        picture: results.picture,
      };
    } else {
      // Handle other error cases
      const errorMessage =
        response.data.errors || "Failed to fetch user details.";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#d33",
    });
  } finally {
    loading.value = false;
  }
};

// Getting the initials of the  user's username
const getUserInitials = (username: string): string => {
  if (!username) return "";

  const initials = username
    .split(" ")
    .map((name) => name.charAt(0))
    .join("");
  return initials.toUpperCase();
};
</script>

<style scoped>
.user-details-content {
  color: #fff;
  display: flex;
  gap: 4rem;
}
.profile-picture {
  margin: 1rem auto;
  height: 6.5rem;
  width: 6.5rem;
  border-radius: 50%;
}
.profile-picture-details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-left: 2rem;
}
.initials {
  background-color: #662d91;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.profile-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
p {
  color: black;
}
h3,
h5 {
  color: #662d91;
}
h5 {
  font-size: 1rem;
  text-align: center;
}
hr {
  color: gray;
}

.userid,
.username {
  display: flex;
  flex-direction: column;
}
.username-and-id {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.phone-and-country,
.views-created-at {
  display: flex;
  gap: 2rem;
}
.bold-paragraph {
  font-weight: 900;
  margin-bottom: -0.1rem;
}

@media (max-width: 768px) {
  .profile-picture-details {
    flex-direction: column; /* Stack profile picture and details vertically */
    /* align-items: flex-start;  */
  }

  .profile-picture {
    margin-bottom: 1rem; /* Space below the profile picture */
  }

  .username-and-id {
    text-align: center; /* Center text on smaller screens */
  }

  .phone-and-country,
  .views-created-at {
    flex-direction: column; /* Stack phone and country details vertically */
  }
}

/* Adjust layout for very small screens */
@media (max-width: 480px) {
  .user-details-content {
    padding: 0.5rem; /* Reduced padding */
  }

  .profile-picture-details {
    gap: 0.5rem; /* Reduced gap */
  }

  .profile-picture img {
    width: 80px; /* Smaller profile picture */
    height: 80px; /* Maintain aspect ratio */
  }

  .username-and-id h5 {
    font-size: 1.2rem; /* Smaller font size for username */
  }

  .phone-and-country,
  .views-created-at {
    flex-direction: column; /* Stack items vertically */
    text-align: center; /* Center text */
  }

  .paragraph-spacing {
    margin-top: 0.5rem; /* Space between phone and country details */
  }
}
</style>
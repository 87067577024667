<template>
  <div class="earnings-container">
    <div v-loading="loading" class="card">
      <header>
        <h1>My Earnings</h1>
        <P class="currency-value">{{ currencyValue }}</P>
      </header>
      <main class="content"></main>
    </div>
  </div>
</template>

<script lang="ts" setup>
// imports
import { useAuthStore } from "../../store/authStore";
import { onMounted, ref, computed } from "vue";
import axios from "axios";
import Swal from "sweetalert2";

// refs
const currencyValue = ref(0);
const loading = ref(false);

// token
const token = computed(() => {
  return useAuthStore().token;
});

const userId = computed(() => {
  return useAuthStore().userId;
});

// fetching all uer earnings
const fetchUserEarnings = async () => {
  try {
    loading.value = true;
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/adverts",
      { method: "advert_earnings", user_id: userId.value },
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );
    if (response.data.success) {
      currencyValue.value = response.data.my_earnings;
    } else {
      // Handle other error cases
      const errorMessage = response.data.errors || "Failed to fetch earnings";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#3085d6",
    });
  } finally {
    loading.value = false;
  }
};
onMounted(() => {
  fetchUserEarnings();
});
</script>

<style scoped>
.earnings-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 95%;
  background-color: rgba(0, 0, 0, 0.3);
}

.card {
  margin: 0 auto;
  height: 35rem;
  width: 20rem;
  transform: translateY(20px);
  /* background-color: #fff; */
  background: url("https://images.pexels.com/photos/4968383/pexels-photo-4968383.jpeg?auto=compress&cs=tinysrgb&w=400");
  background-size: cover;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
}

header {
  background-color: #662d91;
  padding: 20px;
  height: 15rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

header h1 {
  margin-bottom: 3rem;
  text-align: center;
  font-size: 24px;
  color: #fff;
}

header p {
  margin: 5px 0;
  color: #fff;
  text-align: center;
  font-size: 3rem;
}
.currency-value {
  font-size: 2rem;
}

.spinner-wrapper {
  background-color: rgba(0, 0, 0, 0.1);
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
}

.spinner-container {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 5px solid transparent;
  border-top-color: #662d91;
  border-right-color: #662d91;
  border-bottom: 5px dotted #662d91;
  animation: rotateCircle 1s linear infinite;
  margin: 50vh auto;
}

@keyframes rotateCircle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
  font-size: 14px;
  color: #662d91;
}
</style> 


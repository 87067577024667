<template>
  <div>
    <el-dialog
      :model-value="isPaidAdvertDetailsDialogVisible"
      title="Paid Advert details"
      :width="dialogWidth"
      :style="{ paddingBottom: '20px' }"
      @close="closeDialog"
      @open="viewPaidAdvertDetails"
    >
      <div id="receiptDialog" v-loading="loading">
        <div :style="{ border: '5px dotted #000', padding: '10px' }">
          <el-row :gutter="20">
            <el-col :span="12">
              <b>Title:</b>
            </el-col>
            <el-col :span="12">{{ paymentData.title }} </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <b>Trans Status:</b>
            </el-col>
            <el-col :span="12">{{ paymentData.transaction_status }} </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <b>Name:</b>
            </el-col>
            <el-col :span="12">{{ paymentData.payer_name }}</el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <b>Amount:</b>
            </el-col>
            <el-col :span="12">{{ paymentData.amount_paid }}</el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <b>Payment Id:</b>
            </el-col>
            <el-col :span="12">{{ paymentData.payment_id }}</el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <b>Number:</b>
            </el-col>
            <el-col :span="12">{{ paymentData.mobile }}</el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <b>Advert Id:</b>
            </el-col>
            <el-col :span="12">{{ paymentData.advert_id }}</el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <b>User Id:</b>
            </el-col>
            <el-col :span="12">{{ paymentData.user_id }}</el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <b>Payee Currency:</b>
            </el-col>
            <el-col :span="12">{{ paymentData.payee_currency }}</el-col>
          </el-row>
          <p style="margin-top: 20px">
            Thank you for your payment. If you have any questions, please
            contact us at
            <a href="mailto:support@cashchatapp.com">support@cashchatapp.com</a
            >.
          </p>
          <div class="button-container">
            <el-button
              class="receipt-btn"
              @click="downloadPDF(paymentData)"
              type="primary"
              >Download Receipt</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
    
    <script lang="ts" setup>
import {
  defineProps,
  ref,
  defineEmits,
  computed,
  onMounted,
  onUnmounted,
} from "vue";
import PaymentData from "../../../types/PaymentData";
import axios from "axios";
import { useAuthStore } from "../../../store/authStore";
import Swal from "sweetalert2";
// declaring the  endpoint
const token = computed(() => {
  return useAuthStore().token;
});

const loading = ref(false);

// Defining props

const props = defineProps<{
  isPaidAdvertDetailsDialogVisible: boolean;
  advertId: string;
}>();

// defining the emit
const emit = defineEmits<{
  (e: "close-dialog"): void;
}>();

// emitting the custom event "close-dialog" for closing the dialog

const closeDialog = () => {
  emit("close-dialog");
};

const windowWidth = ref(window.innerWidth);

// Computed property for responsive dialog width
const dialogWidth = computed(() => {
  const width = windowWidth.value <= 480 ? "90%" : "500px";
  const maxWidth = "500px"; // Maximum width for large screens
  return `min(${width}, ${maxWidth})`;
});

function handleResize() {
  windowWidth.value = window.innerWidth; // Update the reactive windowWidth
}
// Watch for window resize events
onMounted(() => {
  window.addEventListener("resize", handleResize);
  handleResize();
});

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});

// declaring payment data object
const paymentData = ref<PaymentData>({
  transaction_status: "",
  amount_paid: "",
  payment_id: "",
  payer_name: "",
  payment_date: "",
  user_id: "",
  advert_id: "",
  payee_currency: "",
  mobile: "",
  title: "",
});

// Mthod for viewing paid adverts details
const viewPaidAdvertDetails = async () => {
  try {
    loading.value = true;
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/payments",
      {
        method: "paid_advert_details",
        advert_id: props.advertId,
      },

      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );
    if (response.data.success) {
      console.log(response.data);
      const results = response.data;

      // Updating paymentData with transaction details
      paymentData.value = {
        title: results.title,
        transaction_status: results.transaction_status,
        payer_name: results.payer_name,
        payment_id: results.payment_id,
        mobile: results.mobile,
        advert_id: results.advert_id,
        user_id: results.user_id,
        payee_currency: results.payee_currency,
        amount_paid: results.amount_paid,
        payment_date: results.payment_date,
      };
    } else {
      // Handle other error cases
      const errorMessage =
        response.data.errors || "Failed to show adverts details";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#3085d6",
    });
  } finally {
    loading.value = false;
  }
};

// downloading the receipt as a pdf
const downloadPDF = async (row: PaymentData) => {
  try {
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/payments",
      {
        method: "export_transaction_details_pdf",
        advert_id: row.advert_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
        responseType: "blob", // Setting response type to blob (Bonary Larger Object) to receive binary data
      }
    );

    // Check if the request was successful
    if (response.status === 200) {
      // Creating a Blob object from the PDF data received from the backend response
      const pdfBlob = new Blob([response.data], { type: "application/pdf" });

      // Creating a temporary link element
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(pdfBlob);
      link.download = "receipt.pdf"; // Setting the filename for the downloaded file

      // Triggering the download
      link.click();

      // Revoking the URL to release the resources
      window.URL.revokeObjectURL(link.href);
    } else {
      // Handle other error cases
      const errorMessage = response.data.errors || "Failed to download pdf";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#d33",
    });
  }
};
</script>
  
  <style scoped>
.button-container {
  display: flex;
  justify-content: center;
}
</style>
<template>
  <el-dialog
    :model-value="isAllDeletedAdvertsDialogVisible"
    title="All deleted adverts"
    :width="dialogWidth"
    @close="closeDialog"
    @open="viewAllDeletedAdverts"
  >
    <div class="base-adverts-table">
      <el-table v-loading="loading" :data="rows" border>
        <el-table-column label="#">
          <template v-slot="{ $index }">
            {{ (currentPage - 1) * pageSize + ($index + 1) }}</template
          >
        </el-table-column>
        <el-table-column label="Client Id">
          <template v-slot="{ row }">{{ row.clientId }}</template>
        </el-table-column>

        <el-table-column label="Title">
          <template v-slot="{ row }">
            <el-tooltip effect="dark" :content="row.title" placement="top">
              <div>{{ truncateTitle(row.title) }}</div>
            </el-tooltip>
          </template>
        </el-table-column>

        <el-table-column label="Description">
          <template v-slot="{ row }">
            <el-tooltip
              effect="dark"
              :content="row.description"
              placement="top"
            >
              <div>{{ truncateDescription(row.description) }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="Created By">
          <template v-slot="{ row }">{{ row.createdBy }}</template>
        </el-table-column>
        <el-table-column label="Created At">
          <template v-slot="{ row }">{{
            row.last_created_at
              ? formatTimestamp(row.last_created_at)
              : getCurrentTime()
          }}</template>
        </el-table-column>
        <el-table-column label="Adverts">
          <template v-slot="{ row }">
            <template v-if="row.picture">
              <a :href="row.picture" target="_blank" class="image-link">
                <img
                  class="advert-img"
                  :src="row.picture"
                  alt="Advert Image"
                  style="max-width: 200px; max-height: 50px"
                />
              </a>
            </template>
            <template v-else>
              <div class="viewed-vid">
                <video
                  class="advert-video"
                  :src="row.video"
                  style="max-width: 200px; max-height: 50px"
                ></video>
              </div>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="Restore" width="90">
          <template v-slot="{ row }">
            <span
              @click="restoreDeletedAdvert(row.advertId)"
              class="material-symbols-outlined"
              style="color: #008000"
            >
              restore_from_trash
            </span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="currentPage"
        :small="small"
        :page-size="pageSize"
        :total="total"
        :disabled="disabled"
        :background="background"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, sizes, prev, pager, next, jumper"
      />
    </div>
  </el-dialog>
</template>
  
  <script lang="ts" setup>
import {
  ref,
  defineProps,
  defineEmits,
  computed,
  onMounted,
  onUnmounted,
} from "vue";
import { format } from "date-fns";
import axios from "axios";
import Swal from "sweetalert2";
import { useAuthStore } from "../../../store/authStore";
import Advert from "../../../types/Advert";

const windowWidth = ref(window.innerWidth);

// Computed property for responsive dialog width
const dialogWidth = computed(() => {
  const width = windowWidth.value <= 480 ? "90%" : "600px";
  const maxWidth = "900px"; // Maximum width for large screens
  return `min(${width}, ${maxWidth})`;
});

function handleResize() {
  windowWidth.value = window.innerWidth; // Update the reactive windowWidth
}
// Watch for window resize events
onMounted(() => {
  window.addEventListener("resize", handleResize);
  handleResize();
});

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});

// declaring the token
const token = computed(() => {
  return useAuthStore().token;
});

const props = defineProps<{
  isAllDeletedAdvertsDialogVisible: boolean;
}>();

// emitting the custom event "close-dialog" for closing the dialog
const emit = defineEmits<{
  (e: "close-dialog"): void;
  (e: "fetch-adverts"): void;
}>();

const closeDialog = () => {
  emit("close-dialog");
};

const loading = ref(false);
const rows = ref<Advert[]>([]);
const currentPage = ref(1);
const pageSize = ref(10);
const small = ref(false);
const background = ref(false);
const disabled = ref(false);
const total = ref(0);
const advertId = ref("");

const truncateDescription = (description: string) => {
  const words = description.split(" ");
  if (words.length > 3) {
    return words.slice(0, 3).join(" ") + "...";
  } else {
    return description;
  }
};
const truncateTitle = (title: string) => {
  const words = title.split(" ");
  if (words.length > 3) {
    return words.slice(0, 3).join(" ") + "...";
  } else {
    return title;
  }
};
// changing the format of the time using fns
const formatTimestamp = (timestamp: string) => {
  if (!timestamp) return ""; // Handling case where timestamp is null or undefined
  return format(new Date(timestamp), "yyyy-MM-dd HH:mm:ss");
};
const getCurrentTime = () => {
  const currentTime = new Date();
  const formattedTime = `${currentTime.toLocaleDateString()} ${currentTime.toLocaleTimeString()}`;
  return formattedTime;
};

// viewing all the deleted adverts

const viewAllDeletedAdverts = async () => {
  try {
    loading.value = true;
    const response = await axios.post(
      "/adverts",
      {
        method: "get_deleted_adverts",
      },
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );
    if (response.data.success) {
      total.value = parseInt(response.data.total_adverts);
      rows.value = response.data.result.map((advert: Advert) => ({
        last_created_at: advert.last_created_at,
        userId: advert.user_id,
        username: advert.username,
        advertId: advert.id,
        title: advert.title,
        description: advert.description,
        thumbnail: advert.thumbnail,
        video: advert.video,
        picture: advert.picture,
        verified: advert.verified,
        published: advert.published,
        createdBy: advert.created_by,
        clientId: advert.client_id,
      }));
    } else {
      // Handle error cases
      const errorMessage =
        response.data.errors || "Failed to fetch deleted adverts.";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#3085d6",
    });
  } finally {
    loading.value = false;
  }
};

// restoring deleted advert

const restoreDeletedAdvert = async (advertId: string) => {
  console.log("Advert Id", advertId);
  try {
    loading.value = true;
    // Create a FormData object
    const formData = new FormData();
    formData.append("method", "restore_advert");
    formData.append("advert_id", advertId);
    const response = await axios.post("/adverts", formData, {
      headers: {
        Authorization: `Bearer ${token.value}`,
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.data.success) {
      closeDialog();
      emit("fetch-adverts");
      return Swal.fire({
        title: "succesful",
        text: "Your Advert has been restored",
        icon: "success",
        confirmButtonColor: "#662d91",
      });
    } else {
      // Handle error cases
      const errorMessage = response.data.errors || "Failed to restore advert.";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#3085d6",
    });
  } finally {
    loading.value = false;
  }
};
</script>
  
  <style scoped>
.base-adverts-table {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}
.advert-video,
.advert-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.material-symbols-outlined {
  cursor: pointer;
  font-size: 2rem;
}
</style>
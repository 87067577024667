<template>
  <el-dialog
    :model-value="isAnnouncementDetailsDialogVisible"
    :title="announcement.title"
    :width="dialogWidth"
    class="dialog-wrapper"
    :style="{
      paddingBottom: '20px',
      boxShadow: '0 20px 20px rgba(0, 0, 0, 0.1)',
    }"
    :show-close="false"
    @close="closeDialog"
    @open="viewAnnouncementDetails"
  >
    <div v-loading="loading">
      <el-row>
        <el-col :span="24">
          <p v-if="!isBodyTruncated" class="paragraph-large">
            {{ announcement.body }}
          </p>
          <p v-else class="paragraph-large">{{ truncatedBody }}</p>
          <hr />
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" class="button-container">
          <el-button type="text" @click="toggleBody">
            {{ isBodyTruncated ? " Read Full" : "Read Less" }}
          </el-button>
        </el-col>
      </el-row>
    </div>
  </el-dialog>
</template>

<script lang="ts" setup>
import {
  ref,
  defineProps,
  defineEmits,
  computed,
  onMounted,
  onUnmounted,
} from "vue";
import axios from "axios";
import { useAuthStore } from "../../../store/authStore";
import AnnouncementDetails from "../../../types/AnnouncementDetails";
import Swal from "sweetalert2";

// declaring the token
const token = computed(() => {
  return useAuthStore().token;
});

//refs
const isBodyTruncated = ref(false);
const truncatedBody = ref("");
const loading = ref(false);

const windowWidth = ref(window.innerWidth);

// Computed property for responsive dialog width
const dialogWidth = computed(() => {
  const width = windowWidth.value <= 480 ? "90%" : "500px";
  const maxWidth = "600px"; // Maximum width for large screens
  return `min(${width}, ${maxWidth})`;
});

function handleResize() {
  windowWidth.value = window.innerWidth; // Update the reactive windowWidth
}
// Watch for window resize events
onMounted(() => {
  window.addEventListener("resize", handleResize);
  handleResize();
});

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});

// declaring the announcement object amd give it a type of AnnouncementDetails
const announcement = ref<AnnouncementDetails>({
  id: 0,
  title: "",
  body: "",
  user_id: 0,
  created_at: "",
  updated_at: "",
});

// Initializing truncatedBody ref based on body length
const truncateBody = (text: string, maxLength: number) => {
  return (
    text.split(" ").slice(0, maxLength).join(" ") +
    (text.split(" ").length > maxLength ? "..." : "")
  );
};

const toggleBody = () => {
  isBodyTruncated.value = !isBodyTruncated.value;
  if (!isBodyTruncated.value) {
    truncatedBody.value = truncateBody(announcement.value.body, 15);
  }
};

console.log("Initial isBodyTruncated:", isBodyTruncated.value);

// Define props

const props = defineProps<{
  isAnnouncementDetailsDialogVisible: boolean;
  announcementId: string;
}>();

// defining the emit
const emit = defineEmits<{
  (e: "close-dialog"): void;
}>();

const closeDialog = () => {
  emit("close-dialog");
};

// method for Viewing the announcement details
const viewAnnouncementDetails = async () => {
  try {
    loading.value = true;
    const formData = new FormData();
    formData.append("method", "announcement_details");
    formData.append("announcement_id", props.announcementId);

    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/services",
      formData,
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );

    if (response.data.success) {
      const result = response.data.announcement;
      // populating the date from the response in the api to announcement
      announcement.value = {
        id: result.id,
        title: result.title,
        body: result.body,
        user_id: result.user_id,
        created_at: result.created_at,
        updated_at: result.updated_at,
      };
      // spliting the body into array of words
      isBodyTruncated.value = result.body.split(/\s+/).length > 15;
      truncatedBody.value = truncateBody(result.body, 15);
    } else {
      // Handle other error cases
      const errorMessage =
        response.data.errors || "Failed to load announcement details";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#3085d6",
    });
  } finally {
    loading.value = false;
  }
};
</script>

<style scoped>
.material-symbols-outlined {
  color: blue;
  animation: arrow-move 2s linear infinite;
}

@keyframes arrow-move {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0);
  }
}
.button-container {
  display: flex;
  justify-content: center;
}
.paragraph-large {
  font-size: 18px;
  white-space: pre-line;
}
.dialog-wrapper {
  box-shadow: 0 20px 20px 20px rgba(0, 0, 0, 0.5);
}
</style>

<style>
.el-dialog__title {
  color: #662d91;
  text-align: center;
  font-size: 1.1rem;
}
</style>
<template>
  <el-dialog
    :model-value="isEditUserDialogVisible"
    title="Edit User"
    :width="dialogWidth"
    :style="{ paddingBottom: '20px' }"
    @close="closeDialog"
    @open="handleOpen"
  >
    <el-form v-loading="loading" class="modal-form" label-width="auto">
      <el-form-item label="Username">
        <el-input type="text" v-model="editedUsername" required />
      </el-form-item>
      <el-form-item label="Phone">
        <el-input type="text" v-model="editedPhone" required />
      </el-form-item>
      <el-form-item label="Country">
        <div class="user-input-selector">
          <el-select v-model="editedCountry" :placeholder="editedCountry">
            <el-option
              v-for="country in sortedCountries"
              :key="country.country_code"
              :label="country.country_name"
              :value="country.country_code"
            />
          </el-select>
        </div>
      </el-form-item>

      <div class="mb-2 flex items-center text-sm">
        <div class="roles-btn">
          <el-checkbox v-model="agent" label="Agent" size="large" />
          <el-checkbox v-model="admin" label="Admin" size="large" />
        </div>
      </div>
      <div style="display: flex; justify-content: center; margin-top: auto">
        <el-button @click="saveEditedUser" type="primary" class="modal-btn"
          >Save Changes</el-button
        >
      </div>
    </el-form>
  </el-dialog>
</template>

<script lang="ts" setup>
import axios from "axios";
import {
  ref,
  defineEmits,
  defineProps,
  computed,
  onMounted,
  onUnmounted,
} from "vue";
import Swal from "sweetalert2";
import Country from "../../../types/Country";
import { useAuthStore } from "../../../store/authStore";

const windowWidth = ref(window.innerWidth);

// Computed property for responsive dialog width
const dialogWidth = computed(() => {
  const width = windowWidth.value <= 480 ? "90%" : "3500px";
  const maxWidth = "400px"; // Maximum width for large screens
  return `min(${width}, ${maxWidth})`;
});

function handleResize() {
  windowWidth.value = window.innerWidth; // Update the reactive windowWidth
}
// Watch for window resize events
onMounted(() => {
  window.addEventListener("resize", handleResize);
  handleResize();
});

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});

// declaring token

const token = computed(() => {
  return useAuthStore().token;
});
// props from the parent
const props = defineProps<{
  isEditUserDialogVisible: boolean;
  userId: string;
}>();
// emitting custom events
const emit = defineEmits<{
  (e: "close-dialog"): void;
  (e: "fetch-all-users"): void;
}>();

const closeDialog = () => {
  emit("close-dialog");
};

const editedUsername = ref("");
const editedPhone = ref("");
const editedId = ref("");
const editedCountry = ref("");
const agent = ref(false);
const admin = ref(false);
const loading = ref(false);
const countries = ref<Country[]>([]);

// Single method to openstartEditingUser and fetchCountries to avoid duplication of attribute
const handleOpen = () => {
  startEditingUser();
  fetchCountries();
};

// sorting the  countries in ascending order
const sortedCountries = computed(() => {
  return countries.value
    .slice()
    .sort((a: Country, b: Country) =>
      a.country_name.localeCompare(b.country_name)
    );
});

// this is the method for starting to edit the user
const startEditingUser = async () => {
  try {
    loading.value = true;
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/users",
      {
        method: "user_details",
        user_id: props.userId,
      },
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );
    if (response.data.success) {
      // declaring results to hold the entire data set

      const results = response.data;
      editedUsername.value = results.username;
      editedPhone.value = results.phone;
      editedId.value = results.id;
      editedCountry.value = results.country;
    } else {
      // Handle other error cases
      const errorMessage = response.data.errors || "Failed to fetch data.";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#d33",
    });
  } finally {
    loading.value = false;
  }
};

// Saving the Edited User

const saveEditedUser = async () => {
  try {
    if (
      editedUsername.value === "" ||
      editedPhone.value === "" ||
      editedCountry.value === ""
    ) {
      const result = await Swal.fire({
        title: "Warniing",
        text: "All fields are required",
        icon: "warning",
        confirmButtonColor: "#3085d6",
      });
      if (result.isConfirmed) {
        return "";
      }
    } else {
      const formData = new FormData();
      formData.append("method", "edit_user");
      formData.append("user_id", editedId.value);
      formData.append("username", editedUsername.value);
      formData.append("phone", editedPhone.value);
      formData.append("country", editedCountry.value);
      formData.append("is_agent", agent.value.toString());
      formData.append("admin", admin.value.toString());

      const response = await axios.post(
        // additional to global baseUrl endpoint
        "/users",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token.value}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.success) {
        console.log(response.data);
        closeDialog();

        const result = await Swal.fire({
          title: "Successful",
          text: "User details were updated successfully",
          icon: "success",
          confirmButtonColor: "#3085d6",
        });
        if (result.isConfirmed) {
          editedPhone.value = "";
          editedUsername.value = "";
          emit("fetch-all-users");
        }
      } else {
        // Handle other error cases
        const errorMessage =
          response.data.errors || "Failed to update user details";
        return Swal.fire({
          title: "Error",
          text: errorMessage,
          icon: "error",
          confirmButtonColor: "#d33",
        });
      }
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#d33",
    });
  }
};

// Fetching All countries
const fetchCountries = async () => {
  try {
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/countries",
      { method: "list_country_codes" },
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );
    if (response.data.success) {
      const fetchedCountries = response.data.countries;
      fetchedCountries.sort((a: Country, b: Country) =>
        a.country_name.localeCompare(b.country_name)
      );
      // Set the sorted countries to the countries array
      countries.value = fetchedCountries;
    } else {
      // Handle other error cases
      const errorMessage = response.data.errors || "Failed to fetch countries";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#d33",
    });
  }
};
</script>

<style scoped>
.roles-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-btn {
  margin-top: 0.5rem;
  background-color: #662d91;
  padding: 1.2rem;
  border: none;
}

.modal-btn:hover {
  background-color: transparent;
  color: #662d91;
  border: 1px solid #662d91;
}
.user-input-selector {
  width: 100%;
}
</style>
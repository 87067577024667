<template>
  <div>
    <div class="box"></div>
  </div>
</template>

<script lang="ts" setup>
</script>

<style scoped>
.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
</style>
<template>
  <section class="starts">
    <article class="text-left">
      <div class="statistics" v-loading="loading">
        <div class="headers-content">
          <span>
            <p class="sales-info">{{ salesInfo }}</p>
          </span>
          <div class="time-buttons">
            <button
              :class="{ 'active-btn': interval === 'week' }"
              @click="setInterval('week')"
            >
              Week
            </button>
            <button
              :class="{ 'active-btn': interval === 'month' }"
              @click="setInterval('month')"
            >
              Month
            </button>
            <button
              :class="{ 'active-btn': interval === 'year' }"
              @click="setInterval('year')"
            >
              Year
            </button>
          </div>
        </div>
        <div id="chart">
          <VueApexCharts
            width="100%"
            :key="chartKey"
            style="pointer-events: none !important"
            type="area"
            height="320px"
            :options="options"
            :series="series"
          />
        </div>
      </div>
    </article>
  </section>
</template>

<script lang="ts" setup>
import axios from "axios";
import { ref, onMounted, computed, defineEmits } from "vue";
import { useAuthStore } from "../store/authStore";
import VueApexCharts from "vue3-apexcharts";
import Swal from "sweetalert2";

// const totalUsers = ref("");
const interval = ref("week");
const chartKey = ref(0);
const loading = ref(false);
// toke
const token = computed(() => useAuthStore().token);

const options = ref({
  dataLabels: { enabled: false },
  tooltip: { enabled: false },
  zoom: { enabled: false },
  chart: {
    id: "realtime",
    height: 450,
    type: "line",
    animations: {
      enabled: true,
      easing: "linear",
      dynamicAnimation: {
        speed: 1000,
      },
    },
    toolbar: { show: false },
    zoom: { enabled: false },
  },
  stroke: { curve: "smooth" },
  markers: { size: 0 },
  yaxis: { min: 0 },
  xaxis: {
    categories: [],
    min: 1,
  },
});

const series = ref([
  {
    name: "series-1",
    data: [] as [],
  },
]);

// dynamic rendering of the graph info

const salesInfo = computed(() => {
  if (interval.value === "week") {
    return "Sales in Last 7 Days";
  } else if (interval.value === "month") {
    const currentMonth = new Date().toLocaleString("default", {
      month: "long",
    });
    return `Monthly Sales in ${currentMonth}`;
  } else if (interval.value === "year") {
    const currentYear = new Date().getFullYear();
    return `Yearly Sales in ${currentYear}`;
  }
  return "";
});

// SETTING THE SWICTH FOR THE INTERVAL , WEEK, MONTH AND YEAR
const setInterval = async (newInterval: string) => {
  interval.value = newInterval;
  switch (newInterval) {
    case "week":
      setWeeklyInterval();
      break;

    case "month":
      setMonthlyInterval();
      break;
    case "year":
      setYearlyInterval();
      break;
    default:
      break;
  }
  // Updating a dummy variable to trigger reactivity
  chartKey.value += 1;
};

// weekly function which is triggered whenever the button of week is clicked
const setWeeklyInterval = async () => {
  try {
    loading.value = true;
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/payments",
      { method: "stats", interval: "week" },
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );

    if (response.data.success) {
      const weeklySales: Record<string, number> =
        response.data.result.weekly_sales;
      // Extracting data for the y-axis from the response
      const weeklySalesData = Object.values(weeklySales);
      // Extracting categories for the x-axis from the response
      const labels = Object.keys(weeklySales);
      // Updating options and series with fetched data
      options.value.xaxis.categories = labels as never[];
      series.value[0].data = weeklySalesData as [];
      // Setting a custom minimum value for the x-axis,  not to start at 0,0
      options.value.xaxis.min = 0.6;
      // Increment chart key to trigger reactivity
      chartKey.value += 1;
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#d33",
    });
  } finally {
    loading.value = false;
  }
};

// Monthly data from the server
const setMonthlyInterval = async () => {
  try {
    loading.value = true;
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/payments",
      {
        method: "stats",
        interval: "month",
      },
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );
    if (response.data.success) {
      const monthlySales = response.data.result.monthly_sales;
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();
      const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
      const dateFormat = new Intl.DateTimeFormat("en-US", {
        // this will get the current month with date for example (01-Mar)
        month: "short",
        // this will bring a full year 2024 for example
        year: "numeric",
      });

      // Initializing arrays to hold data for x-axis categories and monthly sales
      const labels = [];
      const monthlySalesData = [];

      for (let i = 1; i <= daysInMonth; i++) {
        const date = new Date(currentYear, currentMonth, i);
        const formattedMonthYear = dateFormat.format(date);
        // to put into this fomart (foexample for march 2024, we need 01-Mar-2024)
        const formattedDate = `${
          i < 10 ? "0" : ""
        }${i}-${formattedMonthYear.replace(" ", "-")}`;
        const sales = monthlySales[formattedDate] || 0;
        labels.push(formattedDate);
        monthlySalesData.push(sales.toFixed(2));
      }
      // Setting x-axis categories and monthly sales data
      options.value.xaxis.categories = labels as never[];
      series.value[0].data = monthlySalesData as [];
      // Setting a custom minimum value for the x-axis
      options.value.xaxis.min = 0.2;
      // Incrementing chart key to trigger reactivity
      chartKey.value += 1;
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#d33",
    });
  } finally {
    loading.value = false;
  }
};

// YEARLY DATA FROM THE SERVER
const setYearlyInterval = async () => {
  try {
    loading.value = true;
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/payments",
      { method: "stats", interval: "year" },
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );

    if (response.data.success) {
      const yearlySales = response.data.result.yearly_sales;
      // Getting the current year
      const currentYear = new Date().getFullYear();
      // Initializing arrays to hold data for x-axis categories and yearly sales
      const labels = [];
      const yearlySalesData = [];
      // Looping through each month from January to December
      for (let month = 0; month < 12; month++) {
        const monthLabel = new Date(currentYear, month).toLocaleString(
          "default",
          { month: "short" }
        ); // Getting month abbreviation
        const fullLabel = `${monthLabel}-${currentYear}`;
        labels.push(fullLabel);

        // Checking if data exists for the current month, if not, set it to 0
        const monthlySales = yearlySales[`${monthLabel}-${currentYear}`] || 0;
        yearlySalesData.push(monthlySales.toFixed(2));
      }
      // Updating options and series with fetched data
      options.value.xaxis.categories = labels as never[];
      series.value[0].data = yearlySalesData as [];
      // Setting a custom minimum value for the x-axis
      options.value.xaxis.min = 0.2;
      // Incrementing chart key to trigger reactivity
      chartKey.value += 1;
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#d33",
    });
  } finally {
    loading.value = false;
  }
};

// Defining custom events to emit
const emits = defineEmits(["loaded"]);

// Emiting events when data is loaded
const emitLoadedEvent = () => {
  emits("loaded");
};

onMounted(() => {
  setWeeklyInterval();
  emitLoadedEvent();
});
</script>

<style scoped>
.statistics {
  border-radius: 10px;
  margin-top: 20px;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px 15px;
  width: 100%;
  max-width: 610px;
  margin: auto;
  display: flex;
  flex-direction: column; /* Ensure vertical stacking */
}

.headers-content {
  flex: 1; /* Take up space above the chart */
}

.statistics h2 {
  font-size: 1.2em;
  font-weight: 600;
  margin-bottom: 30px;
}

.time-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-bottom: 1rem;
}

.time-buttons button {
  border: none;
  padding: 5px 15px;
  background-color: transparent;
  color: #007bff;
  font-size: 0.8em;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.active-btn {
  background-color: #007bff;
  color: white;
  border-radius: 5px;
}

#chart {
  margin-top: 20px;
  width: 100%;
}
</style>

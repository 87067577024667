<template>
  <div class="manage-users-container">
    <div class="home-user">
      <span class="material-symbols-outlined" @click="goHome()">
        grid_view
      </span>
      <div class="right-arrow-h4">
        <el-button class="right-arrow" :icon="arrowIcon" circle></el-button>
        <h4>Users</h4>
      </div>
    </div>
    <h3>Users</h3>
    <div class="inputs-btn">
      <section class="inputs-section">
        <el-input
          v-model="searchInput"
          type="text"
          class="custom-input"
          style="width: 200px"
          :placeholder="placeholder"
          @input="onSearch"
        />
        <el-select
          v-model="searchFilter"
          placeholder="Search By Username"
          class="custom-select"
          style="width: 200px; height: 2rem"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </section>
      <div class="adduser-csv-btns">
        <el-button @click="addUser" class="add-user-btn" type="primary"
          >Add User</el-button
        >
        <el-button @click="downloadCsv" class="download-csv-btn" type="primary"
          >Download CSV</el-button
        >
      </div>
    </div>

    <!-- adding a user dialog  -->

    <div class="users-table">
      <el-table v-loading="loading" :data="rows" border>
        <el-table-column label="#">
          <template v-slot="{ $index }">
            {{ (currentPage - 1) * pageSize + ($index + 1) }}
          </template>
        </el-table-column>
        <el-table-column label="Username">
          <template v-slot="{ row }">{{ row.username }}</template>
        </el-table-column>
        <el-table-column label="Phone">
          <template v-slot="{ row }">{{ row.phone }}</template>
        </el-table-column>
        <el-table-column label="User Id">
          <template v-slot="{ row }">{{ row.id }}</template>
        </el-table-column>
        <el-table-column label="Country">
          <template v-slot="{ row }">{{ row.country }}</template>
        </el-table-column>
        <el-table-column label="Role(s)">
          <template v-slot="{ row }">
            {{
              row.admin && row.is_agent
                ? "Admin, Agent"
                : row.admin
                ? "Admin"
                : row.is_agent
                ? "Agent"
                : "Customer"
            }}
          </template>
        </el-table-column>
        <!-- <el-table-column label="Actions">
          <template v-slot="{ row }">
            <div class="action-btns-wrapper">
              <el-button
                v-if="isSmallScreen"
                class="toggle-btn"
                @click="toggleActions"
                type="primary"
                circle
                :icon="moreIcon"
              />
              <div v-show="!isSmallScreen || showActions" class="action-btns">
                <el-tooltip content="edit user" placement="top">
                  <el-button
                    class="edit-btn"
                    @click="editUserDetails(row.id)"
                    type="primary"
                    :icon="Edit"
                    circle
                  />
                </el-tooltip>
                <el-tooltip content="User Details" placement="top">
                  <el-button
                    type="primary"
                    :icon="detailsIcon"
                    circle
                    @click="allUserDetails(row.id)"
                  ></el-button>
                </el-tooltip>
                <el-tooltip content="Topup commissions" placement="top">
                  <span
                    @click="topupCommissions(row.id)"
                    class="material-symbols-outlined"
                    style="
                      color: white;
                      background-color: #008000;
                      border-radius: 50%;
                      padding: 5px;
                      display: inline-block;
                      cursor: pointer;
                      margin: 0.5rem;
                    "
                  >
                    payments
                  </span>
                </el-tooltip>
              </div>
            </div>
          </template>
        </el-table-column> -->

        <el-table-column label="Actions">
          <template v-slot="{ row }">
            <div class="action-buttons">
              <div class="action-btns-2">
                <el-row class="block-col-2">
                  <el-col :span="8">
                    <el-dropdown>
                      <span class="el-dropdown-link">
                        <el-button
                          type="primary"
                          :icon="moreIcon"
                          circle
                        ></el-button>
                      </span>

                      <template #dropdown>
                        <el-dropdown-menu class="custom-dropdown-menu">
                          <el-dropdown-item
                            @click="allUserDetails(row.id)"
                            class="dropdown-content"
                          >
                            <span class="material-symbols-outlined">
                              info
                            </span>
                            <span class="text">Users Details</span>
                          </el-dropdown-item>
                          <el-dropdown-item
                            @click="editUserDetails(row.id)"
                            class="dropdown-content"
                          >
                            <span class="material-symbols-outlined">
                              edit_square
                            </span>
                            <span class="text">Edit User Details </span>
                          </el-dropdown-item>
                          <el-dropdown-item
                            @click="topupCommissions(row.id)"
                            class="dropdown-content"
                          >
                            <span class="material-symbols-outlined">
                              info
                            </span>
                            <span class="text">Topup Commissions</span>
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div>
        <el-pagination
          class="pagination"
          :current-page="currentPage"
          :small="small"
          :page-size="pageSize"
          :total="total"
          :disabled="disabled"
          :background="background"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          layout="total, sizes, prev, pager, next, jumper"
        />
      </div>
    </div>

    <!-- editing the user -->

    <!-- details dialog -->

    <UserDetailsDialog
      :is-user-details-dialog-visible="dialogDetailsVisible"
      :user-id="userId"
      @close-dialog="closeUserDetails"
      @fetch-all-users="fetchAllUsers"
    />

    <EditUserDialog
      :is-edit-user-dialog-visible="editUserDialogVisible"
      :user-id="userId"
      @close-dialog="closeUserEdit"
      @fetch-all-users="fetchAllUsers"
    />
    <AddUserDialog
      :is-add-user-dialog-visible="addUserDialogVisible"
      @close-dialog="closeUserAddDialog"
      @fetch-all-users="fetchAllUsers"
    />
    <TopupUserCommissions
      :is-topup-commissions-dialog-visible="topupCommissionsDialogVisible"
      @close-dialog="closeTopupCommissionsDialog"
      :user-id="userId"
      @fetch-all-users="fetchAllUsers"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed, onUnmounted } from "vue";
import axios from "axios";
import User from "../../types/User";
import Swal from "sweetalert2";
import Country from "../../types/Country";
import debounce from "debounce";
import { useRouter } from "vue-router";
import { ArrowRight } from "@element-plus/icons-vue";
import { Open, More } from "@element-plus/icons-vue";
import UserDetailsDialog from "../../components/dialogs/users/user-details-dialog.vue";
import EditUserDialog from "../../components/dialogs/users/edit-user-dialog.vue";
import AddUserDialog from "../../components/dialogs/users/add-user-dialog.vue";
import TopupUserCommissions from "../../components/dialogs/users/topup-user-commissions-dialog.vue";
import { useAuthStore } from "../../store/authStore";

// import countries from "countries-list";
import { Edit } from "@element-plus/icons-vue";

const isSmallScreen = ref(false);
const showActions = ref(false);

function toggleActions() {
  showActions.value = !showActions.value;
  console.log("clicked");
}
function handleResize() {
  isSmallScreen.value = window.innerWidth <= 768; // Adjust this breakpoint as needed
}

onMounted(() => {
  window.addEventListener("resize", handleResize);
  handleResize();
});

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});

const dialogDetailsVisible = ref(false);
const editUserDialogVisible = ref(false);
const addUserDialogVisible = ref(false);
const topupCommissionsDialogVisible = ref(false);
const currentPage = ref(1);
const pageSize = ref(10);
const small = ref(false);
const background = ref(false);
const disabled = ref(false);
const total = ref(0);
// const page = ref(1);
const userId = ref("");
// we are creating an empty array of User objects
const rows = ref<User[]>([]);
const countries = ref<Country[]>([]);

const searchInput = ref("");
const searchFilter = ref("username");

const loading = ref(false);

const router = useRouter();

const options = ref([
  { label: "Search By Username", value: "username" },
  { label: "Search By phone", value: "phone" },
  { label: "Search By country", value: "country" },
]);

const arrowIcon = ArrowRight;
const moreIcon = More;
const detailsIcon = Open;

// token
const token = computed(() => {
  return useAuthStore().token;
});

// opening the user details dialog
const allUserDetails = (id: string) => {
  userId.value = id;
  dialogDetailsVisible.value = true;
};
const topupCommissions = (id: string) => {
  userId.value = id;
  topupCommissionsDialogVisible.value = true;
};
// opening the user edit details dialog
const editUserDetails = (id: string) => {
  userId.value = id;
  editUserDialogVisible.value = true;
};
// opening the user add details dialog
const addUser = () => {
  addUserDialogVisible.value = true;
};
// const topupCommissions = () => {
//   topupCommissionsDialogVisible.value = true;
// };

// closing the user details dialog
const closeUserDetails = () => {
  dialogDetailsVisible.value = false;
};
// closing the user edit details dialog
const closeUserEdit = () => {
  editUserDialogVisible.value = false;
};

// closing the user add  dialog
const closeUserAddDialog = () => {
  addUserDialogVisible.value = false;
};
const closeTopupCommissionsDialog = () => {
  topupCommissionsDialogVisible.value = false;
};

// dynamic placeholder
const placeholder = computed((): string => {
  if (searchFilter.value === "username") return "Search by username";
  else if (searchFilter.value === "phone") return "Search by phone";
  else return "Search by Country";
});

// redirecting to home
const goHome = () => {
  router.push("/dashboard");
};

// fatching all user
const fetchAllUsers = async () => {
  try {
    loading.value = true;
    rows.value = [];
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/users",
      {
        method: "get_users",
        page: currentPage.value,
        username: searchFilter.value === "username" ? searchInput.value : "",
        phone: searchFilter.value === "phone" ? +searchInput.value : null,
        country: searchFilter.value === "country" ? searchInput.value : "",
      },
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );
    if (response.data.success) {
      // populating the data from the server into rows
      rows.value = response.data.result;
      total.value = parseInt(response.data.total_users);
    } else {
      // Handle other error cases
      const errorMessage = response.data.errors || "Failed to fetch users";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#3085d6",
    });
  } finally {
    loading.value = false;
  }
};

// handling the current change
const handleCurrentChange = async (val: number) => {
  currentPage.value = val;
  await fetchAllUsers();
};

// hnadling the size
const handleSizeChange = async (val: number) => {
  pageSize.value = val;
  currentPage.value = 1;
  await fetchAllUsers();
};

//downlading CVS (comma, separated value)
const downloadCsv = async () => {
  try {
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/users",
      {
        method: "download_users",
      },
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
        responseType: "text", // Setting response type to plain text
      }
    );

    if (response.status === 200) {
      const csvData = response.data;

      // Creating a Blob object containing the CSV content
      const blob = new Blob([csvData], {
        type: "text/csv;charset=utf-8;",
      });

      // Creating a temporary anchor element
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", "users.csv");

      // Triggering the click event on the anchor element to initiate the download
      document.body.appendChild(link);
      link.click();

      // Removing the temporary anchor element
      document.body.removeChild(link);
    } else {
      // Handle other error cases
      const errorMessage = response.data.errors || "Failed to fetch users data";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#3085d6",
    });
  }
};

// search library
const onSearch = debounce(function () {
  fetchAllUsers();
}, 500);

onMounted(() => {
  fetchAllUsers();
});
</script>

<style scoped>
.manage-users-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 95%;
}
.home-user {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.right-arrow {
  background-color: transparent;
  border: none;
}
h4 {
  font-size: 1rem;
  font-weight: 300;
  margin-top: 0.5rem;
}

.right-arrow-h4 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.material-symbols-outlined {
  font-size: 1.2rem;
  margin-left: 0;
}
.material-symbols-outlined:hover {
  cursor: pointer;
}
h3 {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 2rem 0 3rem 0;
  color: #662d91;
}
button {
  padding: 8px 12px;
  background-color: #662d91;
  color: white;
  margin: 1rem;
  cursor: pointer;
  border-radius: 5px;
  border: none;
}

.inputs-btn {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  align-items: center;
  margin: 0.5rem 0;
}
.inputs-section {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}
.adduser-csv-btns {
  display: flex;
  gap: 0.5rem;
}

.users-table {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}
.el-table {
  overflow-x: auto;
}
.action-btns-wrapper {
  position: relative;
}
.profile-image {
  border-radius: 50%;
  height: 4.5rem;
  width: 4.5rem;
}

.custom-input .el-input {
  height: 60px;
}

.custom-select .el-select {
  height: 60px;
}
.demo-pagination-block + .demo-pagination-block {
  margin-top: 10px;
}
.demo-pagination-block .demonstration {
  margin-bottom: 16px;
}
.edit-btn {
  background-color: #662d91;
  border: none;
  color: #fff;
}

/* styling the modal */
hr {
  border: 2px solid black;
}
.add-user-btn,
.download-csv-btn {
  padding: 17px 20px;
  margin: 5px;
  font-size: 12px;
  border: none;
  margin: 0;
  background-color: #662d91;
}

.add-user-btn:hover,
.download-csv-btn:hover {
  border: 1px solid #662d91;
  background-color: transparent;
  color: #662d91;
}
.action-btns {
  display: flex;
  justify-content: center;
  align-items: center;
}
.toggle-btn {
  display: none;
}
/* Responsive adjustments for larger screens */
@media (max-width: 768px) {
  .inputs-btn {
    flex-direction: column;
    align-items: flex-start;
  }

  .inputs-section {
    width: 100%;
    margin-bottom: 1rem;
  }

  .custom-input,
  .custom-select {
    width: 100%;
    max-width: none; /* Remove max-width to allow full width */
  }

  .adduser-csv-btns {
    width: 100%;
    justify-content: flex-start;
  }

  .add-user-btn,
  .download-csv-btn {
    width: auto;
    min-width: 120px;
    margin-bottom: 1rem;
  }
  .pagination {
    flex-direction: row; /* Maintain row layout */
    flex-wrap: wrap; /* Allow wrapping to fit pagination */
    align-items: center; /* Center items vertically */
    font-size: 0.875rem; /* Slightly smaller font size for better fit */
  }

  .pagination .el-pagination {
    width: auto; /* Let pagination take up necessary space */
  }

  .pagination .el-pagination .el-pagination__pager {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center pagination buttons */
  }

  .pagination .el-pagination .el-pagination__pager button {
    margin: 0.2rem; /* Add margin between buttons */
  }
  .action-btns {
    display: none;
  }

  .toggle-btn {
    display: inline-block;
  }
}

/* Responsive adjustments for screens smaller than 468px */
@media (max-width: 468px) {
  .inputs-section {
    flex-direction: column;
  }
  .action-btns {
    display: none;
  }

  .toggle-btn {
    display: inline-block;
  }

  .custom-input,
  .custom-select {
    width: 100%;
    margin-bottom: 1rem; /* Add spacing between input and select */
  }

  .adduser-csv-btns {
    flex-direction: column;
    width: 100%;
    gap: 0.5rem; /* Add smaller gap for buttons */
  }

  .add-user-btn,
  .download-csv-btn {
    width: 100%; /* Full width for smaller screens */
    min-width: unset; /* Remove minimum width */
    padding: 10px; /* Adjust padding */
    font-size: 14px; /* Adjust font size */
  }
  .pagination {
    font-size: 0.75rem; /* Smaller font size for very small screens */
    padding: 0.5rem; /* Reduce padding around pagination */
  }

  .pagination .el-pagination {
    width: auto; /* Ensure full-width usage */
  }

  .pagination .el-pagination .el-pagination__pager {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center pagination buttons */
  }

  .pagination .el-pagination .el-pagination__pager button {
    margin: 0.1rem; /* Reduce margin between buttons */
    padding: 0.3rem; /* Smaller padding for buttons */
  }
}
</style>

import { createApp } from "vue";
import App from "./App.vue";
import { createPinia } from "pinia";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import { useAuthStore } from "./store/authStore";
import router from "./router";
import axios from "axios";

const app = createApp(App);
const pinia = createPinia();
app.use(pinia);

// registering the global endpoint
axios.defaults.baseURL = process.env.VUE_APP_CATCHCHAT_LOCAL_API;

// initialising authStore
const authStore = useAuthStore();

// Initializing authentication state
authStore.initializeAuthState();

// Navigation guard
router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !authStore.isAuthenticated) {
    // If the route requires authentication and the user is not logged in, redirect to the login page
    next("/login");
  } else if (to.meta.requiresAdmin && !authStore.isAdmin) {
    // Redirecting to dashboard if not admin
    next("/dashboard");
  } else if (to.meta.requiresAgent && !authStore.isAgent) {
    // Redirecting to dashboard if not agent
    next("/dashboard");
  } else {
    // Otherwise, proceed to the next route
    next();
  }
});

app.use(ElementPlus);
app.use(router);
app.mount("#app");

<template>
  <div class="announcements-container">
    <div class="home-announcements">
      <span class="material-symbols-outlined" @click="goHome()">
        grid_view
      </span>
      <div class="right-arrow-h4">
        <el-button class="right-arrow" :icon="arrowIcon" circle></el-button>
        <h4>Announcements</h4>
      </div>
    </div>
    <h3>Announcements</h3>
    <div class="button-container">
      <el-button
        @click="createAnnouncement"
        class="add-announcements-btn"
        type="primary"
        >Create Announcement</el-button
      >
    </div>
    <div class="announcements-table">
      <el-table v-loading="loading" :data="rows" border>
        <el-table-column label="#" width="90">
          <template v-slot="{ $index }">
            {{ (currentPage - 1) * pageSize + ($index + 1) }}
          </template>
        </el-table-column>
        <el-table-column label="Id">
          <template v-slot="{ row }">{{ row.id }}</template>
        </el-table-column>
        <el-table-column label="Title">
          <template v-slot="{ row }">
            <!-- Use el-tooltip for the tooltip functionality -->
            <el-tooltip :content="row.title" placement="top" effect="dark">
              <!-- The truncated description goes inside the tooltip -->
              {{ truncateTitle(row.title) }}
            </el-tooltip>
          </template>
        </el-table-column>

        <el-table-column label="Description" width="200">
          <template v-slot="{ row }">
            <!-- The truncated description goes inside the tooltip -->
            {{ truncateDescription(row.body) }}
          </template>
        </el-table-column>
        <el-table-column label="Created At">
          <template v-slot="{ row }">{{
            row.last_created_at
              ? formatTimestamp(row.created_at)
              : getCurrentTime()
          }}</template>
        </el-table-column>
        <el-table-column label="Updated At">
          <template v-slot="{ row }">{{
            row.last_created_at
              ? formatTimestamp(row.updated_at)
              : getCurrentTime()
          }}</template>
        </el-table-column>
        <el-table-column label="Actions">
          <template v-slot="{ row }">
            <div class="action-btns-2">
              <el-tooltip content="Delete announcement" placement="top">
                <el-button
                  @click="deleteAnnouncement(row.id)"
                  type="danger"
                  :icon="Delete"
                  class="delete-btn"
                  circle
                />
              </el-tooltip>
              <el-tooltip content="Announcement Details" placement="top">
                <el-button
                  @click="announcementDetails(row.id)"
                  type="success"
                  :icon="statusIcon"
                  class="details-btn"
                  circle
                />
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div>
        <el-pagination
          class="pagination"
          :current-page="currentPage"
          :small="small"
          :page-size="pageSize"
          :total="total"
          :disabled="disabled"
          :background="background"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          layout="total, sizes, prev, pager, next, jumper"
        />
      </div>
    </div>
    <CreateAnnouncementDialog
      :is-create-announcement-dialog-visible="addAnnouncementDialogVisible"
      @close-dialog="closeAnnouncementAddDialog"
      @fetch-all-announcements="fetchAllAnnouncements"
      @new-announcement="handleNewAnnouncement"
    />
    <AnnouncementDetailsDialog
      :is-announcement-details-dialog-visible="dialogDetailsVisible"
      :announcement-id="announcementId"
      @close-dialog="closeAnnouncementDetailsDialog"
    />
  </div>
</template>
  
  <script lang="ts" setup>
import { ref, computed, onMounted } from "vue";
import axios from "axios";
import Swal from "sweetalert2";
import { useAuthStore } from "../../store/authStore";
import { useRouter } from "vue-router";
import { ArrowRight } from "@element-plus/icons-vue";
import { View } from "@element-plus/icons-vue";
import { format } from "date-fns";
import Notification from "../../types/Notification";
import CreateAnnouncementDialog from "../../components/dialogs/announcements/creating-announcements-dialog.vue";
import AnnouncementDetailsDialog from "../../components/dialogs/announcements/announcement-details-dialog.vue";
import { Delete } from "@element-plus/icons-vue";

// refs
const loading = ref(false);
const rows = ref<Notification[]>([]);
const currentPage = ref(1);
const pageSize = ref(10);
const small = ref(false);
const background = ref(false);
const disabled = ref(false);
const total = ref(0);
const addAnnouncementDialogVisible = ref(false);
const dialogDetailsVisible = ref(false);
const announcementId = ref("");
const arrowIcon = ArrowRight;
const statusIcon = View;

// token
const token = computed(() => useAuthStore().token);

const handleNewAnnouncement = (announcement: Notification) => {
  // Prepending the new announcement to the rows array
  rows.value.unshift(announcement);
};

// opening the announcement details dialog
const announcementDetails = (id: string) => {
  dialogDetailsVisible.value = true;
  announcementId.value = id;
};
// closing announcement dialog
const closeAnnouncementDetailsDialog = () => {
  dialogDetailsVisible.value = false;
};

// redirecting to home

const router = useRouter();

const goHome = () => {
  router.push("/dashboard");
};

const formatTimestamp = (timestamp: string) => {
  if (!timestamp) return ""; // Handling case where timestamp is null or undefined
  return format(new Date(timestamp), "yyyy-MM-dd HH:mm:ss");
};

// getting current time
const getCurrentTime = () => {
  const currentTime = new Date();
  const formattedTime = `${currentTime.toLocaleDateString()} ${currentTime.toLocaleTimeString()}`;
  return formattedTime;
};

// trancating the title
const truncateTitle = (title: string) => {
  const words = title.split(" ");
  if (words.length > 5) {
    return words.slice(0, 5).join(" ") + "...";
  } else {
    return title;
  }
};
// trancating the description
const truncateDescription = (description: string) => {
  const words = description.split(" ");
  if (words.length > 5) {
    return words.slice(0, 5).join(" ") + "...";
  } else {
    return description;
  }
};

// opening creating announcement dialog
const createAnnouncement = () => {
  addAnnouncementDialogVisible.value = true;
};

// closing announcement dialog
const closeAnnouncementAddDialog = () => {
  addAnnouncementDialogVisible.value = false;
};

// hnadling the current chnage on the table
const handleCurrentChange = async (val: number) => {
  currentPage.value = val;
  await fetchAllAnnouncements();
};

// handling the currenyt==t size of the table
const handleSizeChange = async (val: number) => {
  pageSize.value = val;
  currentPage.value = 1;
  await fetchAllAnnouncements();
};

// fetching all the announcements
const fetchAllAnnouncements = async () => {
  try {
    loading.value = true;
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/services",
      {
        method: "get_all_announcements",
        page: currentPage.value,
      },
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );
    if (response.data.success) {
      // Mapping the result array to extract titles from each item
      rows.value = response.data.result.map((item: Notification) => {
        return {
          id: item.id,
          title: item.title,
          body: item.body,
          user_id: item.user_id,
          created_at: item.created_at,
          updated_at: item.updated_at,
        };
      });
      total.value = response.data.result.length;
    } else {
      // Handle other error cases
      const errorMessage =
        response.data.errors || "Failed to fetch announcements";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#3085d6",
    });
  } finally {
    loading.value = false;
  }
};

// deleting the announcement
const deleteAnnouncement = async (announcementId: string) => {
  try {
    const result = await Swal.fire({
      title: "Dangerous",
      text: "Are you sure you want to delete this announcement? Content will be deleted permanently",
      icon: "warning",
      confirmButtonColor: "#662d91",
      showCancelButton: true,
    });
    if (result.isConfirmed) {
      const response = await axios.post(
        // additional to global baseUrl endpoint
        "/services",
        {
          method: "delete_announcement",
          announcement_id: announcementId,
        },
        {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        }
      );

      if (response.data.success) {
        fetchAllAnnouncements();
        return Swal.fire({
          title: "Successful",
          text: "Announcement has been deleted permanently",
          icon: "success",
          confirmButtonColor: "#3085d6",
        });
      } else {
        // Handle other error cases
        const errorMessage =
          response.data.errors || "Failed to delete announcement";
        return Swal.fire({
          title: "Error",
          text: errorMessage,
          icon: "error",
          confirmButtonColor: "#d33",
        });
      }
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#3085d6",
    });
  }
};

onMounted(() => {
  fetchAllAnnouncements();
});
</script>
  
  <style scoped>
.announcements-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 95%;
}
.add-announcements-btn {
  margin-top: 0.5rem;
  background-color: #662d91;
  padding: 15px 2rem;
  border: none;
  margin: 0.5rem 0;
}

.add-announcements-btn:hover {
  border: 1px solid #662d91;
  background-color: transparent;
  color: #662d91;
}

.button-container {
  display: flex;
  justify-content: flex-end;
}
h3 {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 2rem 0 3rem 0;
  color: #662d91;
}
.pagination {
  margin: 1rem auto;
  overflow-x: auto;
}
.announcements-table {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}
.el-table {
  overflow-x: auto;
}
.home-announcements {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0;
}
.right-arrow-h4 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.material-symbols-outlined {
  font-size: 1.2rem;
  margin-left: 0;
}
.material-symbols-outlined:hover {
  cursor: pointer;
}

.right-arrow {
  background-color: transparent;
  border: none;
}
h4 {
  font-size: 1rem;
  font-weight: 300;
  margin-top: 0.5rem;
}

.delete-btn {
  background-color: red;
}

.action-btns-2 {
  display: flex;
}
.delete-btn,
.details-btn {
  /* Add common button styles if needed */
  font-size: 1rem;
  width: 40px;
  height: 40px;
}
/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .pagination {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    font-size: 0.875rem;
  }

  .pagination .el-pagination {
    width: auto;
  }

  .pagination .el-pagination .el-pagination__pager {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .pagination .el-pagination .el-pagination__pager button {
    margin: 0.2rem;
  }
  .action-btns-2 {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    height: 100%;
  }
  .details-btn {
    margin-right: 0.7rem;
  }
}

/* Responsive styles for extra-small screens */
@media (max-width: 468px) {
  .pagination {
    font-size: 0.75rem;
    padding: 0.5rem;
  }

  .pagination .el-pagination {
    width: auto; /* Ensure full-width usage */
  }

  .pagination .el-pagination .el-pagination__pager {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .pagination .el-pagination .el-pagination__pager button {
    margin: 0.1rem;
    padding: 0.3rem;
  }
  .action-btns-2 {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    height: 100%;
  }
  .details-btn {
    margin-right: 0.7rem;
  }
}
</style>
  
<template>
  <div class="product-container">
    <div class="home-products">
      <span class="material-symbols-outlined" @click="goHome()">
        grid_view
      </span>

      <div class="right-arrow-h4">
        <el-button class="right-arrow" :icon="arrowIcon" circle></el-button>
        <h4>Products</h4>
      </div>
    </div>
    <h3>Products</h3>

    <div class="btns">
      <div>
        <el-button @click="addProduct" class="add-product-btn"
          >Add Product</el-button
        >
      </div>
      <div>
        <el-button
          @click="toggleVerifiedProducts"
          type="primary"
          class="add-product-btn"
        >
          {{ showVerifiedPdts ? "All Products" : "Verified Products" }}
        </el-button>
      </div>
      <div>
        <el-button
          style="margin-right: 0"
          @click="togglePublishedProducts"
          type="primary"
          class="add-product-btn"
        >
          {{ showPublishedPdts ? "All Products" : "Published Products" }}
        </el-button>
      </div>
    </div>

    <!-- table for products -->
    <div class="all-products-table">
      <el-table v-loading="loading" :data="rows" border style="width: 100%">
        <el-table-column label="#">
          <template v-slot="{ $index }">
            {{ (currentPage - 1) * pageSize + ($index + 1) }}</template
          >
        </el-table-column>
        <el-table-column label="Title">
          <template v-slot="{ row }">{{ row.title }}</template>
        </el-table-column>
        <el-table-column label="Price">
          <template v-slot="{ row }">{{ row.price }}</template>
        </el-table-column>
        <el-table-column label="Phone">
          <template v-slot="{ row }">{{ row.phone }}</template>
        </el-table-column>
        <el-table-column label="Description">
          <template v-slot="{ row }">
            <el-tooltip
              :content="row.description"
              placement="top"
              effect="dark"
            >
              {{ truncateDescription(row.description) }}
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="Location">
          <template v-slot="{ row }">{{ row.location }}</template>
        </el-table-column>
        <el-table-column label="Created At">
          <template v-slot="{ row }">{{
            row.last_created_at
              ? formatTimestamp(row.last_created_at)
              : getCurrentTime()
          }}</template>
        </el-table-column>
        <el-table-column label="Products">
          <template v-slot="{ row }">
            <template v-if="row.picture">
              <a :href="row.picture" target="_blank" class="image-link">
                <img
                  class="product-img"
                  :src="row.picture"
                  alt="Product Image"
                  style="max-width: 200px; max-height: 50px"
                />
              </a>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="Actions">
          <template v-slot="{ row }">
            <div class="action-buttons">
              <div class="action-btns-2">
                <el-row class="block-col-2">
                  <el-col :span="8">
                    <el-dropdown>
                      <span class="el-dropdown-link">
                        <el-button
                          type="primary"
                          :icon="moreIcon"
                          circle
                        ></el-button>
                      </span>

                      <template #dropdown>
                        <el-dropdown-menu class="custom-dropdown-menu">
                          <el-dropdown-item
                            @click="deleteProduct(row.productId)"
                            class="dropdown-content"
                          >
                            <span class="material-symbols-outlined">
                              delete
                            </span>
                            <span class="text">Delete Product</span>
                          </el-dropdown-item>
                          <el-dropdown-item
                            @click="editProductDetails(row.productId)"
                            class="dropdown-content"
                          >
                            <span class="material-symbols-outlined">
                              edit_square
                            </span>
                            <span class="text">Edit Product</span>
                          </el-dropdown-item>
                          <el-dropdown-item
                            @click="productDetails(row.productId)"
                            class="dropdown-content"
                          >
                            <span class="material-symbols-outlined">
                              info
                            </span>
                            <span class="text">Product Details</span>
                          </el-dropdown-item>
                          <el-dropdown-item
                            @click="verifyProduct(row.productId)"
                            v-if="!row.verified"
                            class="dropdown-content"
                          >
                            <span class="material-symbols-outlined">
                              task_alt
                            </span>
                            <span class="text">Verify Product</span>
                          </el-dropdown-item>
                          <el-dropdown-item
                            v-else
                            @click="unVerifyProduct(row.productId)"
                            class="dropdown-content"
                          >
                            <span class="material-symbols-outlined">
                              cancel
                            </span>
                            <span class="text">Unverify Product</span>
                          </el-dropdown-item>
                          <el-dropdown-item
                            @click="publishProduct(row.productId)"
                            v-if="!row.published"
                            class="dropdown-content"
                          >
                            <span class="material-symbols-outlined">
                              published_with_changes
                            </span>
                            <span class="text">Publish Product</span>
                          </el-dropdown-item>
                          <el-dropdown-item
                            @click="unPublishProduct(row.productId)"
                            v-else
                            class="dropdown-content"
                          >
                            <span class="material-symbols-outlined">
                              unpublished
                            </span>
                            <span class="text">Unpublish Product</span>
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        :current-page="currentPage"
        :small="small"
        :page-size="pageSize"
        :total="total"
        :disabled="disabled"
        :background="background"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, sizes, prev, pager, next, jumper"
      />
    </div>

    <!-- product details dialog -->
    <ProductDetailsDialog
      :is-product-deatils-dialog-visible="productDetailsDialogVisible"
      :product-id="productId"
      @close-dialog="closeProductDetailsDialog"
      @fetch-all-proucts="fetchAllProducts"
    />
    <!-- editing the product dialog -->
    <EditProductDialog
      :is-edit-product-dialog-visible="editProductDetailsDialogVisible"
      :product-id="productId"
      @fetch-all-proucts="fetchAllProducts"
      @close-dialog="closeEditProductDetailsDialog"
    />
    <!-- adding the product dialog -->

    <AddProductDialog
      :is-add-product-dialog-visible="addProductsDialogVisible"
      @close-dialog="closeAddProductDialog"
      @fetch-all-products="fetchAllProducts"
    />

    <AllDeletedProductsDialog
      :is-all-deleted-products-dialog-visible="deletedProductsDialogVisible"
      @close-dialog="closeDeletedProductsDialog"
      @fetch-all-products="fetchAllProducts"
    />

    <!-- trash can -->
    <div class="fixed-transhcan-button">
      <span class="material-symbols-outlined" @click="viewAllDeletedProducts()"
        >delete</span
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from "vue";
import axios from "axios";
import { useAuthStore } from "../../store/authStore";
import Product from "../../types/Product";
import Swal from "sweetalert2";
import { format } from "date-fns";
import { useRouter } from "vue-router";
import { ArrowRight } from "@element-plus/icons-vue";
import { More } from "@element-plus/icons-vue";
import User from "../../types/User";
import ProductDetailsDialog from "../../components/dialogs/products/product-details-dialog.vue";
import AddProductDialog from "../../components/dialogs/products/add-product-dialog.vue";
import EditProductDialog from "../../components/dialogs/products/edit-product-dialog.vue";
import AllDeletedProductsDialog from "../../components/dialogs/trashcan/all-deleted-products-dialog.vue";

// tokwn and endpoint
const token = computed(() => {
  return useAuthStore().token;
});

// refs
const currentPage = ref(1);
const pageSize = ref(10);
const small = ref(false);
const background = ref(false);
const disabled = ref(false);
const total = ref(0);
const totalPages = ref(0);
const page = ref(1);
const showVerifiedPdts = ref(false);
const showPublishedPdts = ref(false);
const productDetailsDialogVisible = ref(false);
const editProductDetailsDialogVisible = ref(false);
const addProductsDialogVisible = ref(false);
const deletedProductsDialogVisible = ref(false);
const productId = ref("");

const rows = ref<Product[]>([]);

const loading = ref(false);
const router = useRouter();

// redirecting home
const goHome = () => {
  router.push("/dashboard");
};

const arrowIcon = ArrowRight;
const moreIcon = More;

// getting the current time
const getCurrentTime = () => {
  const currentTime = new Date();
  const formattedTime = `${currentTime.toLocaleDateString()} ${currentTime.toLocaleTimeString()}`;
  return formattedTime;
};
// opening the dialog
const productDetails = (pdtId: string) => {
  productId.value = pdtId;
  productDetailsDialogVisible.value = true;
};
const editProductDetails = (pdtId: string) => {
  productId.value = pdtId;
  editProductDetailsDialogVisible.value = true;
};
const addProduct = () => {
  addProductsDialogVisible.value = true;
};
const viewAllDeletedProducts = () => {
  deletedProductsDialogVisible.value = true;
};
const closeProductDetailsDialog = () => {
  productDetailsDialogVisible.value = false;
};
// closing the dialog
const closeEditProductDetailsDialog = () => {
  editProductDetailsDialogVisible.value = false;
};
const closeAddProductDialog = () => {
  addProductsDialogVisible.value = false;
};
const closeDeletedProductsDialog = () => {
  deletedProductsDialogVisible.value = false;
};
// handling the current change of the pagination
const handleCurrentChange = async (val: number) => {
  currentPage.value = val;
  await fetchAllProducts();
};

// handling the current size of the table
const handleSizeChange = async (val: number) => {
  pageSize.value = val;
  currentPage.value = 1;
  await fetchAllProducts();
};

// fetching all the products
const fetchAllProducts = async () => {
  try {
    loading.value = true;
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/products",
      {
        method: "get_all_products",
        page: currentPage.value,
      },
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );

    if (response.data.success) {
      rows.value = response.data.result.map((product: Product) => ({
        last_created_at: product.last_created_at,
        userId: product.user_id,
        username: product.username,
        productId: product.id,
        title: product.title,
        description: product.description,
        picture: product.picture,
        verified: product.verified,
        published: product.published,
        phone: product.phone_number,
        location: product.location,
        price: product.price,
      }));

      // Assigning total number of products
      total.value = parseInt(response.data.total_products);

      // setting current page and page size
      currentPage.value = response.data.current_page;
      pageSize.value = response.data.per_page;
    } else {
      // Handle other error cases
      const errorMessage = response.data.errors || "Failed to fetch products.";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#3085d6",
    });
  } finally {
    loading.value = false;
  }
};

// trancating the description
const truncateDescription = (description: string) => {
  const words = description.split(" ");
  if (words.length > 3) {
    return words.slice(0, 2).join(" ") + "...";
  } else {
    return description;
  }
};

// deleting the product
const deleteProduct = async (productId: string | number) => {
  try {
    const result = await Swal.fire({
      title: "Dangerous",
      text: "Are you sure you want to delete this product?",
      icon: "warning",
      confirmButtonColor: "#662d91",
      showCancelButton: true,
    });
    if (result.isConfirmed) {
      const response = await axios.post(
        // additional to global baseUrl endpoint
        "/products",
        {
          method: "delete_product",
          product_id: productId,
        },
        {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        }
      );

      if (response.data.success) {
        fetchAllProducts();
        return Swal.fire({
          title: "Successful",
          text: "You have deleted this product successfully",
          icon: "success",
          confirmButtonColor: "#3085d6",
        });
      } else {
        // Handle other error cases
        const errorMessage =
          response.data.errors || "Failed to delete product.";
        return Swal.fire({
          title: "Error",
          text: errorMessage,
          icon: "error",
          confirmButtonColor: "#d33",
        });
      }
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#3085d6",
    });
  }
};

// verifying the product
const verifyProduct = async (productId: string | number) => {
  try {
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/products",
      {
        method: "verify_product",
        product_id: productId,
      },
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );
    if (
      response.data.success &&
      response.data.message === "product verified successfully."
    ) {
      fetchAllProducts();
      return Swal.fire({
        title: "Successful",
        text: "Product verified successfully",
        icon: "success",
        confirmButtonColor: "#3085d6",
      });
    } else {
      // Handle other error cases
      const errorMessage = response.data.errors || "Failed to verify product";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#3085d6",
    });
  }
};

// Unverifying the product
const unVerifyProduct = async (productId: string | number) => {
  try {
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/products",
      {
        method: "unverify_product",
        product_id: productId,
      },
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );
    if (
      response.data.success &&
      response.data.message === "product unverified successfully."
    ) {
      fetchAllProducts();
      return Swal.fire({
        title: "Successful",
        text: "Product Unverified Successfully",
        icon: "success",
        confirmButtonColor: "#3085d6",
      });
    } else {
      // Handle other error cases
      const errorMessage = response.data.errors || "Failed to unverify product";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#3085d6",
    });
  }
};

// publishing the product
const publishProduct = async (productId: string | number) => {
  try {
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/products",
      {
        method: "publish_product",
        product_id: productId,
        publishedStatus: true,
      },
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );
    if (
      response.data.success &&
      response.data.message === "product published successfully."
    ) {
      fetchAllProducts();
      return Swal.fire({
        title: "Successful",
        text: "Product published successfully",
        icon: "success",
        confirmButtonColor: "#3085d6",
      });
    } else {
      // Handle other error cases
      const errorMessage = response.data.errors || "Failed to publish product";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#3085d6",
    });
  }
};

// Unpublishing the product
const unPublishProduct = async (productId: string | number) => {
  try {
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/products",
      {
        method: "unpublish_product",
        product_id: productId,
        publishedStatus: false,
      },
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );
    if (
      response.data.success &&
      response.data.message === "product unpublished successfully."
    ) {
      fetchAllProducts();
      return Swal.fire({
        title: "Successful",
        text: "Product Unpublished Successfully",
        icon: "success",
        confirmButtonColor: "#3085d6",
      });
    } else {
      // Handle other error cases
      const errorMessage =
        response.data.errors || "Failed to unpublish product";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#3085d6",
    });
  }
};

// GETTING VERIFIED products
const toggleVerifiedProducts = async () => {
  showVerifiedPdts.value = !showVerifiedPdts.value;
  if (showVerifiedPdts.value) {
    await fetchVerifiedProducts();
  } else {
    await fetchAllProducts();
  }
};

// toggling between published pdts and all products
const togglePublishedProducts = async () => {
  showPublishedPdts.value = !showPublishedPdts.value;
  if (showPublishedPdts.value) {
    await fetchPublishedProducts();
  } else {
    await fetchAllProducts();
  }
};

// fetching all verified products
const fetchVerifiedProducts = async () => {
  try {
    loading.value = true;
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/products",
      {
        method: "get_verified_products",
        page: currentPage.value,
      },
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );
    if (response.data.success) {
      rows.value = response.data.data.map((product: Product) => ({
        last_created_at: product.last_created_at,
        userId: product.user_id,
        username: product.username,
        productId: product.id,
        title: product.title,
        description: product.description,
        picture: product.picture,
        verified: product.verified,
        published: product.published,
        createdBy: product.username,
        clientId: product.user_id,
        price: product.price,
        location: product.location,
        phone: product.phone_number,
      }));
    } else {
      // Handle other error cases
      const errorMessage =
        response.data.errors || "Failed to fetch verified products.";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#3085d6",
    });
  } finally {
    loading.value = false;
  }
};

// fetching all the published products
const fetchPublishedProducts = async () => {
  try {
    loading.value = true;
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/products",
      {
        method: "get_published_products",
        page: page,
      },
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );
    if (response.data.success) {
      rows.value = response.data.data.map((product: Product) => ({
        last_created_at: product.last_created_at,
        userId: product.user_id,
        username: product.username,
        productId: product.id,
        title: product.title,
        description: product.description,
        picture: product.picture,
        verified: product.verified,
        published: product.published,
        createdBy: product.username,
        clientId: product.user_id,
        price: product.price,
        location: product.location,
        phone: product.phone_number,
      }));
    } else {
      // Handle other error cases
      const errorMessage =
        response.data.errors || "Failed to fetch published products.";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#3085d6",
    });
  } finally {
    loading.value = false;
  }
};

// changing the format of the time using fns
const formatTimestamp = (timestamp: string) => {
  if (!timestamp) return ""; // Handle case where timestamp is null or undefined
  return format(new Date(timestamp), "yyyy-MM-dd HH:mm:ss");
};

// onmounting fetch products, all products will be displayed
onMounted(() => {
  fetchAllProducts();
});
</script>

<style scoped>
.product-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 95%;
}
.home-products {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0;
}
.right-arrow {
  background-color: transparent;
  border: none;
  color: gray;
}
h4 {
  font-size: 1rem;
  font-weight: 300;
  margin-top: 0.5rem;
}
.right-arrow-h4 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.material-symbols-outlined:hover {
  cursor: pointer;
}
.material-symbols-outlined {
  font-size: 1.2rem;
  margin-left: 0;
}

h3 {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 2rem 0 3rem 0;
  color: #662d91;
}
.btns {
  display: flex;
  justify-content: flex-end;
  margin-right: 0;
}
.btns button {
  padding: 17px 20px;
  margin: 5px;
  font-size: 12px;
}
.add-product-btn {
  background: #662d91;
  padding: 15px 25px;
}
.add-product-btn:hover {
  color: #662d91;
  border: 1px solid #662d91;
  background-color: #f0e9f5;
}

/* CSS for file input container */
.file-input {
  margin-bottom: 10px;
}

.pagination {
  margin: 1rem auto;
  overflow-x: auto;
}
.content-image {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.detailed-img {
  height: 25rem;
  width: 20rem;
}

.viewer-profile {
  display: flex;
  gap: 1rem;
}
.profile-img {
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: 300;
  cursor: pointer;
}
.watchers {
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.close-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -2rem;
}
.modal-form {
  display: flex;
  flex-direction: column;
}
.details-close {
  color: #aaa;
  font-size: 28px;
  font-weight: 300;
  margin-right: 3rem !important;
}
.details-close:hover {
  cursor: pointer;
  color: #662d91;
}

.product-details-h3 {
  font-weight: 300;
  font-size: 1.2rem;
}

.all-products-table {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.el-table {
  overflow-x: auto;
}

input,
textarea {
  padding: 10px;
}
.file-input {
  position: relative;
}

.input-container {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
}

.text-input {
  flex: 1;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
}

/* modal ends here */
/* teble starts here */
table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  border: 1px solid #eae9e9;

  padding: 1px;
  text-align: left;
  font-size: 0.8rem;
}
th {
  padding: 5px;
}
tr:hover {
  background-color: #d9edf4;
}

th {
  background-color: #f2f2f2;
}
.gray-row {
  background-color: #f2f2f2;
}
.action-btns-2 .el-button {
  margin: 2px;
}

button {
  padding: 15px 20px;
  background-color: #662d91;
  color: white;
  margin: 1rem;
  cursor: pointer;
  border-radius: 5px;
  border: none;
}
.action-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.action-btns-2 {
  display: flex;
  flex-direction: row;
}
.fa-check-circle,
.fa-times-circle {
  font-size: 2rem;
}

.verification-switch {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

iframe {
  height: 3rem;
  width: 7rem;
}

.action-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.1rem;
}

.material-symbols-outlined {
  font-size: 1.2rem;
  margin-left: 0;
}

strong {
  font-weight: 700;
}
.el-tooltip__popper {
  max-width: 300px;
  white-space: normal;
  word-wrap: break-word;
}
.dropdown-content .material-symbols-outlined {
  font-size: 24px;
  margin-right: 8px;
}

.dropdown-content .text {
  font-size: 18px;
  padding: 0.5rem;
}

.product-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.fixed-transhcan-button {
  position: fixed;
  bottom: 5px;
  right: 5px;
  color: red;
  padding: 10px;
  cursor: pointer;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fixed-transhcan-button .material-symbols-outlined {
  font-size: 50px;
}

/* Responsive adjustments for larger screens */
@media (max-width: 768px) {
  .btns {
    flex-direction: column; /* Stack buttons vertically on smaller screens */
    align-items: flex-start; /* Align buttons to the start of the container */
  }

  .add-product-btn {
    width: 100%; /* Make buttons full width on smaller screens */
    min-width: unset; /* Remove minimum width */
    margin-bottom: 1rem; /* Add spacing between buttons */
  }
  .pagination {
    flex-direction: row; /* Maintain row layout */
    flex-wrap: wrap; /* Allow wrapping to fit pagination */
    align-items: center; /* Center items vertically */
    font-size: 0.875rem; /* Slightly smaller font size for better fit */
  }

  .pagination .el-pagination {
    width: auto; /* Let pagination take up necessary space */
  }

  .pagination .el-pagination .el-pagination__pager {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center pagination buttons */
  }

  .pagination .el-pagination .el-pagination__pager button {
    margin: 0.2rem; /* Add margin between buttons */
  }
}

/* Responsive adjustments for screens smaller than 468px */
@media (max-width: 468px) {
  .btns {
    gap: 0.5rem; /* Reduce gap between buttons */
  }

  .add-product-btn {
    padding: 8px 16px; /* Adjust padding for smaller screens */
    font-size: 12px; /* Reduce font size for smaller screens */
    width: 100%; /* Make buttons full width */
    min-width: unset; /* Remove minimum width */
    margin-bottom: 0.5rem; /* Reduce spacing between buttons */
  }
  .pagination {
    font-size: 0.75rem; /* Smaller font size for very small screens */
    padding: 0.5rem; /* Reduce padding around pagination */
  }

  .pagination .el-pagination {
    width: auto; /* Ensure full-width usage */
  }

  .pagination .el-pagination .el-pagination__pager {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center pagination buttons */
  }

  .pagination .el-pagination .el-pagination__pager button {
    margin: 0.1rem; /* Reduce margin between buttons */
    padding: 0.3rem; /* Smaller padding for buttons */
  }
}
</style>
<template>
  <div class="advert-container">
    <div class="home-user">
      <span class="home-icon">
        <span class="material-symbols-outlined" @click="goHome()">
          grid_view
        </span>
      </span>
      <div class="right-arrow-h4">
        <el-button class="right-arrow" :icon="arrowIcon" circle></el-button>
        <h4>Adverts</h4>
      </div>
    </div>
    <h3>Adverts</h3>

    <div class="btns">
      <div>
        <el-button @click="addAdvert" type="primary" class="add-advert-btn"
          >Add Advert</el-button
        >
      </div>
      <!--           @click="toggleVerifiedAds"            @click="togglePublishedAdverts" -->
      <div>
        <el-button type="primary" class="add-advert-btn">
          {{ showVerifiedAds ? "All Adverts" : "Verified Ads" }}
        </el-button>
      </div>
      <div>
        <el-button
          style="margin-right: 0"
          type="primary"
          class="add-advert-btn"
        >
          {{ showPublishedAds ? "All Adverts" : "Published Ads" }}
        </el-button>
      </div>
    </div>

    <!--table -->
    <div class="my-adverts-table">
      <el-table v-loading="loading" :data="rows" border style="width: 100%">
        <el-table-column label="#" width="90">
          <template v-slot="{ $index }">
            {{ (currentPage - 1) * pageSize + ($index + 1) }}</template
          >
        </el-table-column>
        <el-table-column label="client Id">
          <template v-slot="{ row }">{{ row.clientId }}</template>
        </el-table-column>
        <el-table-column label="Title">
          <template v-slot="{ row }">{{ truncateTitle(row.title) }}</template>
        </el-table-column>
        <el-table-column label="Description">
          <template v-slot="{ row }">
            <el-tooltip :content="row.description" placement="top">
              <div>{{ truncateDescription(row.description) }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="Created At">
          <template v-slot="{ row }">{{
            formatTimestamp(row.created_at)
          }}</template>
        </el-table-column>
        <el-table-column label="Advert Id">
          <template v-slot="{ row }">{{ row.advertId }}</template>
        </el-table-column>
        <el-table-column label="View Count">
          <template v-slot="{ row }">{{ row.views_count }}</template>
        </el-table-column>
        <el-table-column label="Adverts">
          <template v-slot="{ row }">
            <template v-if="row.picture">
              <a :href="row.picture" target="_blank" class="image-link">
                <img
                  class="advert-img"
                  :src="row.picture"
                  alt="Advert Image"
                  style="max-width: 200px; max-height: 50px"
                />
              </a>
            </template>
            <template v-if="row.video">
              <div class="viewed-vid">
                <video
                  class="advert-video"
                  :src="row.video"
                  style="max-width: 200px; max-height: 50px"
                ></video>
              </div>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="Actions">
          <template v-slot="{ row }">
            <div class="action-buttons">
              <div class="action-btns-2">
                <div class="bottom-action-btns">
                  <el-row class="block-col-2">
                    <el-col :span="8">
                      <el-dropdown>
                        <span class="el-dropdown-link">
                          <el-button
                            type="primary"
                            :icon="moreIcon"
                            circle
                          ></el-button>
                        </span>

                        <template #dropdown>
                          <el-dropdown-menu class="custom-dropdown-menu">
                            <el-dropdown-item
                              @click="deleteTheAdvert(row.advertId)"
                              class="dropdown-content"
                            >
                              <span class="material-symbols-outlined">
                                delete
                              </span>
                              <span class="text">Delete Advert</span>
                            </el-dropdown-item>
                            <el-dropdown-item
                              @click="editAdvert(row.advertId)"
                              class="dropdown-content"
                            >
                              <span class="material-symbols-outlined">
                                edit_square
                              </span>
                              <span class="text">Edit Advert</span>
                            </el-dropdown-item>
                            <el-dropdown-item
                              @click="advertDetails(row.advertId)"
                              class="dropdown-content"
                            >
                              <span class="material-symbols-outlined">
                                info
                              </span>
                              <span class="text">Advert Details</span>
                            </el-dropdown-item>
                            <el-dropdown-item
                              @click="
                                addAdvertMobilePayment(
                                  row.advertId,
                                  row.clientId
                                )
                              "
                              class="dropdown-content"
                            >
                              <span class="material-symbols-outlined">
                                database
                              </span>
                              <span class="text"
                                >Add Advert Mobile Payment</span
                              >
                            </el-dropdown-item>
                            <el-dropdown-item
                              @click="payFromCashchatapp(row.advertId)"
                              class="dropdown-content"
                            >
                              <span class="material-symbols-outlined">
                                request_quote
                              </span>
                              <span class="text">Pay from Cashchatapp</span>
                            </el-dropdown-item>
                          </el-dropdown-menu>
                        </template>
                      </el-dropdown>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        :current-page="currentPage"
        :small="small"
        :page-size="pageSize"
        :total="total"
        :disabled="disabled"
        :background="background"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, sizes, prev, pager, next, jumper"
      />
    </div>

    <!-- adding advert Dialog -->

    <AddingAdvertDialog
      :is-add-advert-dialog-visible="addAdvertDialogVisible"
      @close-dialog="closeAddingAdvertDialog"
      @fetch-adverts="fetchAdverts"
    />
    <!-- adding mobile payment dialog -->
    <AddMobilePaymentDialog
      :is-add-advert-payment-dialog-visible="addMobilePaymentDialogVisible"
      @close-dialog="closeCardMobilePaymentDialog"
      :advert-id="advertId"
      :client-id="clientId"
      @fetch-adverts="fetchAdverts"
    />
    <!-- editing advrt dialog -->
    <EditAdvertDialog
      :is-edit-advert-dialog-visible="editAdvertDialogVisible"
      :advert-id="advertId"
      @close-dialog="closeEditingAdvertDialog"
      @fetch-adverts="fetchAdverts"
    />
    <!-- advert detials dialog -->
    <AdvertDetailsDialog
      :is-advert-details-dialog-visible="advertDetailsDialogVisible"
      :advert-id="advertId"
      @close-dialog="closeAdvertDetailsDialog"
    />
    <PayFromCashchatDialog
      :is-pay-from-cashchatapp-dialog-visible="payFromCashchatappWalletVisible"
      @close-dialog="closePayFromCashchatappDialog"
      :advert-id="advertId"
      :client-id="clientId"
      @fetch-adverts="fetchAdverts"
    />
  </div>
</template>
      
      <script lang="ts" setup>
//   imports
import { ref, computed, onMounted } from "vue";
import axios from "axios";
import { useAuthStore } from "../../store/authStore";
import Advert from "../../types/Advert";
import Swal from "sweetalert2";
import { More } from "@element-plus/icons-vue";
import { useRouter } from "vue-router";
import { ArrowRight } from "@element-plus/icons-vue";
import EditAdvertDialog from "../../components/dialogs/adverts/edit-advert-dialog.vue";
import AdvertDetailsDialog from "../../components/dialogs/adverts/advert-details-dialog.vue";
import AddingAdvertDialog from "../../components/dialogs/adverts/adding-advert-dialog.vue";
import AddMobilePaymentDialog from "../../components/dialogs/payments/mobile-advert-payment-dialog.vue";
import PayFromCashchatDialog from "../../components/dialogs/payments/pay-from-cashchatapp-dialog.vue";
import User from "../../types/User";
import { format } from "date-fns";

// token
const token = computed(() => {
  return useAuthStore().token;
});

// refs
const editAdvertDialogVisible = ref(false);
const addMobilePaymentDialogVisible = ref(false);
const addAdvertDialogVisible = ref(false);
const advertDetailsDialogVisible = ref(false);
const currentPage = ref(1);
const pageSize = ref(10);
const small = ref(false);
const background = ref(false);
const disabled = ref(false);
const total = ref(0);
const totalPages = ref(0);
const page = ref(1);
const clientId = ref("");
const advertId = ref("");

const showVerifiedAds = ref(false);
const showPublishedAds = ref(false);

const payFromCashchatappWalletVisible = ref(false);

const loading = ref(false);
const rows = ref<Advert[]>([]);

const moreIcon = More;
const arrowIcon = ArrowRight;

// opening edit advert dialog
const editAdvert = (adId: string) => {
  advertId.value = adId;
  editAdvertDialogVisible.value = true;
};

// closing edit advert dialog
const closeEditingAdvertDialog = () => {
  editAdvertDialogVisible.value = false;
};

const payFromCashchatapp = (adId: string) => {
  advertId.value = adId;
  payFromCashchatappWalletVisible.value = true;
};
// opening  advert details dialog
const advertDetails = (adId: string) => {
  advertId.value = adId;
  advertDetailsDialogVisible.value = true;
};

// opening advert detials dialog
const closeAdvertDetailsDialog = () => {
  advertDetailsDialogVisible.value = false;
};

const closePayFromCashchatappDialog = () => {
  payFromCashchatappWalletVisible.value = false;
};
// opening add advert dialog
const addAdvert = () => {
  addAdvertDialogVisible.value = true;
};
// opening adding mobile paymentadvert dialog
const addAdvertMobilePayment = (adId: string, clntId: string) => {
  advertId.value = adId;
  clientId.value = clntId;
  addMobilePaymentDialogVisible.value = true;
};
// closing adding advert dialog
const closeAddingAdvertDialog = () => {
  addAdvertDialogVisible.value = false;
};
// closing mobile advert dialog
const closeCardMobilePaymentDialog = () => {
  addMobilePaymentDialogVisible.value = false;
};

const router = useRouter();

// redirecting home
const goHome = () => {
  router.push("/dashboard");
};

const formatTimestamp = (timestamp: string) => {
  if (!timestamp) return ""; // Handling case where timestamp is null or undefined
  return format(new Date(timestamp), "yyyy-MM-dd HH:mm:ss");
};

// handling curreny change on the table
const handleCurrentChange = async (val: number) => {
  currentPage.value = val;
  await fetchAdverts();
};

// handling  size of the table on the current page
const handleSizeChange = async (val: number) => {
  pageSize.value = val;
  currentPage.value = 1;
  await fetchAdverts();
};

// trancating the description
const truncateDescription = (description: string) => {
  const words = description.split(" ");
  if (words.length > 3) {
    return words.slice(0, 2).join(" ") + "...";
  } else {
    return description;
  }
};

// trancating the title
const truncateTitle = (title: string) => {
  const words = title.split(" ");
  if (words.length > 3) {
    return words.slice(0, 2).join(" ") + "...";
  } else {
    return title;
  }
};

// GETTING VERIFIED ADVERTDS
// const toggleVerifiedAds = async () => {
//   showVerifiedAds.value = !showVerifiedAds.value;
//   if (showVerifiedAds.value) {
//     await fetchVerifiedAdverts();
//   } else {
//     await fetchAdverts();
//   }
// };

// GETTING ALL ADVERTS FROM THE DB
const fetchAdverts = async () => {
  try {
    loading.value = true;
    rows.value = [];
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/adverts",
      {
        method: "my_adverts",
        page: currentPage.value,
      },
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );
    if (response.data.success) {
      total.value = parseInt(response.data.total_adverts);

      // Mapping the result array to popuate data in the rows array
      rows.value = response.data.result.adverts.map((advert: Advert) => ({
        created_at: advert.created_at,
        // userId: user_id,
        // username: username,
        advertId: advert.id,
        title: advert.title,
        description: advert.body,
        thumbnail: advert.thumbnail,
        video: advert.video,
        picture: advert.picture,
        views_count: advert.views_count,
        clientId: advert.client_id,
      }));
    } else {
      // Handle other error cases
      const errorMessage = response.data.errors || "Failed to fetch adverts";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#3085d6",
    });
  } finally {
    loading.value = false;
  }
};
// onmounting fetch adverts, all adverts will be displayed
onMounted(() => {
  fetchAdverts();
});

// deletimg the advert
const deleteTheAdvert = async (advertId: string | number) => {
  try {
    const result = await Swal.fire({
      title: "Dangerous",
      text: "Are you sure you want to delete this advert? Content will be deleted permanently",
      icon: "warning",
      confirmButtonColor: "#3085d6",
      showCancelButton: true,
    });
    if (result.isConfirmed) {
      const response = await axios.post(
        // additional to global baseUrl endpoint
        "/adverts",
        {
          method: "delete_advert",
          advert_id: advertId,
        },
        {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        }
      );

      if (response.data.success) {
        fetchAdverts();
        return Swal.fire({
          title: "Successful",
          text: "You have deleted this Advert successfully",
          icon: "success",
          confirmButtonColor: "#3085d6",
        });
      } else {
        // Handle other error cases
        const errorMessage = response.data.errors || "Failed to delete advert";
        return Swal.fire({
          title: "Error",
          text: errorMessage,
          icon: "error",
          confirmButtonColor: "#d33",
        });
      }
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#3085d6",
    });
  }
};

// Defining a computed property to dynamically filter adverts based on showVerifiedAds
// const filteredRows = computed(() => {
//   const startIndex = (currentPage.value - 1) * pageSize.value;
//   const endIndex = startIndex + pageSize.value;
//   if (showVerifiedAds.value) {
//     const verifiedAdverts = rows.value.filter((ads) => ads.verified);
//     return verifiedAdverts.slice(startIndex, endIndex);
//   } else if (showPublishedAds.value) {
//     const publishedAdverts = rows.value.filter((ads) => ads.published);
//     return publishedAdverts.slice(startIndex, endIndex);
//   } else {
//     return rows.value;
//   }
// });

// fetching all varified adverts
// const fetchVerifiedAdverts = async () => {
//   try {
//     const response = await axios.post(
//       // additional to global baseUrl endpoint
//       "/adverts",
//       {
//         method: "get_verified_adverts",
//         page: page,
//       },
//       {
//         headers: {
//           Authorization: `Bearer ${token.value}`,
//         },
//       }
//     );
//     if (response.data.success) {
//       const verifiedAdverts = response.data.result.flatMap((user: User) =>
//         user.adverts.filter((advert: Advert) => advert.verified)
//       );
//       // Update total count based on the number of verified adverts
//       total.value = verifiedAdverts.length;
//       // Calculate the total number of pages required based on 10 adverts per page
//       totalPages.value = Math.ceil(total.value / pageSize.value);
//       // If there are less than or equal to 10 adverts, set the total pages to 1
//       if (total.value <= pageSize.value) {
//         totalPages.value = 1;
//         // Ensure that the current page is always 1 when there are 10 or fewer adverts
//         currentPage.value = 1;
//       }
//       // Calculate the starting index for the verified adverts based on the current page
//       const startIndex = (currentPage.value - 1) * pageSize.value;
//       // Calculate the ending index for the verified adverts
//       let endIndex = currentPage.value * pageSize.value;
//       // Adjust the end index if it exceeds the total number of verified adverts
//       if (endIndex > total.value) {
//         endIndex = total.value;
//       }
//       // Display verified adverts based on the calculated indexes
//       rows.value = verifiedAdverts.slice(startIndex, endIndex);
//     }
//   } catch (error: any) {
//     throw new Error(error.message);
//   }
// };

// getting verified adverts
// const fetchVerifiedAdverts = async () => {
//   try {
//     loading.value = true;
//     const response = await axios.post(
//       // additional to global baseUrl endpoint
//       "/adverts",
//       {
//         method: "get_verified_adverts",
//         page: currentPage.value,
//       },
//       {
//         headers: {
//           Authorization: `Bearer ${token.value}`,
//         },
//       }
//     );
//     if (response.data.success) {
//       // total.value = parseInt(response.data.total_adverts);

//       // Mapping the result array to populate data in the rows array
//       rows.value = response.data.adverts.map((advert: Advert) => ({
//         advertId: advert.id,
//         title: advert.title,
//         description: advert.body,
//         thumbnail: advert.thumbnail,
//         video: advert.video,
//         picture: advert.picture,
//         views_count: advert.views_count,
//         clientId: advert.client_id,
//       }));
//     }
//   } catch (error) {
//     return Swal.fire({
//       title: "Error",
//       text: "Error fetching verified adverts",
//       icon: "error",
//       confirmButtonColor: "#3085d6",
//     });
//   } finally {
//     loading.value = false;
//   }
// };

// toggling between published adverts and all adverts
// const togglePublishedAdverts = async () => {
//   showPublishedAds.value = !showPublishedAds.value;
//   if (showPublishedAds.value) {
//     await fetchPublishedAdverts();
//   } else {
//     await fetchAdverts();
//   }
// };

// getting all the published adverts
// const fetchPublishedAdverts = async () => {
//   try {
//     const response = await axios.post(
//       // additional to global baseUrl endpoint
//       "/adverts",
//       {
//         method: "get_published_adverts",
//         page: page,
//       },
//       {
//         headers: {
//           Authorization: `Bearer ${token.value}`,
//         },
//       }
//     );
//     if (response.data.success) {
//       const publishedAdverts = response.data.result.flatMap((user: User) =>
//         user.adverts.filter((advert: Advert) => advert.verified)
//       );
//       // Update total count based on the number of verified adverts
//       total.value = publishedAdverts.length;
//       // Calculate the total number of pages required based on 10 adverts per page
//       totalPages.value = Math.ceil(total.value / pageSize.value);
//       // If there are less than or equal to 10 adverts, set the total pages to 1
//       if (total.value <= pageSize.value) {
//         totalPages.value = 1;
//         // Ensure that the current page is always 1 when there are 10 or fewer adverts
//         currentPage.value = 1;
//       }
//       // Calculate the starting index for the verified adverts based on the current page
//       const startIndex = (currentPage.value - 1) * pageSize.value;
//       // Calculate the ending index for the verified adverts
//       let endIndex = currentPage.value * pageSize.value;
//       // Adjust the end index if it exceeds the total number of verified adverts
//       if (endIndex > total.value) {
//         endIndex = total.value;
//       }
//       // Display verified adverts based on the calculated indexes
//       rows.value = publishedAdverts.slice(startIndex, endIndex);
//     }
//   } catch (error: any) {
//     throw new Error(error.message);
//   }
// };

// const fetchPublishedAdverts = async () => {
//   try {
//     loading.value = true;
//     const response = await axios.post(
//       // additional to global baseUrl endpoint
//       "/adverts",
//       {
//         method: "get_published_adverts",
//         page: currentPage.value,
//       },
//       {
//         headers: {
//           Authorization: `Bearer ${token.value}`,
//         },
//       }
//     );
//     if (response.data.success) {
//       // total.value = parseInt(response.data.total_adverts);

//       // Mapping the result array to populate data in the rows array
//       rows.value = response.data.data.map((advert: Advert) => ({
//         last_created_at: advert.last_created_at,
//         userId: advert.user_id,
//         username: advert.username,
//         advertId: advert.id,
//         title: advert.title,
//         description: advert.description,
//         thumbnail: advert.thumbnail,
//         video: advert.video,
//         picture: advert.picture,
//         verified: advert.verified,
//         published: advert.published,
//         createdBy: advert.username,
//         clientId: advert.user_id,
//       }));
//     }
//   } catch (error: any) {
//     return Swal.fire({
//       title: "Error",
//       text: "Error fetching published adverts",
//       icon: "error",
//       confirmButtonColor: "#3085d6",
//     });
//   } finally {
//     loading.value = false;
//   }
// };
</script>
      
      <style scoped>
.advert-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 95%;
}
.home-user {
  display: flex;
  align-items: center;
  /* gap: 0.5rem; */
  margin-left: 0;
}
.right-arrow {
  background-color: transparent;
  border: none;
  color: gray;
}
h4 {
  font-size: 1rem;
  font-weight: 300;
  margin-top: 0.5rem;
}
.right-arrow-h4 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1px;
}
.material-symbols-outlined:hover {
  cursor: pointer;
}
.home-icon .material-symbols-outlined {
  font-size: 1.2rem;
  margin-left: 0;
}

h3 {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 2rem 0 3rem 0;
  color: #662d91;
}
.btns {
  display: flex;
  justify-content: flex-end;
  margin-right: 0;
}
.btns button {
  padding: 17px 20px;
  margin: 5px;
  font-size: 12px;
  border: none;
}
.add-advert-btn {
  background: #662d91;
}
.add-advert-btn:hover {
  color: #662d91;
  border: 1px solid #662d91;
  background-color: #f0e9f5;
}
.my-adverts-table {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 1rem; */
  margin-bottom: 2rem;
}

.el-table {
  overflow-x: auto;
}

th,
td {
  border: 1px solid #eae9e9;

  padding: 1px;
  text-align: left;
  font-size: 0.8rem;
}
th {
  padding: 5px;
}
tr:hover {
  background-color: #d9edf4;
}

th {
  background-color: #f2f2f2;
}
.gray-row {
  background-color: #f2f2f2;
}

.action-btns-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.fa-check-circle,
.fa-times-circle {
  font-size: 2rem;
}

iframe {
  height: 3rem;
  width: 7rem;
}

.advert-video,
.advert-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-link {
  text-decoration: none;
}
.material-symbols-outlined {
  font-size: 2rem;
  padding-right: 0.6rem;
}

.dropdown-content .material-symbols-outlined {
  font-size: 24px;
  margin-right: 8px;
}

.dropdown-content .text {
  font-size: 18px;
  padding: 0.5rem;
}
button {
  padding: 8px 10px;
  background-color: #662d91;
  color: white;
  margin: 1rem;
  cursor: pointer;
  border-radius: 5px;
  border: none;
}
/* Media Queries for Responsiveness */
@media (max-width: 480px) {
  .btns button {
    padding: 8px 12px;
    font-size: 0.7rem;
  }

  h3 {
    font-size: 1rem;
  }

  table {
    font-size: 0.7rem;
  }

  th,
  td {
    padding: 6px;
  }

  .fixed-trashcan-button {
    bottom: 5px;
    right: 5px;
    padding: 5px;
  }

  .fixed-trashcan-button .material-symbols-outlined {
    font-size: 2rem;
  }

  th,
  td {
    display: block;
    width: 100%;
    box-sizing: border-box;
    text-align: right;
  }

  th {
    background: #f2f2f2;
    text-align: center;
  }

  td {
    position: relative;
    padding-left: 50%;
    text-align: left;
  }

  td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 45%;
    padding-left: 10px;
    white-space: nowrap;
    font-weight: bold;
    text-align: left;
  }
  .pagination {
    font-size: 0.75rem;
    padding: 0.5rem;
  }

  .pagination .el-pagination {
    width: auto;
  }

  .pagination .el-pagination .el-pagination__pager {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .pagination .el-pagination .el-pagination__pager button {
    margin: 0.1rem;
    padding: 0.3rem;
  }
}

@media (max-width: 768px) {
  .btns button {
    padding: 10px 14px;
    font-size: 0.8rem;
  }

  h3 {
    font-size: 1.25rem;
  }

  table {
    font-size: 0.8rem;
  }

  .material-symbols-outlined {
    font-size: 2rem;
  }

  .fixed-trashcan-button {
    bottom: 5px;
    right: 5px;
    padding: 10px;
  }

  .fixed-trashcan-button .material-symbols-outlined {
    font-size: 3rem;
  }
  .pagination {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    font-size: 0.875rem;
  }

  .pagination .el-pagination {
    width: auto;
  }

  .pagination .el-pagination .el-pagination__pager {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .pagination .el-pagination .el-pagination__pager button {
    margin: 0.2rem;
  }
}
</style>
      
<template>
  <el-dialog
    :model-value="isCreateAnnouncementDialogVisible"
    title="Create Announcement"
    :width="dialogWidth"
    :style="{ paddingBottom: '20px' }"
    @close="closeDialog"
  >
    <el-form class="modal-form" label-width="auto">
      <el-form-item label="Title" required>
        <el-input v-model="title" />
      </el-form-item>
      <el-form-item label="Body" required>
        <el-input v-model="body" type="textarea" />
      </el-form-item>
      <div class="button-container">
        <el-button
          class="create-announcements-btn"
          type="primary"
          @click="createAnnouncement"
        >
          Create Announcement
        </el-button>
      </div>
    </el-form>
  </el-dialog>
</template>
  
  <script lang="ts" setup>
import axios from "axios";
import {
  ref,
  defineEmits,
  defineProps,
  computed,
  onMounted,
  onUnmounted,
} from "vue";
import Swal from "sweetalert2";
import AnnouncementDetails from "../../../types/AnnouncementDetails";
import { useAuthStore } from "../../../store/authStore";

// declaring the endpoints

const token = computed(() => {
  return useAuthStore().token;
});

const props = defineProps<{
  isCreateAnnouncementDialogVisible: boolean;
  notificationCount: number;
}>();

const emit = defineEmits<{
  (e: "close-dialog"): void;
  (e: "fetch-all-announcements"): void;
  (e: "update-notification-count", count: number): void;
  (e: "new-announcement", announcement: AnnouncementDetails): void; // Emit event for new announcement
}>();

const closeDialog = () => {
  emit("close-dialog");
};

// refs
const title = ref("");
const body = ref("");

const windowWidth = ref(window.innerWidth);

// Computed property for responsive dialog width
const dialogWidth = computed(() => {
  const width = windowWidth.value <= 480 ? "90%" : "500px";
  const maxWidth = "500px"; // Maximum width for large screens
  return `min(${width}, ${maxWidth})`;
});

function handleResize() {
  windowWidth.value = window.innerWidth; // Update the reactive windowWidth
}
// Watch for window resize events
onMounted(() => {
  window.addEventListener("resize", handleResize);
  handleResize();
});

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});

// Mtethod for creating the Announcement
const createAnnouncement = async () => {
  try {
    if (!title.value || !body.value) {
      Swal.fire({
        title: "Warning",
        text: "All fields are required",
        icon: "warning",
        confirmButtonColor: "#3085d6",
      });
      return;
    }
    const formData = new FormData();
    formData.append("method", "create_announcement");
    formData.append("title", title.value);
    formData.append("body", body.value);

    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/services",
      formData,
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    // Checking for success
    if (response.data.success) {
      // Emitting the custom event for the parent to listen
      emit("update-notification-count", 1);
      emit("new-announcement", response.data.announcement);

      // Showing success message
      Swal.fire({
        title: "Successful",
        text: "Announcement Created Successfully",
        icon: "success",
        confirmButtonColor: "#3085d6",
      });

      // Clearing form inputs on success
      emit("fetch-all-announcements");
      title.value = "";
      body.value = "";
      closeDialog();
    } else {
      // Handle other error cases
      const errorMessage =
        response.data.errors || "Failed to create announcement";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    // Show error message
    Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#3085d6",
    });
  }
};
</script>

<style scoped>
.create-announcements-btn {
  margin-top: 0.5rem;
  background-color: #662d91;
  padding: 20px 30px;
  border: none;
  margin: 1rem auto;
}

.create-announcements-btn:hover {
  border: 1px solid #662d91;
  background-color: transparent;
  color: #662d91;
}
.button-container {
  display: flex;
  margin-top: 20px;
}
</style>
  
  
<template>
  <el-dialog
    :model-value="isAddProductDialogVisible"
    title="Add Product"
    :width="dialogWidth"
    :style="{ paddingBottom: '20px' }"
    @close="closeDialog"
  >
    <el-form class="advert-add-modal-content" :model="form" label-width="80px">
      <el-form-item label="Title">
        <el-input v-model="form.title.value" />
      </el-form-item>
      <el-form-item label="Location">
        <el-input v-model="form.location.value" />
      </el-form-item>
      <el-form-item label="Phone">
        <el-input v-model="form.phone.value" />
      </el-form-item>
      <el-form-item label="Price">
        <el-input v-model="form.price.value" />
      </el-form-item>
      <el-form-item label="Description">
        <el-input type="textarea" v-model="form.description.value" />
      </el-form-item>

      <div v-loading="loading" class="file-input">
        <!-- Show the file preview if a file has been selected -->
        <template v-if="pictureAd">
          <div
            v-if="pictureAd"
            class="position-relative category-photo"
            style="background: #fdfdfd"
          >
            <img class="edited-img" :src="fileUrl" style="object-fit: cover" />
            <div
              class="remove-img w-100 d-flex align-items-center justify-content-center"
              style="height: 100%"
            >
              <button
                class="remove-img-btn"
                style="
                  font-size: 1.5rem;
                  color: #fff;
                  background-color: rgba(0, 0, 0, 0.45);
                "
                @click="handleImageEdit"
              >
                Choose Picture
              </button>
            </div>
          </div>
        </template>
        <template v-else>
          <label for="media" class="choose-file">Choose Picture</label>
          <label class="add-img">
            <div class="image-container">
              <input
                type="file"
                class="d-none"
                accept="image/"
                @change="handleFileAdd"
                style="display: none"
              />
              <span class="el-icon-plus"></span>
              <span class="material-symbols-outlined"> add </span>
            </div>
          </label>
        </template>
      </div>
      <el-button type="submit" class="save-button" @click="saveProduct"
        >Save</el-button
      >
    </el-form>
  </el-dialog>
</template>
  
  <script lang="ts" setup>
import {
  ref,
  defineProps,
  defineEmits,
  computed,
  onMounted,
  onUnmounted,
} from "vue";
import { useAuthStore } from "../../../store/authStore";

import axios from "axios";
import Swal from "sweetalert2";

// declaring the token
const token = computed(() => {
  return useAuthStore().token;
});

const props = defineProps<{
  isAddProductDialogVisible: boolean;
}>();

// emitting the custom event "close-dialog" for closing the dialog
const emit = defineEmits<{
  (e: "close-dialog"): void;
  (e: "fetch-all-products"): void;
}>();

const closeDialog = () => {
  emit("close-dialog");
};

// declaring the form object
const form = {
  title: ref<string>(""),
  description: ref<string>(""),
  location: ref<string>(""),
  phone: ref<string>(""),
  price: ref<string>(""),
};

// refs
const isPicture = ref<boolean>(false);
const pictureAd = ref("");
const editedImage = ref("");
const fileUrl = ref("");
const loading = ref(false);

const windowWidth = ref(window.innerWidth);

// Computed property for responsive dialog width
const dialogWidth = computed(() => {
  const width = windowWidth.value <= 480 ? "90%" : "300px";
  const maxWidth = "400px"; // Maximum width for large screens
  return `min(${width}, ${maxWidth})`;
});

function handleResize() {
  windowWidth.value = window.innerWidth; // Update the reactive windowWidth
}
// Watch for window resize events
onMounted(() => {
  window.addEventListener("resize", handleResize);
  handleResize();
});

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});

// warning sweet alert
const warningSwal = async () => {
  // Show error message
  const result = await Swal.fire({
    title: "Warning",
    text: "Invalid file type. Please select an image or a video.",
    icon: "warning",
    confirmButtonColor: "#3085d6",
  });
  //   keeping the dialog there.
  if (result.isConfirmed) {
    // dialogVisible.value = true;
    props.isAddProductDialogVisible;
  }
};

// saving the product
const saveProduct = async () => {
  // Check if all required fields are filled
  if (
    !form.title.value ||
    !form.description.value ||
    !form.location.value ||
    !form.phone.value ||
    !form.price.value ||
    (isPicture.value && !pictureAd.value)
  ) {
    emit("close-dialog");
    return Swal.fire({
      title: "Error",
      text: "Please fill all the required fields",
      icon: "error",
      confirmButtonColor: "#d33",
    });
  }
  try {
    loading.value = true;
    // Accessing the user ID from the global state (using Pinia)
    const authStore = useAuthStore();
    const userId = authStore.userId;

    // Create a FormData object
    const formData = new FormData();
    formData.append("method", "add_product");
    formData.append("user_id", userId.toString());
    formData.append("description", form.description.value);
    formData.append("title", form.title.value);
    formData.append("location", form.location.value);
    formData.append("phone_number", form.phone.value);
    formData.append("price", form.price.value);
    if (isPicture.value) {
      formData.append("picture", pictureAd.value); // Append picture file
      formData.append("is_picture", isPicture.value.toString());
    }

    // Send the request with the FormData object
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/products",
      formData,
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    // Handle response
    if (response.data.success) {
      console.log(response.data);
      closeDialog();
      emit("fetch-all-products");
      // Show success message
      Swal.fire({
        title: "Successful",
        text: "You have added the product successfully",
        icon: "success",
        confirmButtonColor: "#3085d6",
      });
      // Clear input fields after successful product creation
      emit("fetch-all-products");
      form.title.value = "";
      form.description.value = "";
      form.location.value = "";
      form.phone.value = "";
      form.price.value = "";
      isPicture.value = false;
      pictureAd.value = "";
      editedImage.value = "";
      fileUrl.value = "";
      emit("close-dialog");
    } else {
      emit("close-dialog");
      // Handle other error cases
      const errorMessage = response.data.errors || "Failed to add product";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    emit("close-dialog");
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#d33",
    });
  } finally {
    loading.value = false;
  }
};

// handling the image edit
const handleImageEdit = () => {
  editedImage.value = "";
  pictureAd.value = "";
};

// handling the file add
const handleFileAdd = (event: any) => {
  // event.preventDefault();
  const file = event.target.files?.[0];
  if (file) {
    const fileType = file.type;
    if (fileType.startsWith("image/")) {
      fileUrl.value = URL.createObjectURL(file);
      if (fileType.startsWith("image/")) {
        pictureAd.value = file;
        isPicture.value = true;
      }
    } else {
      console.log("File type not allowed");
      warningSwal();
    }
  }
};
</script>
  
  <style scoped>
.modal-content,
.advert-add-modal-content {
  background-color: #fefefe;
  margin: 0.5rem auto;
  padding: 20px;
  border-radius: 10px;
  height: auto;
}

.remove-img {
  display: none;
}
.remove-img-btn {
  display: none;
}
.category-photo:hover .remove-img-btn {
  display: block;
}

.save-button {
  background-color: #662d91;
  color: #fff;
  border: none;
  padding: 20px 3rem;
  cursor: pointer;
  display: block;
  margin: 1rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

/* CSS for button hover effect */
.save-button:hover {
  background-color: transparent;
  border: 1px solid #662d91;
  color: #662d91;
}

.material-symbols-outlined:hover {
  cursor: pointer;
}
.material-symbols-outlined {
  font-size: 2rem;
  padding-right: 0.6rem;
}
.add-img {
  border: 1px dashed rgb(189, 187, 187);
  border-radius: 5px;
  height: 80%;
  font-size: 3em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

input[type="file"] {
  display: none; /* Hide file input */
}
.category-photo:hover .remove-img {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgb(0, 0, 0, 0.45);
}
.category-photo:hover .remove-img-btn {
  display: block;
}

.category-photo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  margin: 2rem auto;
}

.edited-img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}
.choose-file {
  margin: 0.5rem;
}
.dialog-overlay {
  z-index: 1000;
}
.swal-overlay {
  z-index: 9999 !important;
}
</style>
<template>
  <div>
    <el-dialog
      :model-value="isPaidAdvertDetailsDialogVisible"
      title="Paid Advert details"
      :width="dialogWidth"
      :style="{ paddingBottom: '20px' }"
      @close="closeDialog"
      @open="viewPaidAdvertDetails"
    >
      <div id="receiptDialog">
        <div :style="{ border: '5px dotted #000', padding: '10px' }">
          <el-row :gutter="20">
            <el-col :span="12">
              <b>Title:</b>
            </el-col>
            <el-col :span="12">{{ paymentData.title }} </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <b>ID:</b>
            </el-col>
            <el-col :span="12">{{ paymentData.id }} </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <b>Trans Status:</b>
            </el-col>
            <el-col :span="12">{{ paymentData.status }} </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <b>Amount:</b>
            </el-col>
            <el-col :span="12">{{ paymentData.amount }}</el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <b>Payment Id:</b>
            </el-col>
            <el-col :span="12">{{ paymentData.payment_id }}</el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <b>Number:</b>
            </el-col>
            <el-col :span="12">{{ paymentData.mobile }}</el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <b>Reference</b>
            </el-col>
            <el-col :span="12">{{ paymentData.reference }}</el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <b>Created At:</b>
            </el-col>
            <el-col :span="12">{{ formatDate(paymentData.created_at) }}</el-col>
          </el-row>

          <p style="margin-top: 20px">
            Thank you for your payment. If you have any questions, please
            contact us at
            <a href="mailto:support@cashchatapp.com">support@cashchatapp.com</a
            >.
          </p>
          <div class="button-container">
            <el-button
              class="receipt-btn"
              @click="downloadPDF(paymentData)"
              type="primary"
              >Download Receipt</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
      
      <script lang="ts" setup>
import {
  defineProps,
  ref,
  defineEmits,
  computed,
  onMounted,
  onUnmounted,
} from "vue";
import PaymentDetails from "../../../types/PaymentDetails";
import axios from "axios";
import { useAuthStore } from "../../../store/authStore";
import Swal from "sweetalert2";
// declaring token

const token = computed(() => {
  return useAuthStore().token;
});

// Defining props

const props = defineProps<{
  isPaidAdvertDetailsDialogVisible: boolean;
  advertId: string;
}>();

// defining the emit
const emit = defineEmits<{
  (e: "close-dialog"): void;
}>();

// emitting the custom event "close-dialog" for closing the dialog

const closeDialog = () => {
  emit("close-dialog");
};

const windowWidth = ref(window.innerWidth);

// Computed property for responsive dialog width
const dialogWidth = computed(() => {
  const width = windowWidth.value <= 480 ? "90%" : "500px";
  const maxWidth = "600px"; // Maximum width for large screens
  return `min(${width}, ${maxWidth})`;
});

function handleResize() {
  windowWidth.value = window.innerWidth; // Update the reactive windowWidth
}
// Watch for window resize events
onMounted(() => {
  window.addEventListener("resize", handleResize);
  handleResize();
});

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});

// formatting the date format
const formatDate = (dateString: string) => {
  // Convert the date string to a Date object
  const date = new Date(dateString);
  // Format the date using toLocaleString method
  return date.toLocaleString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};

// declaring payment data object
const paymentData = ref<PaymentDetails>({
  status: "",
  amount: 0,
  payment_id: "",
  id: 0,
  mobile: "",
  title: "",
  reference: "",
  created_at: "",
  advert_id: 0,
});

// Method for viewing paid adverts Data
const viewPaidAdvertDetails = async () => {
  try {
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/payments",
      {
        method: "user_paid_adverts",
        advert_id: props.advertId,
      },

      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );
    if (response.data.success) {
      console.log(response.data);
      const paidAdvert = response.data.result.paid_adverts[0];

      // Updating paymentData with transaction details
      paymentData.value = {
        title: paidAdvert.title,
        status: paidAdvert.status,
        payment_id: paidAdvert.payment_id,
        mobile: paidAdvert.mobile,
        amount: paidAdvert.amount,
        id: paidAdvert.id,
        created_at: paidAdvert.created_at,
        reference: paidAdvert.reference,
        advert_id: paidAdvert.advert_id,
      };
    } else {
      // Handle other error cases
      const errorMessage =
        response.data.errors || "Failed to show adverts details";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#d33",
    });
  }
};

// downloading the receipt as a pdf
const downloadPDF = async (row: PaymentDetails) => {
  try {
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/payments",
      {
        method: "export_transaction_details_pdf",
        advert_id: row.advert_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
        responseType: "blob", // Setting response type to blob (Bonary Larger Object) to receive binary data
      }
    );

    // Check if the request was successful
    if (response.status === 200) {
      // Creating a Blob object from the PDF data received from the backend response
      const pdfBlob = new Blob([response.data], { type: "application/pdf" });

      // Creating a temporary link element
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(pdfBlob);
      link.download = "receipt.pdf"; // Setting the filename for the downloaded file

      // Triggering the download
      link.click();

      // Revoking the URL to release the resources
      window.URL.revokeObjectURL(link.href);
    } else {
      // Handle other error cases
      const errorMessage = response.data.errors || "Failed to download pdf";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#d33",
    });
  }
};
</script>
    
    <style scoped>
.button-container {
  display: flex;
  justify-content: center;
}
</style>
<template>
  <el-dialog
    :model-value="isAllDeletedProductsDialogVisible"
    title="All deleted products"
    :width="dialogWidth"
    @close="closeDialog"
    @open="viewAllDeletedProducts"
  >
    <div class="all-deleted-products-table">
      <el-table v-loading="loading" :data="rows" border style="width: 100%">
        <el-table-column label="#">
          <template v-slot="{ $index }">
            {{ (currentPage - 1) * pageSize + ($index + 1) }}</template
          >
        </el-table-column>
        <el-table-column label="Title">
          <template v-slot="{ row }">{{ row.title }}</template>
        </el-table-column>
        <el-table-column label="Price">
          <template v-slot="{ row }">{{ row.price }}</template>
        </el-table-column>
        <el-table-column label="Phone">
          <template v-slot="{ row }">{{ row.phone }}</template>
        </el-table-column>
        <el-table-column label="Description">
          <template v-slot="{ row }">
            <el-tooltip
              :content="row.description"
              placement="top"
              effect="dark"
            >
              {{ truncateDescription(row.description) }}
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="Location">
          <template v-slot="{ row }">{{ row.location }}</template>
        </el-table-column>
        <el-table-column label="Created At">
          <template v-slot="{ row }">{{
            row.last_created_at
              ? formatTimestamp(row.last_created_at)
              : getCurrentTime()
          }}</template>
        </el-table-column>
        <el-table-column label="Products">
          <template v-slot="{ row }">
            <template v-if="row.picture">
              <a :href="row.picture" target="_blank" class="image-link">
                <img
                  class="product-img"
                  :src="row.picture"
                  alt="Product Image"
                  style="max-width: 200px; max-height: 50px"
                />
              </a>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="Restore">
          <template v-slot="{ row }">
            <span
              @click="restoreDeletedProduct(row.productId)"
              class="material-symbols-outlined"
              style="color: #008000"
            >
              restore_from_trash
            </span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="currentPage"
        :small="small"
        :page-size="pageSize"
        :total="total"
        :disabled="disabled"
        :background="background"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, sizes, prev, pager, next, jumper"
      />
    </div>
  </el-dialog>
</template>
    
    <script lang="ts" setup>
import {
  ref,
  defineProps,
  defineEmits,
  computed,
  onMounted,
  onUnmounted,
} from "vue";
import { format } from "date-fns";
import axios from "axios";
import Swal from "sweetalert2";
import { useAuthStore } from "../../../store/authStore";
import Product from "../../../types/Product";

const windowWidth = ref(window.innerWidth);

// Computed property for responsive dialog width
const dialogWidth = computed(() => {
  const width = windowWidth.value <= 480 ? "90%" : "500px";
  const maxWidth = "800px"; // Maximum width for large screens
  return `min(${width}, ${maxWidth})`;
});

function handleResize() {
  windowWidth.value = window.innerWidth; // Update the reactive windowWidth
}
// Watch for window resize events
onMounted(() => {
  window.addEventListener("resize", handleResize);
  handleResize();
});

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});

// declaring the token
const token = computed(() => {
  return useAuthStore().token;
});

const props = defineProps<{
  isAllDeletedProductsDialogVisible: boolean;
}>();

// emitting the custom event "close-dialog" for closing the dialog
const emit = defineEmits<{
  (e: "close-dialog"): void;
  (e: "fetch-all-products"): void;
}>();

const closeDialog = () => {
  emit("close-dialog");
};

const loading = ref(false);
const rows = ref<Product[]>([]);
const currentPage = ref(1);
const pageSize = ref(10);
const small = ref(false);
const background = ref(false);
const disabled = ref(false);
const total = ref(0);
const productId = ref("");

const truncateDescription = (description: string) => {
  const words = description.split(" ");
  if (words.length > 3) {
    return words.slice(0, 3).join(" ") + "...";
  } else {
    return description;
  }
};
const truncateTitle = (title: string) => {
  const words = title.split(" ");
  if (words.length > 3) {
    return words.slice(0, 3).join(" ") + "...";
  } else {
    return title;
  }
};
// changing the format of the time using fns
const formatTimestamp = (timestamp: string) => {
  if (!timestamp) return ""; // Handling case where timestamp is null or undefined
  return format(new Date(timestamp), "yyyy-MM-dd HH:mm:ss");
};
const getCurrentTime = () => {
  const currentTime = new Date();
  const formattedTime = `${currentTime.toLocaleDateString()} ${currentTime.toLocaleTimeString()}`;
  return formattedTime;
};

// viewing all the deleted adverts

const viewAllDeletedProducts = async () => {
  try {
    loading.value = true;
    const response = await axios.post(
      "/products",
      {
        method: "get_deleted_products",
      },
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );
    if (response.data.success) {
      total.value = parseInt(response.data.total_adverts);
      rows.value = response.data.result.map((product: Product) => ({
        last_created_at: product.last_created_at,
        userId: product.user_id,
        username: product.username,
        productId: product.id,
        title: product.title,
        description: product.description,
        picture: product.picture,
        verified: product.verified,
        published: product.published,
        phone: product.phone_number,
        location: product.location,
        price: product.price,
      }));
      // Assigning total number of products
      total.value = parseInt(response.data.total_products);

      // setting current page and page size
      currentPage.value = response.data.current_page;
      pageSize.value = response.data.per_page;
    } else {
      // Handle error cases
      const errorMessage =
        response.data.errors || "Failed to fetch deleted products.";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#3085d6",
    });
  } finally {
    loading.value = false;
  }
};

// restoring deleted advert

const restoreDeletedProduct = async (productId: string) => {
  try {
    loading.value = true;
    // Create a FormData object
    const formData = new FormData();
    formData.append("method", "restore_product");
    formData.append("product_id", productId);
    const response = await axios.post("/products", formData, {
      headers: {
        Authorization: `Bearer ${token.value}`,
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.data.success) {
      closeDialog();
      emit("fetch-all-products");
      return Swal.fire({
        title: "succesful",
        text: "Your Product has been restored",
        icon: "success",
        confirmButtonColor: "#662d91",
      });
    } else {
      // Handle error cases
      const errorMessage = response.data.errors || "Failed to restore product.";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#3085d6",
    });
  } finally {
    loading.value = false;
  }
};
</script>
    
    <style scoped>
.all-deleted-products-table {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.product-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.material-symbols-outlined {
  cursor: pointer;
  font-size: 2rem;
}
</style>
<template>
  <div>
    <CreatingAnnouncements
      :isCreateAnnouncementDialogVisible="isCreateAnnouncementDialogVisible"
      :notificationCount="notificationCount"
      @update-notification-count="handleUpdateNotificationCount"
    />
    <nav class="header-container">
      <div class="menu-bar">
        <h3>{{ userNameLogin }}</h3>
      </div>
      <div class="spacer"></div>
      <div class="bell-user-image">
        <!-- adding the badge to the bell for showing notifications -->
        <div v-if="isAgent">
          <el-badge :value="unreadNotifications.length" :max="10" class="item">
            <span
              class="material-symbols-outlined"
              @click="showNotification = true"
            >
              notifications
            </span>
          </el-badge>
        </div>
        <!-- Display user's picture or name initials -->
        <img
          v-if="userPicture"
          :src="userPicture"
          alt="user-image"
          class="profile-pic"
          style="object-fit: cover"
        />
        <!-- Display picture if available -->
        <div v-else class="initials">{{ userInitials }}</div>
        <!-- Display initials if picture is not available -->
      </div>
    </nav>
    <!-- <el-button @click="showNotification = true"> Notifications </el-button> -->
    <el-drawer v-model="showNotification" :show-close="true">
      <template #header="{ titleId, titleClass }">
        <div class="heading-switch">
          <h4 :id="titleId" :class="titleClass">Notifications</h4>
          <div class="switch-heading">
            <p class="switch-para">
              {{
                showUnreadNotifications
                  ? "Showing unread"
                  : "Showing all notifications"
              }}
            </p>
            <el-switch
              v-model="showUnreadNotifications"
              class="mt-2"
              style="margin-left: 24px"
              inline-prompt
              :active-icon="Check"
              :inactive-icon="Close"
            />
          </div>
        </div>
      </template>

      <hr />
      <a href="#" @click.prevent="markAllAsRead">Mark all as read</a>

      <div v-if="showUnreadNotifications">
        <ul v-if="unreadNotifications.length > 0">
          <li
            v-for="notification in unreadNotifications"
            :key="notification.id"
            class="notification-item"
          >
            <div>
              <h5 class="announcement-title">{{ notification.title }}</h5>
              <p>
                {{
                  notification.showMore
                    ? notification.description
                    : truncateDescription(notification.description)
                }}
              </p>
              <p>{{ formatDate(notification.created_at) }}</p>
            </div>
            <el-button
              v-if="
                countWords(notification.description) > 10 &&
                !notification.showMore
              "
              type="primary"
              @click="openNotificationCard(notification)"
              class="button-container"
            >
              > Show more
            </el-button>
            <el-button
              v-if="notification.showMore"
              type="primary"
              @click="toggleDescription(notification)"
              class="button-container"
            >
              > Show less
            </el-button>
            <el-button
              type="success"
              :icon="Check"
              @click="markAsReadNotification(notification.id)"
              circle
              style="
                position: absolute;
                top: 50%;
                right: 20px;
                transform: translateY(-50%);
              "
              data-tooltip="Mark as read"
            />
          </li>
        </ul>
        <p v-else class="no-unread-notifications-paragraph">
          No unread notifications
        </p>
      </div>
      <div v-else>
        <ul>
          <li
            v-for="notification in markedNotifications"
            :key="notification.id"
            class="notification-item"
          >
            <div>
              <h5 class="announcement-title">{{ notification.title }}</h5>
              <p>
                {{
                  notification.showMore
                    ? notification.description
                    : truncateDescription(notification.description)
                }}
              </p>
              <p>{{ formatDate(notification.created_at) }}</p>
            </div>

            <el-button
              v-if="
                countWords(notification.description) > 10 &&
                !notification.showMore
              "
              type="primary"
              @click="openNotificationCard(notification)"
              class="button-container"
            >
              > Show more
            </el-button>
            <el-button
              v-if="notification.showMore"
              type="primary"
              @click="toggleDescription(notification)"
              class="button-container"
            >
              > Show less
            </el-button>
          </li>
        </ul>
      </div>
      <el-dialog
        v-model="notificationDialogVisible"
        :width="dialogWidth"
        :modal-append-to-body="false"
        :show-close="false"
      >
        <div v-if="selectedNotification">
          <h5>{{ selectedNotification.title }}</h5>
          <p class="paragraph-large">{{ selectedNotification.description }}</p>
          <p>{{ formatDate(selectedNotification.created_at) }}</p>
        </div>
        <template #footer>
          <el-button @click="notificationDialogVisible = false"
            >Close</el-button
          >
        </template>
      </el-dialog>
    </el-drawer>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted, onUnmounted } from "vue";
import axios from "axios";
import CreatingAnnouncements from "./dialogs/announcements/creating-announcements-dialog.vue";
import { useAuthStore } from "../store/authStore";
import { ElButton, ElDrawer } from "element-plus";
import { Check, Close } from "@element-plus/icons-vue";
import Notification from "../types/Notification";
import Swal from "sweetalert2";

// token
const token = computed(() => useAuthStore().token);
const isAgent = computed(() => useAuthStore().isAgent);

// refs
const userNameLogin = useAuthStore().userName;
const notificationCount = ref(0);
const isCreateAnnouncementDialogVisible = ref(false);
const showNotification = ref(false);
const showUnreadNotifications = ref(true);
const notificationDialogVisible = ref(false);
const selectedNotification = ref<Notification | null>(null);
const notifications = ref<Notification[]>([]);

const windowWidth = ref(window.innerWidth);

const dialogWidth = computed(() => {
  const width = windowWidth.value <= 480 ? "90%" : "500px";
  const maxWidth = "600px"; // Maximum width for large screens
  return `min(${width}, ${maxWidth})`;
});

function handleResize() {
  windowWidth.value = window.innerWidth; // Update the reactive windowWidth
}
// Watch for window resize events
onMounted(() => {
  window.addEventListener("resize", handleResize);
});

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});
// for opening the notification dialog
const openNotificationCard = (notification: Notification) => {
  selectedNotification.value = notification;
  notificationDialogVisible.value = true;
};

// showing only the unread  notifications
const unreadNotifications = computed(() => {
  return notifications.value.filter(
    (notification: Notification) => !notification.read
  );
});

// making the notifications
const markedNotifications = computed(() => {
  return notifications.value.filter(
    (notification: Notification) => notification.read
  );
});

// truncating the description
const truncateDescription = (description: string) => {
  const maxWords = 15;
  const words = description.split(" ");
  if (words.length > maxWords) {
    return words.slice(0, maxWords).join(" ") + "...";
  } else {
    return description;
  }
};

// toggling between more and less
const toggleDescription = (notification: Notification) => {
  notification.showMore = !notification.showMore;
  if (notification.showMore) {
    openNotificationCard(notification);
  }
};

// counting words of the description
const countWords = (text: string) => {
  return text.trim().split(/\s+/).length;
};

const formatDate = (timestamp: string) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  // Converting the timestamp to a Date object
  const date = new Date(timestamp);

  // Getting the date components
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  // Getting the time components
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  // Formating the date and time
  const formattedDate = `${day} ${month} , ${year} at ${hours}:${minutes}`;

  return formattedDate;
};

// getting the user initials
const userInitials = computed(() => {
  const userName = useAuthStore().userName;
  if (userName) {
    const names = userName.split(" ");
    let initials = "";
    for (let i = 0; i < Math.min(names.length, 2); i++) {
      initials += names[i].charAt(0).toUpperCase();
    }
    return initials;
  }
  return "";
});

// getting the user profile picture
const userPicture = computed(() => {
  return useAuthStore().userPicture;
});

const handleUpdateNotificationCount = () => {
  notificationCount.value += 1; // Increment the count by the emitted value
};

// fetching notifications
const fetchNotifications = async () => {
  try {
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/services",
      {
        method: "get_notifications",
      },
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );
    if (response.data.success) {
      notifications.value = response.data.notifications;
    } else {
      // Handle other error cases
      const errorMessage =
        response.data.errors || "Failed to fetch notifications";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    await Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#d33",
    });
  }
};

// marking notification
const markAsReadNotification = async (id: string | number) => {
  try {
    const formData = new FormData();
    formData.append("method", "mark_as_read");
    formData.append("id", String(id));
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/services",
      formData,
      {
        headers: { Authorization: `Bearer ${token.value}` },
      }
    );
    if (response.data.success) {
      const notificationIndex = notifications.value.findIndex(
        (notification) => notification.id === id
      );
      if (notificationIndex !== -1) {
        notifications.value[notificationIndex].read = true;
        // Trigger Vue reactivity
        notifications.value = [...notifications.value];
      }
    } else {
      // Handle other error cases
      const errorMessage =
        response.data.errors || "Failed to mark notification as read.";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    await Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#d33",
    });
  }
};

// marking all notifications as read
const markAllAsRead = async () => {
  try {
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/services",
      { method: "mark_all_as_read" },
      {
        headers: { Authorization: `Bearer ${token.value}` },
      }
    );
    if (response.data.success) {
      notifications.value.forEach((notification) => (notification.read = true));
    } else {
      // Handle other error cases
      const errorMessage =
        response.data.errors || "Failed to mark all notifications as read.";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    await Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#d33",
    });
  }
};

onMounted(() => {
  fetchNotifications();
});
</script>
  
  <style scoped>
.header-container {
  display: flex;
  align-items: center;
  position: fixed;
  width: 100vw;
  background-color: white;
  height: 4rem;
  z-index: 99;
  padding: 0 20px;
}
.menu-bar {
  order: -1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2rem;
}
.spacer {
  flex: 1;
}
.fa-bars {
  cursor: pointer;
}

img {
  border-radius: 50%;
  height: 40px;
  width: 40px;
}
.cashchat-logo img {
  height: 1rem;
  width: 7rem;
  margin: 2rem 1rem;
}

i {
  margin-right: 1rem;
  font-size: 1.2rem;
}

h3 {
  font-weight: 300;
  font-size: 1.3rem;
  color: #662d91;
  margin-left: 4rem;
  text-transform: capitalize;
}

.bell-user-image {
  display: flex;
  align-items: center;
  margin-right: 8rem;
  gap: 2rem;
}

.anoouncement-title {
  font-size: 1rem;
  font-weight: 700;
}

.material-symbols-outlined {
  font-size: 2rem;
}
.material-symbols-outlined:hover {
  cursor: pointer;
}

/* Style for displaying initials */
.initials {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #662d91;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #fff;
  margin-left: 10px;
}
.notification-header {
  display: flex;
  justify-content: space-around;
}
.switch-heading {
  display: flex;
  align-items: center;
}
.switch-para {
  font-size: 0.9rem;
  margin-top: 1rem;
}
li {
  list-style: none;
  /* margin: 1rem; */
  box-shadow: 0 5px gray;
}

.notification-item {
  padding: 15px 3rem 15px 1rem;
  margin-bottom: 10px;
  margin-left: -15px;
  border-radius: 5px;
  box-shadow: 0 5px 7px rgba(0, 0, 0, 0.1);
  background-color: white;
  position: relative;
}

[data-tooltip] {
  position: relative;
  cursor: pointer;
}

[data-tooltip]:after {
  content: attr(data-tooltip);
  background-color: black;
  color: white;
  padding: 5px 10px;
  border-radius: 3px;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  display: none;
}

[data-tooltip]:hover:after {
  display: block;
}
a {
  color: black;
  text-decoration: none;
  margin-bottom: 1.5rem;
  /* float: right; */
}
a:hover {
  text-decoration: underline;
}
.no-unread-notifications-paragraph {
  text-align: center;
  margin: 5rem auto;
}
.button-container {
  display: flex;
  justify-content: center;
  background-color: transparent;
  border: none;
  color: #662d91;
}
.paragraph-large {
  font-size: 18px;
  white-space: pre-line;
}
.profile-pic {
  object-fit: cover;
}
.el-drawer {
  /* Make sure the drawer is full-screen on mobile */
  width: 100vw;
  max-width: 100%;
}
.heading-switch {
  display: flex;
}
/* responsiveness for different screens sizes */

@media (max-width: 468px) {
  .header-container {
    /* flex-direction: row; */
    height: 3rem;
    padding: 0 5px;
  }

  .menu-bar {
    margin-left: 1rem;
  }

  .bell-user-image {
    margin-right: 0;
    gap: 0.5rem;
  }

  .material-symbols-outlined {
    font-size: 1.2rem;
  }

  .initials {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }

  .notification-item {
    padding: 10px 0.5rem;
    margin-left: 0;
  }

  .button-container {
    font-size: 0.8rem;
  }
  .el-overlay {
    width: 100vw;
    max-width: 100%;
  }
  .heading-switch {
    flex-direction: column;
    overflow-x: auto;
  }
  .switch-heading {
    flex-direction: column;
    justify-content: center;
  }
}
@media (max-width: 768px) {
  .header-container {
    height: 3rem;
    padding: 0 10px;
  }

  .menu-bar h3 {
    font-size: 1.1rem;
    margin-left: 2rem;
  }

  .bell-user-image {
    margin-right: 4rem;
    gap: 1rem;
  }

  .material-symbols-outlined {
    font-size: 1.5rem;
  }

  .initials {
    width: 35px;
    height: 35px;
    font-size: 16px;
  }

  .notification-item {
    padding: 15px 1rem;
    margin-left: 0;
  }

  .button-container {
    font-size: 0.9rem;
  }
  .el-overlay {
    width: 80vw;
    max-width: 80%;
    background-color: red;
  }
  .heading-switch {
    flex-direction: column;
    overflow-x: auto;
  }
}
</style>


  
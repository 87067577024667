<template>
  <el-dialog
    :model-value="isAddUserDialogVisible"
    title="Add User"
    :width="dialogWidth"
    :style="{ paddingBottom: '20px' }"
    @close="closeDialog"
    @open="fetchCountries"
  >
    <el-form class="modal-form" label-width="auto" v-loading="loading">
      <div class="form-container">
        <div class="form-row">
          <el-form-item label="Username">
            <el-input type="text" v-model="username" required />
          </el-form-item>
        </div>
        <div class="form-row">
          <el-form-item label="Phone">
            <el-input type="text" v-model="phone" required />
          </el-form-item>
        </div>
        <div class="form-row">
          <el-form-item label="Country">
            <div class="user-input-selector">
              <el-select
                v-model="selectedCountry"
                placeholder="Choose Nationality"
                style="width: 100%"
                popper-class="custom-dropdown"
              >
                <el-option
                  v-for="country in sortedCountries"
                  :key="country.country_code"
                  :label="country.country_name"
                  :value="country.country_code"
                />
              </el-select>
            </div>
          </el-form-item>
        </div>
      </div>
      <el-button @click="saveUser" type="primary" class="save-user-btn"
        >Save User</el-button
      >
    </el-form>
  </el-dialog>
</template>
  
  <script lang="ts" setup>
import axios from "axios";
import {
  ref,
  defineEmits,
  defineProps,
  computed,
  onMounted,
  onUnmounted,
} from "vue";
import Swal from "sweetalert2";
import Country from "../../../types/Country";
import { useAuthStore } from "../../../store/authStore";

const windowWidth = ref(window.innerWidth);

// Computed property for responsive dialog width
const dialogWidth = computed(() => {
  const width = windowWidth.value <= 480 ? "90%" : "400px";
  const maxWidth = "500px"; // Maximum width for large screens
  return `min(${width}, ${maxWidth})`;
});

function handleResize() {
  windowWidth.value = window.innerWidth; // Update the reactive windowWidth
}
// Watch for window resize events
onMounted(() => {
  window.addEventListener("resize", handleResize);
  handleResize();
});

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});

// declarig the token
const token = computed(() => {
  return useAuthStore().token;
});

const props = defineProps<{
  isAddUserDialogVisible: boolean;
}>();

const emit = defineEmits<{
  (e: "close-dialog"): void;
  (e: "fetch-all-users"): void;
}>();

const closeDialog = () => {
  emit("close-dialog");
};

const username = ref("");
const phone = ref("");
const agent = ref(false);
const admin = ref(false);
const countries = ref<Country[]>([]);
const selectedCountry = ref<string>("");
const loading = ref(false);

// Sorting the coutries alphabetically
const sortedCountries = computed(() => {
  return countries.value
    .slice()
    .sort((a: Country, b: Country) =>
      a.country_name.localeCompare(b.country_name)
    );
});

// Saving the User
const saveUser = async () => {
  try {
    loading.value = true;
    if (
      username.value === "" ||
      phone.value === "" ||
      selectedCountry.value === ""
    ) {
      emit("close-dialog");
      return Swal.fire({
        title: "Warning",
        text: "All fields are required",
        icon: "warning",
        confirmButtonColor: "#3085d6",
      });
    } else {
      const formData = new FormData();
      formData.append("method", "add_user");
      formData.append("username", username.value);
      formData.append("phone", phone.value.toString());
      formData.append("is_agent", agent.value ? "true" : "false");
      formData.append("admin", admin.value ? "true" : "false");

      const countryCodeResponse = await axios.post(
        // additional to global baseUrl endpoint
        "/countries",
        { method: "list_country_codes" },
        {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        }
      );

      if (countryCodeResponse.data.success) {
        // Fetching country code data
        const countries = countryCodeResponse.data.countries;
        countries.sort((a: Country, b: Country) =>
          a.country_name.localeCompare(b.country_name)
        );

        const selectedCountryData = countries.find(
          (country: Country) => country.country_code === selectedCountry.value
        );

        if (selectedCountryData) {
          // Append selected country code to form data
          formData.append("country", selectedCountryData.country_code);
        } else {
          console.log("Selected country is empty or invalid.");
          return;
        }

        // Submit form data to API
        const response = await axios.post(
          // additional to global baseUrl endpoint
          "/users",
          formData,
          {
            headers: {
              Authorization: `Bearer ${token.value}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.data.success) {
          console.log(response.data);
          Swal.fire({
            title: "Successful",
            text: "User Added Successfully",
            icon: "success",
            confirmButtonColor: "#3085d6",
          });

          // Clearing input fields after successful user creation
          emit("fetch-all-users");
          username.value = "";
          phone.value = "";
          selectedCountry.value = "";
          agent.value = false;
          admin.value = false;
          emit("close-dialog");
        } else {
          emit("close-dialog");
          // Handle other error cases
          const errorMessage =
            response.data.result?.join(", ") || "Failed to add user";
          return Swal.fire({
            title: "Error",
            text: errorMessage,
            icon: "error",
            confirmButtonColor: "#d33",
          });
        }
      } else {
        emit("close-dialog");
        return Swal.fire({
          title: "Error",
          text: "Failed to fetch country code",
          icon: "error",
          confirmButtonColor: "#d33",
        });
      }
    }
  } catch (error: any) {
    emit("close-dialog");
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#d33",
    });
  } finally {
    loading.value = false;
  }
};

// fetchig countries
const fetchCountries = async () => {
  try {
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/countries",
      { method: "list_country_codes" },
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );
    if (response.data.success) {
      const fetchedCountries = response.data.countries;
      fetchedCountries.sort((a: Country, b: Country) =>
        a.country_name.localeCompare(b.country_name)
      );
      // Setting the sorted countries to the countries array
      countries.value = fetchedCountries;
    } else {
      // Handle other error cases
      const errorMessage = response.data.errors || "Failed to fetch countries";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#d33",
    });
  }
};
</script>

<style scoped>
.roles-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-btn {
  margin-top: 0.5rem;
  background-color: #662d91;
  padding: 1.2rem;
  border: none;
}

.modal-btn:hover {
  background-color: transparent;
  color: #662d91;
  border: 1px solid #662d91;
}
.modal-form .form-container {
  display: flex;
  flex-direction: column;
}
.user-input-selector {
  width: 100%;
}
.save-user-btn {
  background-color: #662d91;
  color: #fff;
  border: none;
  padding: 20px 3rem;
  cursor: pointer;
  display: block;
  margin: 1rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}
.save-user-btn:hover {
  background-color: transparent;
  border: 1px solid #662d91;
  color: #662d91;
}

.material-symbols-outlined:hover {
  cursor: pointer;
}
.material-symbols-outlined {
  font-size: 2rem;
  padding-right: 0.6rem;
}
</style>
<template>
  <section class="cards">
    <div class="top-cards">
      <div class="agentsStatCards">
        <p>Purchased Adverts</p>
        <P>{{ purchasedAdverts }}</P>
      </div>
      <div class="agentsStatCards">
        <p>Published Adverts</p>
        <P>{{ totalPublishedAdverts }}</P>
      </div>
      <div class="agentsStatCards">
        <p>Created Products</p>
        <P>{{ totalProductsCreated }}</P>
      </div>
      <div class="agentsStatCards">
        <p>Verified Adverts</p>
        <P>{{ totalVerifiedAdverts }}</P>
      </div>
      <div class="agentsStatCards">
        <p>Verified Products</p>
        <P>{{ totalVerifiedProducts }}</P>
      </div>
    </div>
    <div class="down-cards">
      <div class="agentsStatCards">
        <p>Adverts Created</p>
        <P>{{ totalAdvertsCreated }}</P>
      </div>
      <div class="agentsStatCards">
        <p>Purchases Today</p>
        <P>{{ purchasesToday }}</P>
      </div>
      <div class="agentsStatCards">
        <p>Monthly Purchases</p>
        <P>{{ totalPurchasesThisMonth }}</P>
      </div>
      <div class="agentsStatCards">
        <p>Yearly Purchases</p>
        <P>{{ totalPurchasesThisYear }}</P>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
// imports
import { ref, onMounted, computed, defineEmits } from "vue";
import axios from "axios";
import { useAuthStore } from "../../store/authStore";
import Swal from "sweetalert2";

// api
const token = computed(() => {
  return useAuthStore().token;
});

// refs
const totalVerifiedAdverts = ref(0);
const totalVerifiedProducts = ref(0);
const totalPublishedAdverts = ref(0);
const purchasedAdverts = ref([]);
const totalProductsCreated = ref(0);
const totalAdvertsCreated = ref(0);
const purchasesToday = ref(0);
const totalPurchasesThisMonth = ref(0);
const totalPurchasesThisYear = ref(0);

// agents dashboard statistics
const agentsDashboardStats = async () => {
  try {
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/payments",
      { method: "agent_stats" },
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );

    if (response.data.success) {
      const results = response.data.result;
      purchasedAdverts.value = results.purchased_adverts.length;
      totalPublishedAdverts.value = results.total_published_adverts;
      totalProductsCreated.value = results.total_products_created;
      totalVerifiedAdverts.value = results.total_verified_adverts;
      totalVerifiedProducts.value = results.total_verified_products;
      totalAdvertsCreated.value = results.total_Adverts_created;
      purchasesToday.value = results.purchases_today;
      totalPurchasesThisMonth.value = results.total_purchases_this_month;
      totalPurchasesThisYear.value = results.total_purchases_this_year;
    } else {
      // Handle other error cases
      const errorMessage = response.data.errors || "Failed to fetch data.";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#3085d6",
    });
  }
};

// Defining custom events to emit
const emits = defineEmits(["loaded"]);

// Emiting events when data is loaded
const emitLoadedEvent = () => {
  emits("loaded");
};

onMounted(() => {
  agentsDashboardStats();
  emitLoadedEvent();
});
</script>

<style scoped>
.cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin: 2rem auto;
  width: 95%;
}
.top-cards,
.down-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  justify-content: center;
  width: 100%;
}
.down-cards .agentsStatCards {
  background-color: #662d91;
  color: white;
}
.agentsStatCards {
  background-color: white;
  border-radius: 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.agentsStatCards p {
  text-align: center;
}
.agentsStatCards p:first-child {
  font-size: 0.8rem;
  font-weight: 700;
  /* margin-top: 0.5rem; */
  padding: 5px 1rem;
}
.agentsStatCards p:last-child {
  font-size: 1rem;
  margin-bottom: 20px;
}
/* Media Query for Smaller Screens */
@media (max-width: 768px) {
  .agentsStatCards {
    flex: 1 1 calc(50% - 1rem);
  }
}

@media (max-width: 480px) {
  .agentsStatCards {
    flex: 1 1 100%;
  }
}
</style>
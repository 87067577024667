<template>
  <div>
    <div class="login-container">
      <section class="login-form-contol">
        <div class="login-content">
          <img
            src="https://cashchatapp.com/assets/images/new_brand.png"
            alt="cashchat-logo"
            class="logo-img"
          />
          <p class="main-paragraph">CashChat Agents Login</p>
          <form @submit.prevent="signIn">
            <label>Enter Phone and Password </label>
            <div class="mb-1">
              <input
                type="text"
                class="form-control"
                v-model="phone"
                placeholder="Format { 256712345689 }"
              />
              <div class="input-view-icon">
                <input
                  :type="passwordVisible ? 'text' : 'password'"
                  class="form-control"
                  v-model="password"
                  placeholder="Password"
                />
                <el-button
                  v-if="passwordVisible"
                  type="success"
                  :icon="hideIcon"
                  class="view-icon"
                  circle
                  @click="togglePasswordVisibility"
                ></el-button>
                <el-button
                  v-else
                  type="success"
                  :icon="viewIcon"
                  class="view-icon"
                  circle
                  @click="togglePasswordVisibility"
                ></el-button>
              </div>

              <!-- login button -->
            </div>
            <button v-loading="loading" class="sign-in-btn">Login</button>
          </form>
        </div>

        <!-- forgot password -->
        <el-button
          @click="requestResetCode"
          class="password-reset-btn"
          type="primary"
          >Forgot Password? Reset</el-button
        >

        <el-dialog
          v-model="resetDialogVisible"
          title="Password Reset"
          :width="dialogWidth"
          @close="closeResetDialog"
        >
          <!-- style="height: 35px" style="height: 35px" style="height: 35px" style="height: 35px" style="height: 35px"  style="height: 35px" style="height: 35px" -->
          <el-form class="modal-form" label-width="auto">
            <el-form-item label="Phone">
              <el-input
                type="text"
                v-model="phone"
                placeholder="Format { 256712345689 }"
                required
              />
            </el-form-item>
          </el-form>
          <template #footer>
            <div class="dialog-footer">
              <el-button @click="resetDialogVisible = false">Cancel</el-button>
              <el-button type="primary" @click="sendResetCode">
                Confirm
              </el-button>
            </div>
          </template>
        </el-dialog>

        <el-dialog
          v-model="dialogVisible"
          title="Reset your password"
          :width="dialogWidth"
          @close="closeDialog"
        >
          <el-form class="modal-form" label-width="auto">
            <el-form-item label="Phone">
              <el-input
                type="text"
                v-model="phone"
                placeholder="Format { 256712345689 }"
                required
              />
            </el-form-item>
            <el-form-item label="Password">
              <el-input type="text" v-model="password" required />
            </el-form-item>
            <el-form-item label="Code">
              <el-input type="text" v-model="code" required />
            </el-form-item>
          </el-form>
          <template #footer>
            <div class="dialog-footer">
              <el-button @click="dialogVisible = false">Cancel</el-button>
              <el-button type="primary" @click="resetPassword">
                Confirm
              </el-button>
            </div>
          </template>
        </el-dialog>

        <!-- animated text -->
        <div class="cashchat-text" :class="{ loaded: isLoaded }">
          {{ animatedText }}
        </div>
      </section>

      <section class="cover-page-control">
        <img
          src="https://cashchatapp.com/assets/images/oze.jpg"
          alt="Ozze Anatalia"
        />
      </section>
    </div>

    <!-- dialog for resetting the password -->

    <div v-if="dialogVisible" class="modal">
      <div class="modal-content">
        <div class="close-word">
          <h4>Password Reset</h4>
          <span class="close" @click="closeDialog">&times;</span>
        </div>
        <el-form class="modal-form" label-width="auto">
          <el-form-item label="Username">
            <el-input type="text" v-model="phone" required />
          </el-form-item>
          <el-form-item label="Phone">
            <el-input type="text" v-model="password" required />
          </el-form-item>
          <el-form-item label="Phone">
            <el-input type="text" v-model="code" required />
          </el-form-item>
          <el-button @click="resetPassword" type="primary" class="modal-btn"
            >Reset password</el-button
          >
        </el-form>
      </div>
    </div>
  </div>
</template>
      
      <script lang="ts" setup>
import { ref, computed, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import Swal from "sweetalert2";
import axios from "axios";
import { useAuthStore } from "../../store/authStore";
import { View } from "@element-plus/icons-vue";
import { Hide } from "@element-plus/icons-vue";

const authStore = useAuthStore();

const dialogVisible = ref(false);
const resetDialogVisible = ref(false);

const phone = ref("");
const password = ref("");
const code = ref("");
const passwordVisible = ref(false);

const router = useRouter();
const loading = ref(false);

const animatedText = ref("");
const isLoaded = ref(false);

const windowWidth = ref(window.innerWidth);

// Computed property for responsive dialog width
const dialogWidth = computed(() => {
  const width = windowWidth.value <= 480 ? "90%" : "500px";
  const maxWidth = "800px"; // Maximum width for large screens
  return `min(${width}, ${maxWidth})`;
});

function handleResize() {
  windowWidth.value = window.innerWidth; // Update the reactive windowWidth

}
// Watch for window resize events
onMounted(() => {
  window.addEventListener("resize", handleResize);
});

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});

const viewIcon = View;
const hideIcon = Hide;

const closeDialog = () => {
  dialogVisible.value = false;
};

const requestResetCode = () => {
  resetDialogVisible.value = true;
};

const closeResetDialog = () => {
  resetDialogVisible.value = false;
};

const togglePasswordVisibility = () => {
  passwordVisible.value = !passwordVisible.value;
};

// Defining a function to generate the animated text

const showWarningDialog = async () => {
  return Swal.fire({
    title: "Check Credentials",
    text: "Put in your number and Password!",
    icon: "warning",
    confirmButtonColor: "#662d91",
  });
};

const showNotInSystemDialog = async () => {
  return Swal.fire({
    title: "Number not in System",
    text: "Phone number not available in the system Try to Register on CashChatApp",
    icon: "error",
    confirmButtonColor: "#662d91",
  });
};
const showIncorrectPasswordDialog = async () => {
  return Swal.fire({
    title: "Incorrect password",
    text: "Password you entered is incorrect. Please try again.",
    icon: "error",
    confirmButtonColor: "#662d91",
  });
};

const signIn = async () => {
  try {
    loading.value = true;
    if (phone.value === "" || password.value === "") {
      return showWarningDialog();
    }
    if (password.value.length < 6) {
      return Swal.fire({
        title: "Invalid password",
        text: "Password must be 6 or more characters long",
        icon: "warning",
        confirmButtonColor: "#662d91",
      });
    }

    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/login",
      { method: "create_login", phone: phone.value, password: password.value },
      { headers: { "Content-Type": "application/json" } }
    );

    if (
      response.data.success &&
      response.data.info === "Logged in successfully."
    ) {
      // Handle successful login
      const token = response.data.user_info.token;
      localStorage.setItem("token", token);
      authStore.loginSuccess(token); // Calling the loginSuccess action in the authStore

      // Redirecting to dashboard
      router.push("/dashboard");
    } else {
      // Handle other error cases
      const errorMessage =
        response.data.errors || "Login failed, check your credentials";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    if (
      error.response.data.result === "Incorrect password. Please try again."
    ) {
      // Server responded with the message for incorrect password
      await showIncorrectPasswordDialog();
    } else if (
      error.response.data.result ===
      "Phone number not available in the system. Please check your phone number or Try to Register on CashChatApp."
    ) {
      await showNotInSystemDialog();
    } else if (
      error.response.data.result ===
      "Failed to login. You are not an Admin or an Agent authorized to login, contact Support."
    ) {
      return Swal.fire({
        title: "Login Failed",
        text: "Failed to login. You are not an Admin or an Agent authorized to login, contact Support.",
        icon: "error",
        confirmButtonColor: "#3085d6",
      });
    } else {
      return Swal.fire({
        title: "Login Failed",
        text: error.message,
        icon: "error",
        confirmButtonColor: "#3085d6",
      });
    }
  } finally {
    loading.value = false;
  }
};

// sending reset password code
const sendResetCode = async () => {
  try {
    if (!phone.value) {
      return Swal.fire({
        title: "Warning",
        text: "Phone is required",
        icon: "warning",
        confirmButtonColor: "#3085d6",
      });
    } else {
      const formData = new FormData();
      formData.append("method", "send_reset_password_code");
      formData.append("phone", phone.value.toString());
      // additional to global baseUrl endpoint
      const response = await axios.post("/send_reset_password_code", formData);
      if (response.data.success) {
        resetDialogVisible.value = false;
        const result = await Swal.fire({
          title: "Successful",
          text: "Reset password code sent successfully",
          icon: "success",
          confirmButtonColor: "#3085d6",
        });
        if (result.isConfirmed) {
          phone.value = "";
          resetDialogVisible.value = false;
        }
        dialogVisible.value = true;
      } else {
        // Handle other error cases
        const errorMessage =
          response.data.errors ||
          "Failed to send reset password code. Please try again later";
        return Swal.fire({
          title: "Error",
          text: errorMessage,
          icon: "error",
          confirmButtonColor: "#d33",
        });
      }
    }
  } catch (error: any) {
    await Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#3085d6",
    });
  }
};

// password reset
const resetPassword = async () => {
  try {
    if (phone.value === "" || password.value === "" || code.value === "") {
      return Swal.fire({
        title: "Warning",
        text: "All fields are required",
        icon: "warning",
        confirmButtonColor: "#3085d6",
      });
    } else {
      const formData = new FormData();
      formData.append("method", "reset_password");
      formData.append("phone", phone.value.toString());
      formData.append("password", password.value);
      formData.append("code", code.value);

      const response = await axios.post("/reset_password", formData);

      if (response.data.success) {
        dialogVisible.value = false;
        const result = await Swal.fire({
          title: "Successful",
          text: "Password reset successfully",
          icon: "success",
          confirmButtonColor: "#3085d6",
        });
        if (result.isConfirmed) {
          phone.value = "";
          password.value = "";
          code.value = "";
          dialogVisible.value = false;
        }
      } else {
        // Handle other error cases
        const errorMessage = response.data.errors || "Password reset failed";
        return Swal.fire({
          title: "Error",
          text: errorMessage,
          icon: "error",
          confirmButtonColor: "#d33",
        });
      }
    }
  } catch (error: any) {
    await Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#3085d6",
    });
  }
};
</script>
      
<style scoped>
.login-container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  height: 100vh;
  overflow: hidden;
}

.logo-img {
  width: 120px !important;
  height: auto;
  margin: 1rem auto !important;
  margin-left: 3rem !important;
}

.login-form-contol {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #662d91;
}

.login-content {
  width: 250px;
  margin: 0 auto;
}

.login-content img {
  width: 100px;
  height: 5rem;
}

/* paragraphs */
.login-form-contol p {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  position: relative;
  padding: 0 15px;
}

p {
  position: relative;
  padding: 0 15px;
}

.login-heading {
  font-weight: 200;
  color: #fff;
}

.main-paragraph {
  display: block;
  font-weight: 500;
  color: #fff;
  opacity: 0.8;
}

/* inputs */
.login-form-contol label {
  display: block;
  margin-bottom: 5px;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-form-contol input,
.form-control {
  height: 2.8rem;
  padding: 5px;
  font-size: 0.8rem;
  font-weight: 400;
  color: #495057;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  font-family: inherit;
  margin-bottom: 1rem;
}

.login-form-contol ::placeholder {
  font-weight: 100;
  font-size: 12px;
}

.login-form-contol label {
  display: flex;
  align-items: center;
}

.form-control label span {
  margin-left: 5px;
}

.login-form-contol img {
  width: 200px;
  vertical-align: middle;
  border-style: none;
  height: auto;
  margin-bottom: 20px;
}

.mb-1 {
  width: 265px;
}

label {
  color: #fff;
  font-style: italic;
}

/* buttons */
.sign-in-btn {
  width: 265px;
  height: 45px;
  text-transform: capitalize;
  background-color: #8cc63f;
  color: #662d91;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sign-in-btn:hover {
  background-color: #a8fa10;
}

.cashchat-text {
  color: #8cc63f;
  font-size: 3rem;
  margin-top: -1rem;
  text-align: center;
  font-style: italic;
  font-family: Cursive;
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}

.cashchat-text.loaded {
  opacity: 1;
}

/* loading spinner */
.input-view-icon {
  display: flex;
  justify-content: center;
}

.view-icon {
  z-index: 99;
  background-color: transparent;
  border: none;
  margin-left: -2rem;
  color: black;
  margin-top: 0.5rem;
  font-size: 1.1rem;
}

.password-reset-btn {
  background-color: transparent;
  color: white;
  border: none;
  margin: 3rem auto 3rem;
  text-align: center;
  display: block;
}

.password-reset-btn:hover {
  text-decoration: underline;
  color: #7e7efd;
}

/* cover image */
.cover-page-control img {
  width: 100%;
  height: 100vh; /* Full viewport height */
  object-fit: cover;
}

/* styling dialog */
.close-word {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 2rem;
}

.modal-content {
  background-color: #fefefe;
  margin: 25% auto;
  padding: 20px;
  border-radius: 10px;
  width: 30rem;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: 200;
  margin: -1rem -7rem 0 0;
}

.close-word h2 {
  font-size: 1.2rem;
  margin-left: -1rem;
}

/* Media queries for responsiveness */

@media (max-width: 480px) {
  .login-container {
    grid-template-columns: 1fr;
    height: auto;
  }
  .logo-img {
    margin-left: auto;
    margin-right: auto;
    width: 100px;
    display: block;
  }

  .login-form-contol {
    height: 100vh;
    padding: 2rem;
  }

  .login-content {
    width: 100%;
  }

  .sign-in-btn {
    height: 40px;
    font-size: 14px;
  }

  .login-content img {
    width: 60px;
  }

  .cashchat-text {
    font-size: 1.5rem;
  }

  .cover-page-control img {
    display: none;
  }
  /* this makes sure that all other images are not visible but logo is visible */

  .login-content img:not(.logo-img),
  .login-form-contol img:not(.logo-img) {
    display: none;
  }
}

@media (max-width: 600px) {
  .login-container {
    grid-template-columns: 1fr;
    height: auto;
  }

  .logo-img {
    margin-left: auto;
    margin-right: auto;
    width: 100px;
    display: block;
  }

  .login-content {
    width: 90%;
  }

  .sign-in-btn,
  .mb-1 {
    width: 90%;
  }

  .cover-page-control img {
    height: auto;
  }

  .login-content img {
    width: 80px;
    height: auto;
  }

  .cashchat-text {
    font-size: 2rem;
  }

  .modal-content {
    width: 90%;
  }
  .cover-page-control img {
    display: none;
  }
  .login-form-contol {
    height: 100vh;
    padding: 2rem;
  }
}
</style>
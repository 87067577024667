<template>
  <div class="my-commissions-container">
    <div class="home-user">
      <span class="home-icon">
        <span class="material-symbols-outlined" @click="goHome()">
          grid_view
        </span>
      </span>
      <div class="right-arrow-h4">
        <el-button class="right-arrow" :icon="arrowIcon" circle></el-button>
        <h4>My Commmissions</h4>
      </div>
    </div>
    <h3>My Commmissions</h3>
    <div class="card">
      <p>Commission Balance</p>
      <p class="currency-value">
        <span class="currency-code">{{ currencyCode }}</span
        ><br />
        <span class="currency-amount">{{
          parseFloat(currencyAmount).toFixed(3)
        }}</span>
      </p>
    </div>
    <el-button class="withdraw-btn" @click="showWithdrawDialog"
      >Withdraw Commissions</el-button
    >

    <div class="commissions-table">
      <el-table v-loading="loading" :data="rows" border style="width: 100%">
        <el-table-column label="#" width="90">
          <template v-slot="{ $index }">
            {{ $index + 1 }}
          </template>
        </el-table-column>
        <el-table-column
          label="Advert Id"
          prop="id"
          class="bold-label"
          width="150px"
        >
          <template v-slot="{ row }">
            <el-tooltip class="item" effect="dark" :content="row.id.toString()">
              <div>{{ row.id }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="Title" prop="title">
          <template v-slot="{ row }">
            {{ row.title }}
          </template>
        </el-table-column>
        <el-table-column label="Amount" prop="amount">
          <template v-slot="{ row }">
            {{ row.amount }}
          </template>
        </el-table-column>
        <el-table-column label="Paid Date" prop="paid_date">
          <template v-slot="{ row }">
            {{ row.paid_date }}
          </template>
        </el-table-column>
        <el-table-column label="Action" width="90">
          <template v-slot="{ row }">
            <el-tooltip content="Advert Details" placement="top">
              <el-button
                type="primary"
                :icon="statusIcon"
                circle
                @click="advertDetails(row.id)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        :current-page="currentPage"
        :small="small"
        :page-size="pageSize"
        :total="total"
        :disabled="disabled"
        :background="background"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, sizes, prev, pager, next, jumper"
      />
    </div>

    <el-dialog
      :model-value="withdrawCommissionDialog"
      title="Withdraw to CashChatApp"
      width="350"
      :style="{ paddingBottom: '20px' }"
      @close="closeDialog"
    >
      <!--       style="max-width: 600px" -->
      <el-form v-loading="loading" class="modal-form" label-width="auto">
        <el-form-item label="Account Number">
          <el-input type="text" v-model="accountNumber" required />
        </el-form-item>
        <el-form-item label="Amount">
          <el-input type="text" v-model="amount" required />
        </el-form-item>
        <el-form-item label="Currency">
          <div class="user-input-selector">
            <el-select
              v-model="selectedCurrency"
              placeholder="Choose Currecny"
              @change="handleCurrencyChange"
            >
              <el-option
                v-for="currency in currencies"
                :key="currency"
                :label="currency"
                :value="currency"
              />
            </el-select>
          </div>
        </el-form-item>
        <el-button
          @click="withdrawTheCommission"
          type="primary"
          class="create-payments-btn"
          >Confirm Withdraw</el-button
        >
      </el-form>
    </el-dialog>
    <!-- advert detals dialog -->
    <AdvertDetailsDialog
      :is-advert-details-dialog-visible="advertDetailsDialogVisible"
      :advert-id="advertId"
      @close-dialog="closeAdvertDetailsDialog"
    />
  </div>
</template>
    
<script lang="ts" setup>
import { useAuthStore } from "../../store/authStore";
import { onMounted, ref, computed } from "vue";
import axios from "axios";
import Swal from "sweetalert2";
import { ArrowRight, View } from "@element-plus/icons-vue";
import { useRouter } from "vue-router";
import AdvertDetailsDialog from "../../components/dialogs/adverts/advert-details-dialog.vue";

// paid adverts interface
interface PaidAdvert {
  id: number;
  title: string;
  amount: number;
  paid_date: string;
}

const loading = ref(false);
const totalCommission = ref("");
const currencyCode = ref("");
const currencyAmount = ref("");
const currentPage = ref(1);
const pageSize = ref(10);
const small = ref(false);
const background = ref(false);
const disabled = ref(false);
const rows = ref([]);
const total = ref(0);
const advertId = ref("");

const selectedCurrency = ref("");
const accountNumber = ref("");
const amount = ref("");
const formData = ref(new FormData());
const currencies = ref(["UGX", "KES", "RWF", "USD"]);

const advertDetailsDialogVisible = ref(false);
const withdrawCommissionDialog = ref(false);

const arrowIcon = ArrowRight;
const statusIcon = View;

// token
const token = computed(() => {
  return useAuthStore().token;
});
const handleCurrencyChange = async () => {
  formData.value.set("currency", selectedCurrency.value);
};

const advertDetails = (adId: string) => {
  advertId.value = adId;
  advertDetailsDialogVisible.value = true;
};

// closing advert details dialog
const closeAdvertDetailsDialog = () => {
  advertDetailsDialogVisible.value = false;
};

// Show the dialog when the button is clicked
const showWithdrawDialog = () => {
  withdrawCommissionDialog.value = true;
};

// Close the dialog
const closeDialog = () => {
  withdrawCommissionDialog.value = false;
};

const router = useRouter();

const goHome = () => {
  router.push("/dashboard");
};

const handleCurrentChange = async (val: number) => {
  currentPage.value = val;
  await fetchMyCommissions();
};

const handleSizeChange = async (val: number) => {
  pageSize.value = val;
  currentPage.value = 1;
  await fetchMyCommissions();
};

// fetching yhe commissions method
const fetchMyCommissions = async () => {
  try {
    loading.value = true;
    rows.value = [];
    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/adverts",
      {
        method: "agent_commissions",
      },
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );
    if (response.data.success) {
      const { paid_adverts, total_commission } = response.data;

      // Pushing mapped values to the rows
      rows.value = paid_adverts.map((advert: PaidAdvert) => ({
        id: advert.id,
        title: advert.title,
        amount: advert.amount,
        paid_date: new Date(advert.paid_date).toLocaleString(),
      }));

      // Assigning total commission and currency code/amount
      totalCommission.value = total_commission;
      const [code, amount] = totalCommission.value.split(" ");
      currencyCode.value = code;
      currencyAmount.value = amount;
    } else {
      // Handle other error cases
      const errorMessage =
        response.data.errors || "Failed to fetch commissions";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    return Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#3085d6",
    });
  } finally {
    loading.value = false;
  }
};

// verifying the name before withdrawing

// Fetch user commissions when component is mounted
onMounted(() => {
  fetchMyCommissions();
});

// verifying the account name before withdrawing
const verifyAccountName = async () => {
  try {
    // Create a FormData object for the verification request
    const formData = new FormData();
    formData.append("method", "verify_account_name");
    formData.append("account_number", accountNumber.value);

    // Make the API request
    const response = await axios.post("/users", formData, {
      headers: {
        Authorization: `Bearer ${token.value}`,
        "Content-Type": "multipart/form-data",
      },
    });

    if (response.data.success) {
      const { account_name } = response.data;
      const confirmed = await Swal.fire({
        title: "Confirm Account Name",
        html: `The account name is <strong>${account_name}</strong>. Do you want to proceed with the withdrawal?`,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Proceed",
        cancelButtonText: "No, Cancel",
        customClass: {
          container: "custom-swal",
        },
      });
      return confirmed.isConfirmed;
    } else {
      const errorMessage =
        response.data.errors || "Failed to verify account name";
      await Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
        customClass: {
          container: "custom-swal",
        },
      });
      return false;
    }
  } catch (error: any) {
    await Swal.fire({
      title: "Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#3085d6",
      customClass: {
        container: "custom-swal",
      },
    });
    return false;
  }
};

// Method to withdraw commission
const withdrawCommission = async () => {
  try {
    loading.value = true;

    // Validate amount based on selected currency
    let errorMessage = "";
    if (selectedCurrency.value === "UGX" && parseFloat(amount.value) < 500) {
      errorMessage = "Amount must be 500 UGX and above";
    } else if (
      selectedCurrency.value === "USD" &&
      parseFloat(amount.value) < 0.5
    ) {
      errorMessage = "Amount must be 0.5 USD and above";
    } else if (
      selectedCurrency.value === "KES" &&
      parseFloat(amount.value) < 5
    ) {
      errorMessage = "Amount must be 5 Ksh and above";
    }

    if (errorMessage) {
      closeDialog();
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }

    // Create a FormData object
    const formData = new FormData();
    formData.append("method", "withdraw");
    formData.append("mode", "wallet");
    formData.append("account_number", accountNumber.value);
    formData.append("amount", amount.value);
    formData.append("currency", selectedCurrency.value);

    // Make the API request
    const response = await axios.post("/payments", formData, {
      headers: {
        Authorization: `Bearer ${token.value}`,
        "Content-Type": "multipart/form-data",
      },
    });

    if (response.data.message === "Transaction Processed ") {
      closeDialog();
      Swal.fire({
        title: "Successful",
        text: "Withdraw processed",
        icon: "info",
        confirmButtonColor: "#3085d6",
      });
    } else if (
      response.data.message === "Transaction failed. Invalid country"
    ) {
      closeDialog();
      return Swal.fire({
        title: "Error",
        text: "Currency selected doesn't match your country",
        icon: "error",
        confirmButtonColor: "#d33",
      });
    } else if (response.data.message === "Transaction failed. Invalid amount") {
      closeDialog();
      return Swal.fire({
        title: "Error",
        text: "Amount must be 500 UGX and above",
        icon: "error",
        confirmButtonColor: "#d33",
      });
    } else {
      closeDialog();
      const errorMessage =
        response.data.errors || "Failed to withdraw commission";
      return Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  } catch (error: any) {
    await Swal.fire({
      title: "Payment Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#d33",
    });
  } finally {
    loading.value = false;
  }
};

const withdrawTheCommission = async () => {
  closeDialog();
  // Verify account name first
  const isVerified = await verifyAccountName();
  if (isVerified) {
    await withdrawCommission();
  }
};
</script>

<style scoped>
.my-commissions-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 95%;
}

.card {
  background-color: white;
  border-radius: 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin-bottom: 3rem;
  max-width: 250px;
  border: none;
}
.card p:first-child {
  text-align: center;
}
.currency-value {
  text-align: center;
}
.currency-code {
  font-size: 1.5rem;
}
.currency-amount {
  font-size: 1.2rem;
}

.commissions-table {
  display: block;
  width: 100%;
  overflow-x: auto;
}
.el-table {
  width: 100%;
  min-width: 600px;
  overflow-x: auto;
}
/* .pagination {
  margin: 1rem auto;
  overflow-x: auto;
} */
.home-user {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  /* margin: 0; */
}

.home-icon .material-symbols-outlined {
  font-size: 1.2rem;
  margin-left: 0;
}

.material-symbols-outlined:hover {
  cursor: pointer;
}

.right-arrow-h4 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.right-arrow {
  background-color: transparent;
  border: none;
  color: gray;
}
h3 {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 2rem 0 3rem 0;
  color: #662d91;
}
h4 {
  font-size: 1rem;
  font-weight: 300;
  margin-top: 0.5rem;
}
button {
  background-color: #662d91;
  border: none;
}

.create-payments-btn,
.withdraw-btn {
  background-color: #662d91;
  color: #fff;
  border: none;
  padding: 20px 3rem;
  cursor: pointer;
  display: block;
  margin: 1rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}
.withdraw-btn {
  display: flex;
  justify-content: flex-start;
  margin: 1rem 0;
  width: 15rem;
  padding: 20px 2rem;
}

.create-payments-btn:hover,
.withdraw-btn:hover {
  background-color: transparent;
  border: 1px solid #662d91;
  color: #662d91;
}
.user-input-selector {
  width: 100%;
}
.custom-swal {
  z-index: 9999 !important;
}
.swal2-container {
  z-index: 9999 !important;
}
/* Responsiveness for different screens */
@media (max-width: 768px) {
  .card {
    margin-bottom: 1.5rem;
  }
  .withdraw-btn {
    padding-right: 5px;
    text-align: center;
  }
  .create-payments-btn,
  .withdraw-btn {
    padding: 10px 15px;
    font-size: 12px;
  }
  .pagination {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    font-size: 0.875rem;
  }

  .pagination .el-pagination {
    width: auto; /* Let pagination take up necessary space */
  }

  .pagination .el-pagination .el-pagination__pager {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center pagination buttons */
  }

  .pagination .el-pagination .el-pagination__pager button {
    margin: 0.2rem; /* Add margin between buttons */
  }
}

@media (max-width: 480px) {
  .card {
    margin-bottom: 1rem;
  }

  .create-payments-btn,
  .withdraw-btn {
    padding: 10px 15px;
    font-size: 12px;
  }

  .withdraw-btn {
    text-align: center;
  }

  .home-icon .material-symbols-outlined {
    font-size: 1rem;
  }
  h4 {
    font-size: 0.7rem;
    margin-top: 0.5rem;
  }
  .pagination {
    font-size: 0.75rem;
    padding: 0.5rem;
  }

  .pagination .el-pagination {
    width: auto;
  }

  .pagination .el-pagination .el-pagination__pager {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .pagination .el-pagination .el-pagination__pager button {
    margin: 0.1rem;
    padding: 0.3rem;
  }
}
</style>
<template>
  <el-dialog
    :model-value="isPayFromCashchatappDialogVisible"
    title="Pay from cashchatapp"
    :width="dialogWidth"
    :style="{ paddingBottom: '20px' }"
    @close="closeDialog"
  >
    <!--       style="max-width: 600px" -->
    <el-form v-loading="loading" class="modal-form" label-width="auto">
      <el-form-item label="Wallet Id">
        <el-input type="text" v-model="walletId" required />
      </el-form-item>
      <el-form-item label="Days">
        <el-input type="text" v-model="days" required />
      </el-form-item>
      <el-form-item label="Currency">
        <div class="user-input-selector">
          <el-select
            v-model="selectedCurrency"
            placeholder="Choose Currecny"
            @change="handleCurrencyChange"
          >
            <el-option
              v-for="currency in currencies"
              :key="currency"
              :label="currency"
              :value="currency"
            />
          </el-select>
        </div>
      </el-form-item>
      <el-button
        @click="createPaymentFromCashchatapp"
        type="primary"
        class="create-payments-btn"
        >Create payment</el-button
      >
    </el-form>
  </el-dialog>
</template>
    
    <script lang="ts" setup>
import {
  ref,
  defineProps,
  defineEmits,
  computed,
  onMounted,
  onUnmounted,
} from "vue";

import axios from "axios";
import Swal from "sweetalert2";
import { useAuthStore } from "../../../store/authStore";
//  declaring the token
const token = computed(() => {
  return useAuthStore().token;
});

const windowWidth = ref(window.innerWidth);

// Computed property for responsive dialog width
const dialogWidth = computed(() => {
  const width = windowWidth.value <= 480 ? "90%" : "350px";
  const maxWidth = "350px"; // Maximum width for large screens
  return `min(${width}, ${maxWidth})`;
});

function handleResize() {
  windowWidth.value = window.innerWidth; // Update the reactive windowWidth
}
// Watch for window resize events
onMounted(() => {
  window.addEventListener("resize", handleResize);
  handleResize();
});

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});

const props = defineProps<{
  isPayFromCashchatappDialogVisible: boolean;
  advertId: string;
  clientId: string;
}>();

// emitting the custom event "close-dialog" for closing the dialog
const emit = defineEmits<{
  (e: "close-dialog"): void;
  (e: "fetch-adverts"): void;
}>();

const closeDialog = () => {
  emit("close-dialog");
};

// refs
const days = ref("");
const selectedCurrency = ref("");
const walletId = ref("");
const formData = ref(new FormData());
const currencies = ref(["UGX", "KES", "RWF", "USD"]);
const loading = ref(false);

// handing the currency change
const handleCurrencyChange = async () => {
  formData.value.set("currency", selectedCurrency.value);
};

const createPaymentFromCashchatapp = async () => {
  try {
    loading.value = true;
    // Create a FormData object
    const formData = new FormData();
    formData.append("method", "pay_from_cashchatapp");
    formData.append("mode", "wallet");
    formData.append("advert_id", props.advertId);
    formData.append("wallet_id", walletId.value);
    formData.append("days", days.value);
    // clientId represented by userId
    // formData.append("user_id", props.clientId);
    formData.append("currency", selectedCurrency.value);
    formData.append("days", days.value);

    const response = await axios.post(
      // additional to global baseUrl endpoint
      "/payments",
      formData,
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (response.data.success) {
      closeDialog();

      // Show initial Swal alert to check wallet
      await Swal.fire({
        title: "Check your cashchat wallet",
        text: "Please confirm the transaction in your cashchat wallet.",
        icon: "info",
        confirmButtonColor: "#3085d6",
      });
      // Showing the loading Swal with a timeout
      const swalWithTimeout = async () => {
        const swalPromise = Swal.fire({
          title: "Transaction Started",
          text: response.data.info,
          icon: "info",
          confirmButtonColor: "#3085d6",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        await swalWithTimeout();

        const timeoutPromise = new Promise<void>((resolve) => {
          setTimeout(() => {
            Swal.close();
            resolve();
          }, 60000);
        });

        // Use Promise.race to handle whichever promise resolves first
        await Promise.race([swalPromise, timeoutPromise]);
      };
      days.value = "";
      selectedCurrency.value = "";
      walletId.value = "";
    }
  } catch (error: any) {
    await Swal.fire({
      title: "Payment Error",
      text: error.message,
      icon: "error",
      confirmButtonColor: "#d33",
    });
  } finally {
    loading.value = false;
  }
};
</script>

  <style scoped>
.create-payments-btn {
  background-color: #662d91;
  color: #fff;
  border: none;
  padding: 20px 3rem;
  cursor: pointer;
  display: block;
  margin: 1rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

.create-payments-btn:hover {
  background-color: transparent;
  border: 1px solid #662d91;
  color: #662d91;
}
.user-input-selector {
  width: 100%;
}
</style>
import { createRouter, createWebHistory } from "vue-router";
import DashboardLayout from "@/views/dashboard/dashboard-layout-page.vue";
import HomeDashboard from "@/views/dashboard/home-dashboard-page.vue";
import DashboardSidebar from "@/components/dashboard-sidebar-component.vue";
import BaseHeader from "@/components/base-header-component.vue";
import BaseAdverts from "@/views/dashboard/base-adverts-page.vue";
import MyAdverts from "@/views/dashboard/my-adverts-page.vue";
import AdminStatistics from "@/views/dashboard/admin-statistics-page.vue";
import AgentsStatistics from "@/views/dashboard/agents-statistics-page.vue";
import DashboardGraph from "@/components/dashboard-graph-component.vue";
import AgentsGraph from "@/components/dashboard-graph-component.vue";
import ManageUsers from "@/views/dashboard/manage-users-page.vue";
import AdvertsPayments from "@/views/dashboard/adverts-payments-page.vue";
import AllProducts from "@/views/dashboard/all-products-page.vue";
import MyProducts from "@/views/dashboard/my-products-page.vue";
import MyEarnings from "@/views/dashboard/my-earnings-page.vue";
import MyCommissions from "@/views/dashboard/my-commissions-page.vue";
import AdminPaidAdverts from "@/views/dashboard/admin-paid-adverts-page.vue";
import PaidAdverts from "@/views/dashboard/paid-adverts-page.vue";
import ManageAnnouncements from "@/views/dashboard/manage-announcements-page.vue";
import AccountSettings from "@/views/dashboard/account-settings-page.vue";
import AllCommissions from "@/views/dashboard/all-commissions-page.vue";
import AllUserWithdraws from "@/views/dashboard/all-users-withdraws-page.vue";
import MyWithdraws from "@/views/dashboard/my-widthdraws-page.vue";
import LoginPage from "@/views/dashboard/login-page.vue";

// defining the routes
const routes = [
  {
    path: "/dashboard",
    component: DashboardLayout,
    children: [
      // Define admin-only routes first
      {
        path: "",
        component: HomeDashboard,
        meta: { requiresAuth: true },
      },
      {
        path: "home-dashboard",
        component: HomeDashboard,
        meta: { requiresAuth: true },
      },
      {
        path: "dashboard-sidebar",
        component: DashboardSidebar,
        meta: { requiresAuth: true },
      },
      {
        path: "base-header",
        component: BaseHeader,
        meta: { requiresAuth: true },
      },
      {
        path: "base-adverts",
        component: BaseAdverts,
        meta: { requiresAuth: true, requiresAdmin: true },
      },
      {
        path: "all-products",
        component: AllProducts,
        meta: { requiresAuth: true, requiresAdmin: true },
      },
      {
        path: "admin-statistics",
        component: AdminStatistics,
        meta: { requiresAuth: true, requiresAdmin: true },
      },
      {
        path: "manage-users",
        component: ManageUsers,
        meta: { requiresAuth: true, requiresAdmin: true },
      },
      {
        path: "all-commissions",
        component: AllCommissions,
        meta: { requiresAuth: true, requiresAdmin: true },
      },
      {
        path: "all-user-widthdraws",
        component: AllUserWithdraws,
        meta: { requiresAuth: true, requiresAdmin: true },
      },
      {
        path: "dashboard-graph",
        component: DashboardGraph,
        meta: { requiresAuth: true },
      },
      {
        path: "adverts-payments",
        component: AdvertsPayments,
        meta: { requiresAuth: true, requiresAdmin: true },
      },
      {
        path: "admin-paid-adverts",
        component: AdminPaidAdverts,
        meta: { requiresAuth: true, requiresAdmin: true },
      },
      {
        path: "manage-announcements",
        component: ManageAnnouncements,
        meta: { requiresAuth: true, requiresAdmin: true },
      },
      {
        path: "account-settings",
        component: AccountSettings,
        meta: { requiresAuth: true },
      },
      {
        path: "my-widthdraws",
        component: MyWithdraws,
        meta: { requiresAuth: true },
      },
      // Define agent-only routes next
      {
        path: "my-adverts",
        component: MyAdverts,
        meta: { requiresAuth: true, requiresAgent: true },
      },
      {
        path: "agents-graph",
        component: AgentsGraph,
        meta: { requiresAuth: true, requiresAgent: true },
      },
      {
        path: "agents-statistics",
        component: AgentsStatistics,
        meta: { requiresAuth: true, requiresAgent: true },
      },
      {
        path: "my-products",
        component: MyProducts,
        meta: { requiresAuth: true, requiresAgent: true },
      },
      {
        path: "paid-adverts",
        component: PaidAdverts,
        meta: { requiresAuth: true, requiresAgent: true },
      },
      // Define routes accessible to all authenticated users
      {
        path: "my-earnings",
        component: MyEarnings,
        meta: { requiresAuth: true },
      },
      {
        path: "my-commissions",
        component: MyCommissions,
        meta: { requiresAuth: true },
      },
    ],
  },
  { path: "/login", component: LoginPage },
  // Redirecting any unmatched routes to the login page
  { path: "/:pathMatch(.*)*", redirect: "/login" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
